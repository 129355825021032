<!-- Button -->
<button
        [matMenuTriggerFor]="languages"
        mat-icon-button>
    <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: activeLang}"></ng-container>
</button>

<!-- Language menu -->
<mat-menu
        #languages="matMenu"
        [xPosition]="'before'">
    <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
        <button
                (click)="setActiveLang(lang.id)"
                mat-menu-item>
            <span class="flex items-center">
                <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang.id}"></ng-container>
                <span class="ml-3">{{lang.label}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

<!-- Flag image template -->
<ng-template
        #flagImage
        let-lang>
    <span *ngIf="flagCodes[lang]" class="relative w-6 shadow rounded-sm overflow-hidden">
        <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
        <img
                [alt]="'Flag image for ' + lang"
                [src]="'assets/images/flags/' + flagCodes[lang]?.toUpperCase() + '.svg'"
                class="w-full">
    </span>
</ng-template>
