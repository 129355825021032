import { Component } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { NgForOf } from '@angular/common';

@Component({
    selector: 'variable-example',
    templateUrl: 'variable-example.html',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        NgForOf,
    ],
})
export class VariableExampleComponent {
    variables = [
        { variable: '{PACIENTE_NOME_SOCIAL}: Adiciona o nome do paciente a mensagem' },
        { variable: '{LOCAL_CONSULTA_EXAME}: Adiciona o local da consulta ou do exame' },
        { variable: '{PROFISSIONAL_CONSULTA_EXAME}: Adiciona o nome do profissional a mensagem' },
        { variable: '{NOME_CONSULTA_EXAME}: Adiciona o nome da consulta ou do exame' },
        { variable: '{DATA_CONSULTA_EXAME}: Adiciona o data e a hora marcada da consulta ou do exame' },
        { variable: '{STATUS_DO_AGENDAMENTO}: Adiciona o status do agendamento' }
    ]
    constructor(
        public dialogRef: MatDialogRef<VariableExampleComponent>,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    protected readonly Object = Object;
}
