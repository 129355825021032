export enum UserRole {
    ROOT = 'RO',
    ADMINISTRATOR_MANAGER = 'AM',
    SERVICE_UNIT_MANAGER = 'SUM',
    SECRETARY = 'SC',
    PROFESSIONAL = 'PR',
    PATIENT = 'PT',
    RECEPTIONIST = 'RC',
    COMPANY_MANAGER = 'CM',
    COVENANT_MANAGER = 'CTM',
}
