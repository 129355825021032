import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  /* Service Unit Photo Observer */
  private serviceUnitPhotoSubject = new BehaviorSubject<any>(null);
  serviceUnitPhoto$ = this.serviceUnitPhotoSubject.asObservable();
  updateServiceUnitPhoto(serviceUnitPhoto: any) {
    this.serviceUnitPhotoSubject.next(serviceUnitPhoto);
  }


}
