import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AbstractService } from '../commons/services/abstract.service';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { of, switchMap } from 'rxjs';

declare global {
    interface Window {
        Pusher: any;
        Echo: any;
    }
}

@Injectable({ providedIn: 'root' })
export class SoketiService extends AbstractService<any> {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    public baseUrl: string;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'soketi', null)
        window.Pusher = Pusher;
    }

    connect() {
        return this.subRoute('').pipe(
            switchMap((result: any) => {
                window.Echo = new Echo({
                    authEndpoint: environment.broadcastingServerUrl + `/broadcasting/auth`,
                    auth: {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                    },
                    broadcaster: 'pusher',
                    key: 'app-key',
                    wsHost: '127.0.0.1',
                    wsPort: 6001,
                    wssPort: 6001,
                    forceTLS: false,
                    encrypted: true,
                    disableStats: true,
                    enabledTransports: ['ws', 'wss'],
                    cluster: 'mt1'
                });

                return of(window.Echo);
            })
        )
    }
}
