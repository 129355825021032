<div class="flex flex-col flex-auto min-w-0">
    <!-- Header -->
    <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b text-white gradient-bg dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <div class="flex justify-center">
                <div class="mt-2">
                    <h2 class="text-2xl md:text-4xl font-semibold tracking-tight leading-7 sm:leading-10 truncate">
                        Prescrições
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showDelayedContent" class="px-3">
    <div class="flex justify-center items-center text-gray-700 bg-gray-200 shadow-md rounded-xl w-full py-6">
        <div class="text-sm text-center">Aguarde, os dados estão sendo carregados ....</div>
    </div>
</div>

<div *ngIf="showDelayedContent2" class="px-3">
    <div class="flex justify-center items-center text-gray-700 bg-gray-200 shadow-md rounded-xl w-full py-6">
        <div class="text-sm text-center">Não foram encontradas prescrições até o momento</div>
    </div>
</div>

<div class="flex flex-col md:w-1/2 mx-auto p-3 flex-auto">
    <app-prescription-item class="mt-4" *ngFor="let prescription of appointmentPrescriptions?.data" [prescription]="prescription"></app-prescription-item>
</div>
