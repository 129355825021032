<div class="company-table">
    <router-outlet></router-outlet>
    <div class="flex flex-col flex-auto min-w-0">
        <!-- Header -->
        <div
                class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
            <div class="flex-1 min-w-0">
                <!-- Breadcrumbs -->
                <div>
                    <div class="flex items-center font-medium gap-2">
                        <p class="text-[16px] text-[#1C2D48] font-normal">Gestão colaboradores</p>
                        <!--<p class="text-[13px] text-[#0F80CB] bg-[#F0F8FF] rounded-[16px] p-2 w-fit">190 colaboradores</p> -->
                    </div>
                </div>
                <!-- Title -->
                <div class="flex justify-between">
                    <div class="mt-2">
                        <h2 class="mt-[7px] text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                            Colaboradores Desligados
                        </h2>
                    </div>
                </div>
            </div>
        </div>

        <!-- Main -->
        <div class="flex flex-auto overflow-hidden">
            <!-- Main -->
            <div class="flex-auto w-full">
                <div class="grid grid-cols-1 gap-8 w-full mt-8">
                    <!-- Tabela antiga -->
                    <div class="xl:col-span-2 flex flex-col flex-auto rounded-2xl overflow-hidden">
                        <div class="flex gap-5 mb-5">


                            <div class="w-full">
                                <label>
                                    <p class="text-[#545F71] text-[15px] font-medium pb-[7px]">Pesquisar</p>
                                    <div class="relative">
                                        <svg class="absolute top-1/2 translate-y-[-50%] left-[11px]"
                                             xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             viewBox="0 0 20 20" fill="none">
                                            <path d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                                                  stroke="#6C7E89" stroke-width="1.66667" stroke-linecap="round"
                                                  stroke-linejoin="round"/>
                                        </svg>
                                        <input type="text" (keyup)="applyFilter($event)" matInput
                                               placeholder="Pesquisar"
                                               class="search-input text-[15px] text-[#6C7E89] font-normal py-[10px] pl-[34px] pr-[14px] h-[44px] rounded-[8px] border-[1px] border-solid border-[#D2DAE0] bg-white shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] w-full"/>
                                    </div>
                                </label>
                            </div>


                        </div>
                        <div class="relative overflow-x-auto">
                            <table class="w-full text-left mt-[27px]" #table [dataSource]="dataSource" [trackBy]="trackByFn" class="w-full" mat-table matSort>

                                <ng-container matColumnDef="status">
                                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
                                    <td (click)="openDetails('200ms', '200ms', data)" *matCellDef="let data"
                                        class="table-item" mat-cell>


                                        <mat-icon *ngIf="data.companyStatus == patientCompanyStatus.active" class="icon-green">check_circle</mat-icon>
                                        <mat-icon *ngIf="data.companyStatus != patientCompanyStatus.active" class="icon-red">cancel</mat-icon>

                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="company">
                                    <th style="width: 20rem"  *matHeaderCellDef mat-header-cell mat-sort-header>Empresa</th>
                                    <td (click)="openDetails('200ms', '200ms', data)" *matCellDef="let data"
                                        class="table-item" mat-cell>
                                        <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                                            {{ data?.company?.name }}
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container  matColumnDef="name">
                                    <th style="width: 20rem"  *matHeaderCellDef mat-header-cell mat-sort-header>Nome</th>
                                    <td (click)="openDetails('200ms', '200ms', data)" *matCellDef="let data"
                                        class="table-item" mat-cell>
                                        <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                                            {{ data.name }}
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="jobTitle">
                                    <th style="width: 20rem"  *matHeaderCellDef mat-header-cell mat-sort-header>Setor/cargo</th>
                                    <td (click)="openDetails('200ms', '200ms', data)" *matCellDef="let data"
                                        class="table-item" mat-cell>
                                        <ng-container>
                                            <span  *ngIf="data.jobTitle"
                                                  class="pr-6 font-medium text-sm text-secondary truncate">
                                                {{ (data.jobTitle.companyDivision != null) ? data.jobTitle.companyDivision.name : "" }}
                                                / {{ data.jobTitle.name }}
                                            </span>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="settings">
                                    <th *matHeaderCellDef class="settings-column" mat-header-cell mat-sort-header>
                                        <mat-icon class="table-icon" svgIcon="heroicons_solid:cog-6-tooth"></mat-icon>
                                    </th>
                                    <td *matCellDef="let data" class="settings-column-data table-item" mat-cell>
                                        <div class="flex items-center justify-end buttons">
                                            <button (click)="openDetails('200ms', '200ms', data)" class="view-button"
                                                    mat-menu-item matTooltip="Ver" matTooltipPosition="above">
                                                <svg class="eye cursor-pointer" xmlns="http://www.w3.org/2000/svg"
                                                     width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                    <path d="M2.03613 12.822C1.96712 12.6146 1.96712 12.3904 2.03613 12.183C3.42313 8.01 7.36013 5 12.0001 5C16.6381 5 20.5731 8.007 21.9631 12.178C22.0331 12.385 22.0331 12.609 21.9631 12.817C20.5771 16.99 16.6401 20 12.0001 20C7.36213 20 3.42613 16.993 2.03613 12.822Z"
                                                          stroke="#677285" stroke-width="1.5" stroke-linecap="round"
                                                          stroke-linejoin="round"/>
                                                    <path d="M15 12.5C15 13.2956 14.6839 14.0587 14.1213 14.6213C13.5587 15.1839 12.7956 15.5 12 15.5C11.2044 15.5 10.4413 15.1839 9.87868 14.6213C9.31607 14.0587 9 13.2956 9 12.5C9 11.7044 9.31607 10.9413 9.87868 10.3787C10.4413 9.81607 11.2044 9.5 12 9.5C12.7956 9.5 13.5587 9.81607 14.1213 10.3787C14.6839 10.9413 15 11.7044 15 12.5Z"
                                                          stroke="#677285" stroke-width="1.5" stroke-linecap="round"
                                                          stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr *matHeaderRowDef="columns" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: columns;" class="order-row h-16" mat-row></tr>
                            </table>
                        </div>
                        <mat-paginator (page)="setPage($event)" [hidePageSize]="true"
                                       [length]="pagination?.length" [ngClass]="{'pointer-events-none': isLoading}"
                                       [pageIndex]="pagination?.pageIndex" [pageSize]="pagination?.pageSize"
                                       [showFirstLastButtons]="true"></mat-paginator>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

