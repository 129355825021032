import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Tenant } from '../model/tenant';
import { AbstractService } from '../../commons/services/abstract.service';

@Injectable({ providedIn: 'root' })
export class TenantService extends AbstractService<Tenant> {

    // Private
    private _tenant: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'tenant', Tenant);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get tenant(): Observable<any> {
        return this._tenant.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Tenant
     */
    getTenant(): Observable<any> {
        return this.get().pipe(
            tap((response) => {
                this._tenant.next(response);
            }),
        );
    }
}
