export class File {
    slug: string;
    name: string;
    fileName: string;
    type: string;
    path: string;
    mimeType: string;
    base64: string;

    constructor(object?: any) {
        if (object) {
            for (const prop of Object.keys(object)) {
                this[prop] = object[prop];
            }
        }
    }
}
