<div class="flex flex-col flex-auto min-w-0">
    <!-- Header -->
    <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b text-white gradient-bg dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <div class="flex items-center  cursor-pointer">
                <svg (click)="back()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" class="block md:hidden mr-2">
                    <path fill="#ffff" d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z"/>
                </svg>
                <div class="flex justify-center w-full">
                    <h2 class="text-2xl md:text-4xl font-semibold tracking-tight leading-7 sm:leading-10 truncate">
                        Prescrições
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showDelayedContent" class="px-3">
    <div class="flex justify-center items-center text-gray-700 bg-gray-200 shadow-md rounded-xl w-full py-6">
        <div class="text-sm text-center">Aguarde, os dados estão sendo carregados ....</div>
    </div>
</div>
<div *ngIf="showDelayedContent2" class="px-3">
    <div class="flex justify-center items-center text-gray-700 bg-gray-200 shadow-md rounded-xl w-full py-6">
        <div class="text-sm text-center">Não foram encontradas prescrições até o momento</div>
    </div>
</div>

<div class="relative flex md:w-1/2 mx-auto md:mt-6  flex-col text-gray-700 bg-gray-200 shadow-md rounded-xl">
    <div *ngFor="let appointment of appointmentPrescriptions" class="px-3">
        <div class="flex items-center  mt-3">
            <div class="rounded-full w-[45px] h-[45px] text-base font-medium leading-relaxed text-blue-gray-900 mt-2 mr-3">
                <img *ngIf="appointment['professionalProfilePicture']" [src]="appointment['professionalProfilePicture']"
                     class="w-[45px] h-[45px] border border-cyan-600 object-cover rounded-full"/>
                <div *ngIf="!appointment['professionalProfilePicture']">
                    <mat-icon [svgIcon]="'heroicons_solid:user-circle'"
                              class="w-full h-full rounded-full default-avatar bg-neutral-200 color-gray"></mat-icon>
                </div>
            </div>
            <div class="flex flex-col">
                <p class="text-sm font-semibold leading-relaxed text-blue-gray-900">
                    {{ appointment['professionalName'] }} - {{ appointment['professionalCouncilNumber'] }}
                </p>
                <p class="text-sm font-semibold leading-relaxed text-blue-gray-900">
                    Paciente - {{ appointment['patientName'] }}
                </p>
            </div>
        </div>
        <div class="flex ml-14 items-center mb-3">
            <p class="text-sm font-medium leading-relaxed text-gray-500">
                Realizado em: {{ appointment['date'] }} ás {{ appointment['hour'] }}
            </p>
        </div>
    </div>
    <div class="w-full border-t border-gray-400 mb-3"></div>

    <div class="flex justify-center">
        <div class="H1">
            <span class="font-bold">{{ presType | uppercase }}</span>
        </div>
    </div>

    <app-prescription-referrals-pdf
            *ngIf="type === 'PC'"
            [type]="type"
            [data]="data?.data"
            (downloadReferralsPdfEvent)="clickButton($event)">
    </app-prescription-referrals-pdf>


    <app-prescription-medicine-pdf
            *ngIf="type === 'MPR'"
            [type]="type"
            [data]="data?.data"
            [mediciPdf]="mediciPdf"
            (downloadMedicinePdfEvent)="downloadPDF($event)">
    </app-prescription-medicine-pdf>

    <app-prescription-pdf
            *ngIf="type === 'CT' || type === 'OR' || type === 'RP'"
            [type]="type"
            [data]="data?.data"
            [mediciPdf]="mediciPdf"
            (downloadPdfEvent)="downloadPDF($event)"
    >
    </app-prescription-pdf>



    <div class="flex justify-end mt-3">
        <button (click)="back()" class="cancel-button px-4 py-2 mr-2 mat-raised-button hidden sm:hidden md:inline-block">Voltar</button>
    </div>
</div>







