import { Component, Input } from '@angular/core';
import { NgIf, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prescription-card',
  standalone: true,
    imports: [
        NgStyle,
        MatIconModule,
        NgIf
    ],
  templateUrl: './prescription-card.component.html',
  styleUrl: './prescription-card.component.scss'
})
export class PrescriptionCardComponent {
    @Input() title: string;
    @Input() count: number;
    @Input() slug: string;
    @Input() type: string;

    constructor(private router: Router) {}

    navigateWithSlug(slug: string) {
        localStorage.setItem('type', this.type);
        this.router.navigate(['/resources/patient-list',slug], { state: { type: this.type } });
    }
}
