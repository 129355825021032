import { Component, Input } from '@angular/core';
import { PrescriptionCardComponent } from '../prescription-card/prescription-card.component';
import { DatePipe, NgIf, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-prescription-item',
  standalone: true,
    imports: [
        PrescriptionCardComponent,
        DatePipe,
        TitleCasePipe,
        UpperCasePipe,
        MatIconModule,
        NgIf
    ],
  templateUrl: './prescription-item.component.html',
  styleUrl: './prescription-item.component.scss'
})
export class PrescriptionItemComponent {
    @Input() prescription: any;

    ZERO = 0;
    ONE = 1;
    TWO = 2;
    THREE = 3;
    FOUR = 4;

    prescriptionType = [
        { 'type': 'PC' },
        { 'type': 'MPR' },
        { 'type': 'CT' },
        { 'type': 'OR' },
        { 'type': 'RP' }
    ];
}
