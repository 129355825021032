import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, tap } from "rxjs";
import { Patient } from "../model/patient";
import { AbstractService } from "../../../../core/commons/services/abstract.service";
import { environment } from "app/environments/environment";
import { CompanyFinance } from '../../finance/model/Finance';

@Injectable({ providedIn: "root" })
export class PatientService extends AbstractService<Patient> {
    _patient: BehaviorSubject<Patient | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, "patients", Patient);
    }

    // Private
    private _patients: BehaviorSubject<any> = new BehaviorSubject(null);
    private _finances: BehaviorSubject<any> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get patients(): Observable<any> {
        return this._patients.asObservable();
    }

    /**
     * Getter for item
     */
    get patient$(): Observable<Patient> {
        return this._patient.asObservable();
    }

    get finances(): Observable<any> {
        return this._finances.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Procedures
     */
    getPatients(): Observable<any> {
        return this._httpClient.get<Patient[]>("/").pipe(
            tap((response) => {
                this._patients.next(response);
            })
        );
    }

    employeeExpenses(yearMonth: string, uuid: string): Observable<any> {
        return this._httpClient.get<any>(`${environment.serverUrl}/employee-expenses/${yearMonth}/${uuid}`);
    }

    companyEmployeeExpenses(yearMonth: string): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/company-invoices/${yearMonth}`).pipe(
            tap((response) => {
                this._finances.next(response);
            }),
        );
    }

    getPatientByStatus(page: number, limit: number, companyStatus: string): Observable<any> {
        return this._httpClient.get<Patient[]>(`${ environment.serverUrl }/patients?page=${ page }&limit=${ limit }&companyStatus=${ companyStatus }`).pipe(
            tap((response) => {
                this._patients.next(response);
            })
        );
    }
    searchByCpf(cpf: string): Observable<any> {
        return this._httpClient.get<Patient[]>(`${ environment.serverUrl }/users-search/${ cpf }`).pipe(
            tap((response) => {
                this._patients.next(response);
            })
        );
    }
    patientsDeactivate(id:string, request: any): Observable<any> {
        return this._httpClient.put<Patient[]>(`${ environment.serverUrl }/patients/${id}`, request).pipe(
            tap((response) => {
                this._patients.next(response);
            })
        );
    }
    contractPrimePanPrime(request: any): Observable<any> {
        return this._httpClient.post<Patient[]>(`${ environment.serverUrl }/company/collaborator-prime`, request).pipe(
            tap((response) => {
                this._patients.next(response);
            })
        );
    }
    excel(data: any): Observable<any> {
        return this._httpClient.post<any>(`${ environment.serverUrl }/patients-import`, data);
    }


    companyDivisionsRelations(companyDivisionId: string): Observable<any> {
        return this._httpClient.get<Patient[]>(`${ environment.serverUrl }/company-divisions-relations/${companyDivisionId}`).pipe(
            tap((response) => {
                this._patients.next(response);
            })
        );
    }

    linkCollaborator(data): Observable<any> {
        return this._httpClient.patch<Patient[]>(`${ environment.serverUrl }/link-collaborator`, data);
    }

}
