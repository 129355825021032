import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-img-plans',
  standalone: true,
  imports: [],
  templateUrl: './img-plans.component.html',
  styleUrl: './img-plans.component.scss'
})
export class ImgPlansComponent implements OnInit {

    imageUrl: string = null;

  constructor() {}

  ngOnInit(): void {
      this.getPlanImageUrl();
  }

    getPlanImageUrl() {
        let storagePlan = localStorage.getItem('planType');
        let imageUrl = '';

        //console.log('meu plano', storagePlan);

        if (storagePlan != null) {
            if (storagePlan === 'ClubMed Prime Gold' || storagePlan === 'gold') {
                imageUrl = 'assets/images/plans/prime gold.png';
            } else if (storagePlan === 'ClubMed Prime Black' || storagePlan === 'black') {
                imageUrl = 'assets/images/plans/prime black.png';
            } else if (storagePlan === 'ClubMed Prime Silver' || storagePlan === 'silver') {
                imageUrl = 'assets/images/plans/prime silver.png';
            }
        }

        return imageUrl;
    }

}
