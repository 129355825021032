import { Component, OnInit } from '@angular/core';
import { AsyncPipe, Location, NgForOf, NgIf } from '@angular/common';
import { FuseCardComponent } from '../../../../../@fuse/components/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { BankService } from '../../service/bank.service';
import Swal from 'sweetalert2';
import { map, Observable, startWith } from 'rxjs';

interface AccountType {
    value: number;
    name: string;
}

enum AccountT {
    LittleBox = 6,
    Slug,
}

@Component({
    selector: 'app-internal-account',
    templateUrl: './internal-account.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        FuseCardComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        NgForOf,
        ReactiveFormsModule,
        RouterOutlet,
        NgIf
    ],
    styleUrls: ['./internal-account.component.scss']
})
export class InternalAccountComponent implements OnInit  {
    action = 'Salvar';
    unit: string;
    private bankAccount: any;
    bankForm: FormGroup;
    bankSlug: any;
    myControl = new FormControl<string | any>('');
    data: any;
    filteredOptions: Observable<any>;
    code: string;


    accountType: AccountType[] = [
        {value: AccountT.LittleBox, name: 'Caixinha'},
        {value: AccountT.Slug, name: 'slug'},
    ];



    constructor(private bankService: BankService,
                private location: Location,
                private route: ActivatedRoute,
    ) {
        this.bankForm = new FormGroup({
            holder: new FormControl('', [Validators.required]),
            observation: new FormControl('', [Validators.required]),
            bankSlug: new FormControl(''),
            accountType: new FormControl(AccountT.LittleBox, [Validators.required]),
        });

        this.unit = localStorage.getItem('userLocation');

        this.route.params.subscribe((params) => {
            if (params.id) {
                this.bankAccount = params['id'];
                this.action = 'Editar';
                this.bankService.editAccount(this.unit, params.id).subscribe((response) => {
                    this.bankForm.patchValue({
                        holder: response.holder,
                        observation: response.observation,
                        bankSlug: response.code,
                    });
                    this.bankSlug = response.code;
                });
            }
        });
    }

    ngOnInit(): void {
        this.list();
    }

    goBack() {
        this.location.back();
    }

    save() {
         this.bankForm.value.bankSlug = this.bankSlug;
        this.bankForm.value.accountType = AccountT.LittleBox;
            console.log(this.bankForm.value, 'entroui');

        if (!this.bankForm.valid || !this.bankSlug && this.action === 'Salvar') {
            this.showError('Preencha os campos obrigatórios.');
            return;
        }

        if (this.action === 'Salvar')
        {
            this.createBankAccount();
        } else {

            this.updateBankAccount();
        }
    }

    private createBankAccount() {

        console.log(this.bankForm.value, this.unit);
        this.bankService.storeBank(this.bankForm.value,  this.unit).subscribe({
            next: (response) => {
                this.showSuccess('Cadastro realizado com sucesso.');
                this.location.back();
            },
            error: (error) => {
                this.showError('Erro ao cadastrar conta.');
            }
        });
    }

    private updateBankAccount() {

        this.bankService.updateBank(this.bankForm.value, this.bankAccount).subscribe({
            next: (response) => {
                this.showSuccess('Cadastro realizado com sucesso.');
                this.location.back();
            },
            error: (error) => {
                this.showError('Erro ao editar conta.');
            }
        });

    }

    private showError(message: string) {
        Swal.fire('Erro', message, 'error');
    }

    private showSuccess(message: string) {
        Swal.fire('', message, 'success');
    }


    displayFn(bank: any): string {
        return bank && bank.name ? bank.name : '';
    }

    private _filter(name: string): any {
        const filterValue = name.toLowerCase();

        return this.data.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    list(){
        this.bankService.bank().subscribe((response) => {
            this.data = response.data.filter((bank: any) => bank.name === 'Caixinha');
            //select
            this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map(value => {
                    this.bankSlug = value.slug;
                    console.log(  this.bankSlug);
                    const name = typeof value === 'string' ? value : value?.name;
                    return name ? this._filter(name as string) : this.data.slice();
                }),
            );
        });

    }




}
