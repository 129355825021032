import { Address } from "../../../address/model/address";
import { Company } from '../../../company/model/company';
import { CompanyDivision } from '../../../company/company-division/model/company-division';
import { CompanyDepartment } from '../../../company/company-department/model/company-department';
import { JobTitle } from '../../../company/job-title/model/job-title';
import { User } from "app/modules/user/model/user";

export class Patient {
    slug: string;
    name: string;
    socialName: string;
    user: User;
    documentNumber: number;
    phone: string;
    phoneLocale: string;
    birthdate: string
    admissionDate: string
    gender: string;
    type: string;
    holder: Patient;
    address: Address;
    company: Company;
    companyDivision: CompanyDivision;
    companyDepartment: CompanyDepartment;
    jobTitle: JobTitle;
    companyId: string;
    companyDivisionSlug: string;
    companyDepartmentSlug: string;
    jobTitleSlug: string;
    holderId: string;
    cns: string;
    bloodType: string;
    motherName: string;
    race: string;
    nationality: string;
    naturalness: string;
    otherPhones: any;
    corporateCredit: number;

    constructor(object?: any) {
        if (object) {
            for (const prop of Object.keys(object)) {
                this[prop] = object[prop];
            }
        }
    }
}
