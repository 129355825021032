<div class="flex flex-col  w-full   overflow-y-auto">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between  bg-blue-600 text-on-primary">
        <div class="text-lg font-medium p-4">Cadastro de Banco</div>
        <button
                mat-icon-button
                (click)="matDialogRef.close()"
                [tabIndex]="-1">
            <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <!-- Card form -->
    <form
            class="flex flex-col flex-0 items-start w-full p-6 sm:p-8 space-y-6 overflow-y-auto"
            [formGroup]="cardForm">

        <!-- Title -->
        <mat-form-field
                class="w-4/12"
                [subscriptSizing]="'dynamic'">
            <mat-label>Código do Banco</mat-label>
            <textarea
                    matInput
                    [formControlName]="'code'"
                    [rows]="1"
                    cdkTextareaAutosize
                    [cdkAutosizeMinRows]="1">
            </textarea>
        </mat-form-field>

        <!-- Description -->
        <mat-form-field
                class="w-full"
                [subscriptSizing]="'dynamic'">
            <mat-label>Banco</mat-label>
            <textarea
                    matInput
                    [formControlName]="'name'"
                    [rows]="1"
                    cdkTextareaAutosize
                    [cdkAutosizeMinRows]="1">
            </textarea>
        </mat-form-field>

        <div class="self-end mt-4">
            <button mat-dialog-close class="cancel-button mx-2" mat-raised-button>Cancelar</button>
            <button class="save-button md:self-end" color="primary" (click)="save()" mat-raised-button>{{ action }}
            </button>
        </div>
    </form>
</div>

