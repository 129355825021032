import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MarketplaceContractService } from '../../../marketplace/marketplace-contract/service/marketplace-contract.service';
import Swal from 'sweetalert2';
import { RiskComponent } from '../../../marketplace/marketplace-contract/components/risk/risk.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { NgForOf, NgIf } from '@angular/common';
import { BankService } from '../../service/bank.service';

@Component({
    selector: 'app-bank',
    templateUrl: './bank.component.html',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatInputModule,
        MatRadioModule,
        NgForOf,
        NgIf,
        MatDialogModule
    ],
    styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {
    cardForm: FormGroup;
    action = 'Salvar';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public matDialogRef: MatDialogRef<RiskComponent>,
                private cdRef: ChangeDetectorRef,
                private bankService: BankService,
                private formBuilder: FormBuilder,
    ) {
        this.cardForm = this.formBuilder.group({
            code: ['', Validators.required],
            name: [''],
        });
    }

    ngOnInit(): void {

        if (this.data.action == 'edit') {
            this.action = 'Editar';
            this.cardForm.patchValue({
                risk_code: this.data.risk.riskCode,
                name: this.data.risk.name,
                //slug: this.data.risk.slug,
            });
        }

    }

    save() {
        if (this.action === 'Salvar') {
            if (this.cardForm.valid) {
                this.bankService.storeBankingInstitution(this.cardForm.value).subscribe((response) => {
                    Swal.fire('Cadastrar', 'Cadastro realizado com sucesso.', 'success');
                });
            } else {
                Swal.fire('Erro', 'Preencha os campos obrigatórios.', 'error');
            }
            this.matDialogRef.close()
        } else {

            if (this.cardForm.valid) {
                this.bankService.updateBank( 2,this.cardForm.value).subscribe((response) => {
                    Swal.fire('Editar', 'Cadastro editado com sucesso.', 'success');
                    console.log(response);
                });
            } else {
                Swal.fire('Erro', 'Preencha os campos obrigatórios.', 'error');
            }
        }

    }
}
