import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {BehaviorSubject, map, Observable, tap} from 'rxjs';
import { AbstractService } from '../../../core/commons/services/abstract.service';
import { File } from '../model/file';
import { Utils } from '../../utils/utils';

@Injectable({ providedIn: 'root' })
export class FileService extends AbstractService<File> {

    _file: BehaviorSubject<File | null> = new BehaviorSubject(null);
    private _files: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'files', File)
    }

    // Private

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get files(): Observable<any> {
        return this._files.asObservable();
    }

    /**
     * Getter for item
     */
    get data$(): Observable<File> {
        return this._file.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get files
     */
    getFiles(): Observable<any> {
        return this._httpClient.get<File[]>('/').pipe(
            tap((response) => {
                this._files.next(response);
            }),
        );
    }

    save(object: File | any, path: string = ''): Observable<File> {
        const url = Utils.isEmpty(path) ? this.baseUrl : `${ this.baseUrl }/${ path }`;
        return this.http.post<File>(url, object);
    }

    // download(file: File) {
    //     return this.http.get(`${this.baseUrl}/${file.slug}/download`)
    //         .pipe(
    //             map(response => {
    //                 // @ts-ignore
    //                 return response.url
    //             })
    //         );
    // }
}
