import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AbstractService } from '../../../../core/commons/services/abstract.service';
import { Reminder } from '../models/reminder';
import { CompanyType } from '../../../commons/enums/company-type.enum';
import { ReminderEvent } from '../enums/patient-role.enum';

@Injectable({ providedIn: 'root' })
export class ReminderService extends AbstractService<Reminder> {
    _reminder: BehaviorSubject<Reminder | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'assistant/reminders', Reminder)
    }

    // Private
    private _reminders: BehaviorSubject<any> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get reminders(): Observable<any> {
        return this._reminders.asObservable();
    }

    /**
     * Getter for item
     */
    get reminder$(): Observable<Reminder> {
        return this._reminder.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Reminders
     */
    getReminders(): Observable<any> {
        return this._httpClient.get<Reminder[]>('/').pipe(
            tap((response) => {
                this._reminders.next(response);
            }),
        );
    }

    list(page: number = 1, limit: number = 10, searchTerm = null, uuid = null, urlPrefix = null): Observable<any> {
        let additionalSearch = "";
        additionalSearch += "&filterBy_event=" + ReminderEvent.GENERATED_GUIDE;
        super.setAdditionalSearch(additionalSearch);

        return super.list(page, limit, searchTerm, uuid, urlPrefix);
    }
}
