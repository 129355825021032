// tenant.state.ts

import { createReducer, on } from '@ngrx/store';
import { setTenant } from '../actions/tenant.action';
import { Tenant } from '../model/tenant';

export interface TenantState {
    currentTenant: Tenant | null;
}

export const initialState: TenantState = {
    currentTenant: null,
};

const _tenantReducer = createReducer(
    initialState,
    on(setTenant, (state, { tenant }) => ({
        ...state,
        currentTenant: tenant,
    }))
);

export function tenantReducer(state: TenantState | undefined, action: any) {
    return _tenantReducer(state, action);
}
