import { AsyncPipe, NgIf, NgOptimizedImage } from "@angular/common";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { FuseFullscreenComponent } from "@fuse/components/fullscreen";
import { FuseLoadingBarComponent } from "@fuse/components/loading-bar";
import { FuseNavigationService, FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { NavigationService } from "app/core/navigation/navigation.service";
import { Navigation } from "app/core/navigation/navigation.types";
import { UserService } from "app/core/user/service/user.service";
import { User } from "app/core/user/model/user.types";
import { LanguagesComponent } from "app/layout/common/languages/languages.component";
import { MessagesComponent } from "app/layout/common/messages/messages.component";
import { NotificationsComponent } from "app/layout/common/notifications/notifications.component";
import { QuickChatComponent } from "app/layout/common/quick-chat/quick-chat.component";
import { ShortcutsComponent } from "app/layout/common/shortcuts/shortcuts.component";
import { UserComponent } from "app/layout/common/user/user.component";
import { Observable, Subject, takeUntil } from "rxjs";
import { selectCurrentTenant } from "../../../core/tenant/selectors/tenant.selector";
import { Tenant } from "../../../core/tenant/model/tenant";
import { Store } from "@ngrx/store";
import {
    faBarChart,
    faBell,
    faBuilding,
    faBuildingCircleArrowRight,
    faCalendar,
    faChartLine,
    faMoneyBill,
    faClipboardUser,
    faComment,
    faFileLines,
    faFileMedical,
    faFileSignature,
    faFileWaveform,
    faFlaskVial,
    faFolderClosed,
    faGears,
    faHandHoldingMedical,
    faHandshake,
    faHeadset,
    faHospital,
    faHospitalUser,
    faHouseChimneyMedical,
    faHouseMedicalFlag,
    faLandmarkFlag,
    faLaptopMedical,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faPaste,
    faPeopleArrows,
    faPeopleGroup,
    faPeopleLine,
    faPerson,
    faPills,
    faShareNodes,
    faSquareH,
    faStethoscope,
    faSuitcaseMedical,
    faSyringe,
    faTags,
    faUser,
    faUserClock,
    faUserDoctor,
    faUserNurse,
    faUserTie,
    faVialVirus,
    faShop,
    faBuildingUser,
    faUsersGear,
    faMoneyCheck,
    faFileText,
    faMedal,
    faMoneyCheckAlt,
    faStore,
    faMoneyBills,
} from "@fortawesome/free-solid-svg-icons";
import { PlatformType } from "app/modules/commons/enums/platform-type.enum";
import { MenuHelper } from "app/layout/common/menu/menu.helper";
import { UserRole } from "app/modules/commons/enums/user-role.enum";
import { SharedService } from "app/modules/shared/services/shared.service";
import { DayCalendarComponent } from '../../../../@fuse/components/calendar/day-calendar/day-calendar.component';
import { ImgPlansComponent } from '../components/img-plans/img-plans.component';

@Component({
    selector: "default-layout",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        NotificationsComponent,
        UserComponent,
        NgIf,
        MatIconModule,
        MatButtonModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        //SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        RouterOutlet,
        QuickChatComponent,
        NgOptimizedImage,
        AsyncPipe,
        DayCalendarComponent,
        ImgPlansComponent,
    ],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    menuData = [];
    tenant$: Observable<Tenant | null>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public platformType: any = null;
    localeId: any;
    userLocation: any = null;
    userLocales = [];
    currentImagePhoto: string;
    currentUserPhoto: string;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private store: Store,
        private sharedService: SharedService,
        protected _changeDetectorRef: ChangeDetectorRef,
    ) {
        this.tenant$ = this.store.select(selectCurrentTenant);
        this.tenant$.subscribe(tenant => {
            this.platformType = tenant.type;
        });

        // Ao atualizar a foto de perfil da unidade de saúde
        this.sharedService.serviceUnitPhoto$.subscribe(data => {
            this.currentImagePhoto = data;
            this._changeDetectorRef.markForCheck();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
            this.user = user;
            this.user = {
                ...user,
                permissions: JSON.parse(atob(user.permissions)),
            };
            this.currentUserPhoto = this.user.photo?.base64;
        });

        this.tenant$.subscribe(tenant => {
            if (this.user.role == UserRole.PROFESSIONAL) {
                this.menuData = MenuHelper.professionalMenu(tenant?.type);
            }else if(this.user.role == UserRole.SERVICE_UNIT_MANAGER){
                // if (this.user.role == 'SUM' && localStorage.getItem('userLocation')) {
                this.userLocation = this.user.userLocales.find(location => location.slug === localStorage.getItem('userLocation'));
                // }
                this.currentImagePhoto =  this.userLocation?.photo?.base64;
                this.menuData = MenuHelper.managerMenu(tenant?.type);
            } else if (this.user.role == UserRole.COMPANY_MANAGER) {
                this.userLocation = this.user.company;
                this.currentImagePhoto =  this.userLocation?.photo?.base64;
                this.menuData = MenuHelper.companyManagerMenu();
            }  else if (this.user.role == UserRole.PATIENT) {
                this.menuData = MenuHelper.patientMenu(tenant?.type);
            } else {
                this.menuData = MenuHelper.adminMenu(tenant?.type);
            }
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes("md");
            });
    }

    isServiceUnitManager() {
        return this.user.role == UserRole.SERVICE_UNIT_MANAGER;
    }

    isCompanyManager() {
        return this.user.role == UserRole.COMPANY_MANAGER;
    }

    isManager() {
        return this.isServiceUnitManager() || this.isCompanyManager();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
