<form [formGroup]="formGroup" class="flex w-full flex-col justify-between">
    <div *ngIf="data.type == 'T'" class="flex flex-row w-full">
        <div *ngIf="data.responseButton" class="flex sm:flex-row items-start w-full sm:w-30 mr-2">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Botão</mat-label>
                <input
                        [formControlName]="'responseButton'"
                        readonly
                        matInput
                >
            </mat-form-field>
        </div>
        <div class="flex flex-col items-start w-full">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Mensagem (Template)</mat-label>
                <textarea
                        [formControlName]="'content'"
                        readonly
                        matInput
                ></textarea>
            </mat-form-field>
            <div class="w-full" *ngFor="let messageCtrl of messagesFormArray.controls; let i = index">
                <message-component class="w-full" [messages]="messageCtrl.value" (formValueChanged)="setValue($event, i)"></message-component>
            </div>
        </div>
    </div>
    <div *ngIf="data.type == 'F'" class="flex flex-row">
        <div *ngIf="data.responseButton" class="flex sm:flex-row items-start w-full sm:w-30 mr-2">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Botão</mat-label>
                <input
                        [formControlName]="'responseButton'"
                        readonly
                        matInput
                >
            </mat-form-field>
        </div>
        <div class="flex flex-col items-start w-full">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Mensagem</mat-label>
                <textarea
                        [formControlName]="'content'"
                        matInput
                ></textarea>
                <mat-icon (click)="openDialog()" class="self-center cursor-pointer" color="accent" matSuffix>info_outline</mat-icon>
            </mat-form-field>
            <div class="w-full" *ngFor="let messageCtrl of messagesFormArray.controls">
                <message-component class="w-full" [messages]="messageCtrl.value" (formValueChanged)="setValue($event, messageCtrl)"></message-component>
            </div>
        </div>
    </div>
    <div *ngIf="data.type == 'E'" class="flex flex-row">
        <div class="flex flex-col items-start w-full">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Mensagem em caso de erro</mat-label>
                <textarea
                        [formControlName]="'content'"
                        matInput
                ></textarea>
                <mat-icon (click)="openDialog()" class="self-center cursor-pointer" color="accent" matSuffix>info_outline</mat-icon>
            </mat-form-field>
            <div class="w-full" *ngFor="let messageCtrl of messagesFormArray.controls">
                <message-component class="w-full" [messages]="messageCtrl.value" (formValueChanged)="setValue($event, messageCtrl)"></message-component>
            </div>
        </div>
    </div>
    <div *ngIf="data.type == 'RA'" class="flex flex-row">
        <div class="flex flex-col items-start w-full">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Mensagem caso o usuário tente responder novamente</mat-label>
                <textarea
                        [formControlName]="'content'"
                        matInput
                ></textarea>
                <mat-icon (click)="openDialog()" class="self-center cursor-pointer" color="accent" matSuffix>info_outline</mat-icon>
            </mat-form-field>
            <div class="w-full" *ngFor="let messageCtrl of messagesFormArray.controls">
                <message-component class="w-full" [messages]="messageCtrl.value" (formValueChanged)="setValue($event, messageCtrl)"></message-component>
            </div>
        </div>
    </div>
    <div *ngIf="data.type == 'GE'" class="flex flex-row">
        <div class="flex flex-col items-start w-full">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Mensagem em caso de falha no envio da guia</mat-label>
                <textarea
                        [formControlName]="'content'"
                        matInput
                ></textarea>
                <mat-icon (click)="openDialog()" class="self-center cursor-pointer" color="accent" matSuffix>info_outline</mat-icon>
            </mat-form-field>
            <div class="w-full" *ngFor="let messageCtrl of messagesFormArray.controls">
                <message-component class="w-full" [messages]="messageCtrl.value" (formValueChanged)="setValue($event, messageCtrl)"></message-component>
            </div>
        </div>
    </div>
</form>
