import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { DefaultLayoutComponent } from "app/layout/layouts/default/default.component";
import { FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { FormBuilder, FormGroup, FormsModule, NgForm, ReactiveFormsModule, UntypedFormArray, } from '@angular/forms';
import { RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Status } from "app/modules/commons/enums/status.enum";
import { ReminderService } from '../../service/reminder.service';
import { Reminder } from '../../models/reminder';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Utils } from '../../../../utils/utils';
import Swal from 'sweetalert2';
import { MatTabsModule } from "@angular/material/tabs";
import { FlowComponent } from "../flow-messages/flow.component";
import { FlowService } from "../flow-messages/service/flow.service";
import { Flow } from "../flow-messages/flow";
import { MessagesComponent } from "../messages/messages.component";
import { VariableExampleComponent } from '../messages/variable-example/variable-example.component';
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "assistent-component",
    templateUrl: "./assistent.component.html",
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./assistent.component.scss"],
    imports: [MatSlideToggleModule, DefaultLayoutComponent, FuseVerticalNavigationComponent, RouterOutlet,
        CommonModule, DefaultLayoutComponent, FuseVerticalNavigationComponent,
        FormsModule, ReactiveFormsModule, MatIconModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule,
        MatOptionModule, MatDividerModule, MatCheckboxModule, MatRadioModule, MatButtonModule, MatAutocompleteModule, MatTooltipModule, MatTabsModule, FlowComponent],
})
export class AssistentComponent {
    @ViewChild('form') form: NgForm;
    @ViewChild(FlowComponent, { static: false }) flowComponent: FlowComponent;

    formGroup: FormGroup;
    data: Reminder;
    tabs = "stickyNotes";
    protected readonly Status = Status;
    private reminder: any;
    flow: Flow;

    /**
     * Constructor
     */
    constructor(private _formBuilder: FormBuilder,
                protected _changeDetectorRef: ChangeDetectorRef,
                private reminderService: ReminderService,
                public dialog: MatDialog,
                private flowService: FlowService) {
        this.formGroup = this._formBuilder.group({
            status: [''],
            messages: this._formBuilder.array([])
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.reminderService.list(1, 1).subscribe((response) => {
            this.reminder = response.data[0];
            this.reminderService.subRoute(`/${ this.reminder.slug }/messages`).subscribe((response) => {
                const messages = response.data;
                const controls = [];
                this.formGroup = this._formBuilder.group({
                    status: [this.reminder.status == Status.ACTIVE],
                    messages: this._formBuilder.array([])
                });
                for (const message of messages) {
                    const control = this._formBuilder.group({
                        time: [message.time],
                        content: [message.content]
                    });

                    controls.push(control);
                    (this.formGroup.get('messages') as UntypedFormArray).push(control);
                    this._changeDetectorRef.markForCheck();
                }

                if (Utils.isEmpty(controls)) {
                    this.addTimeMessageField();
                }
            }, error => {
                this.formGroup = this._formBuilder.group({
                    status: [this.reminder.status],
                    messages: this._formBuilder.array([])
                });
            })

            this._changeDetectorRef.markForCheck();
        }, error => {
            this.formGroup = this._formBuilder.group({
                status: [''],
                messages: this._formBuilder.array([])
            });
            this.addTimeMessageField();
        });

        this.flowService.subRoute('?filterBy_type=AP').subscribe((data: any) => {
            this.flow = new Flow(data.data[0]);
        })
    }

    handleChangeTabs = (tab: string) => {
        this.tabs = tab;
        return;
    };

    getStatusLabel() {
        return this.formGroup?.value?.status ? "Sim" : "Não";
    }

    save() {
        const rawValue = this.formGroup.getRawValue();
        this.reminder.messages = rawValue.messages;
        this.reminder.status = rawValue.status ? Status.ACTIVE : Status.INACTIVE;
        const seenTimes = {};

        for (const message of rawValue.messages) {
            if (seenTimes[message.time]) {
                Swal.fire('Erro', "Existe mais de uma entrada de mensagem para o mesmo período", 'error');
                return;
            }

            if (Utils.isEmpty(message.content) || Utils.isEmpty(message.time)) {
                Swal.fire('Erro', "Existe mensagens que podem está com período e/ou conteúdo vazio(s)", 'error');
                return;
            }

            seenTimes[message.time] = true;
        }

        this.reminderService.save(this.reminder).subscribe((response) => {
            Swal.fire('Sucesso', "Salvo com sucesso.", 'success');
            this.ngOnInit();
        });
    }

    saveFlow() {
        this.flow = this.flowComponent.formGroup.getRawValue();

        this.flowService.save(this.flow).subscribe((response: any) => {
            Swal.fire('Sucesso', "Salvo com sucesso.", 'success');
            this.ngOnInit();
        })
    }

    addTimeMessageField() {
        const timeMessageField = this._formBuilder.group({
            time: [''],
            content: ['']
        });

        (this.formGroup.get('messages') as UntypedFormArray).push(timeMessageField);

        this._changeDetectorRef.markForCheck();
    }

    removeTimeMessageField(index: number): void {
        const timeMessageArray = this.formGroup.get('messages') as UntypedFormArray;

        timeMessageArray.removeAt(index);

        this._changeDetectorRef.markForCheck();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(VariableExampleComponent);
    }
}
