<form class="flex flex-col" [formGroup]="formGroup">
    <div class="flex w-full sm:flex-row flex-col justify-between">
        <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Número de Tentativas</mat-label>
                <input mask="0*" [formControlName]="'retryTimes'" id="retryTimes" matInput>
            </mat-form-field>
        </div>
        <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2">
            <mat-form-field class="flex mt-4 w-full">
                <mat-label class="font-bold w-40">Intervalo entre as tentativas (Em Horas)</mat-label>
                <input mask="0*" [formControlName]="'retryInterval'" id="retryInterval" matInput>
            </mat-form-field>
        </div>
    </div>
    <message-component #messageComponent [formGroupName]="'template'" *ngIf="data.template" [messages]="data?.template" (formValueChanged)="setValue($event)"></message-component>
</form>
