<div class="flex flex-col min-w-0 my-5 mx-7">
<!--    <div class="flex items-center">-->
<!--        <button class="font-normal py-3 px-6" (click)="handleChangeTabs('stickyNotes')" [ngClass]="tabs === 'stickyNotes' ? 'active' : 'inactive'">-->
<!--            Lembretes-->
<!--        </button>-->
<!--    </div>-->
    <div class="flex-auto p-6 sm:p-10">
        <mat-tab-group>
            <mat-tab label="Lembretes">
                <form [formGroup]="formGroup" class="flex flex-col mt-4 px-8 bg-card rounded overflow-hidden">
                    <h1 class="text-lg mb-3">Lembrete de Agendamento</h1>
                    <div class="flex flex-col items-start w-full sm:w-1/2 mr-2">
                        <mat-label class="font-bold">Deseja enviar o lembrete de atendimento?</mat-label>
                        <div class="flex sm:flex-row items-start sm:items-center height-60 ml-2">
                            <mat-slide-toggle id="statusToggle" class="mr-4"
                                              [formControlName]="'status'"></mat-slide-toggle>
                            <mat-label for="statusToggle">{{ getStatusLabel() }}</mat-label>
                        </div>
                    </div>

                    <div class="gt-xs:flex-row pt-4">
                        <mat-label class="font-bold">Insira o período e lembretes dos atendimento que deseja enviar ao paciente</mat-label>
                        <br>
                        <br>

                        <ng-container
                                *ngFor="let messageControl of formGroup.get('messages')['controls']; let i = index; let first = first; let last = last;">
                            <div class="w-full">
                                <p class="font-bold text-sm color-gray">Pré visualização da mensagem</p>
                                <textarea class="w-full text-start p-2 whitespace-pre-line rounded-2xl bg-gray-100 my-1" value="Olá {{ '{PACIENTE_NOME_SOCIAL}' }}, {{ messageControl.get('content').value }}

Local: {{ '{LOCAL_CONSULTA_EXAME}' }}
Especialidade/Exame: {{ '{NOME_CONSULTA_EXAME}' }}
Data: {{ '{DATA_CONSULTA_EXAME}' }}

Lembrando que é OBRIGATÓRIO o paciente apresentar o Pedido Médico (encaminhamento) e a Guia de Autorização no momento do atendimento para realizar a consulta, exame ou cirurgia.

Te aguardamos
Até breve, Dr. Ingá!
Assistente Virtual da Secretaria de Saúde de Maringá">
                                </textarea>
                            </div>
                            <div class="flex">
                                <mat-form-field class="pt-6 pr-5 sm:w-1/4">
                                    <mat-select
                                            [value]="messageControl.get('time').value ?? ''"
                                            [formControl]="messageControl.get('time')"
                                            [placeholder]="'Escolha o tempo'">
                                        <mat-option [value]="'H24'">24 horas</mat-option>
                                        <mat-option [value]="'H48'">48 horas</mat-option>
                                        <mat-option [value]="'H72'">72 horas</mat-option>
                                        <mat-option [value]="'D7'">7 dias</mat-option>
                                        <mat-option [value]="'D15'">15 dias</mat-option>
                                        <mat-option [value]="'D30'">30 dias</mat-option>
                                        <mat-option [value]="'D45'">45 dias</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="pt-6 sm:w-3/4">
                                    <input matInput [formControl]="messageControl.get('content')" placeholder="Conteúdo">
                                    <mat-icon (click)="openDialog()" class="self-center cursor-pointer" color="accent" matSuffix>info_outline</mat-icon>
                                </mat-form-field>
                                <ng-container *ngIf="!(first && last)">
                                    <div
                                            class="flex items-center w-10 pl-2">
                                        <button
                                                class="w-8 h-8 min-h-8"
                                                mat-icon-button
                                                (click)="removeTimeMessageField(i)">
                                            <mat-icon
                                                    class="icon-size-5 opacity-70"
                                                    [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer" style="color: gray" (click)="addTimeMessageField()">
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                            <span class="ml-2 font-medium text-secondary group-hover:underline">Adicionar Mensagem</span>
                        </div>
                    </div>

                    <div class="flex flex-col items-start pt-4 w-full mr-2">
                        <mat-label class="font-bold">Deseja que o paciente possa confirmar/cancelar a presença?</mat-label>
                        <div class="flex sm:flex-row items-start sm:items-center height-60 ml-2">
                            <mat-slide-toggle class="mr-4" disabled></mat-slide-toggle>
                            <span>Inativo</span>
                        </div>
                    </div>

                    <div class="flex flex-col items-start pt-4 w-full mr-2">
                        <mat-label class="font-bold">Deseja que o Assistente Virtual realize agendamento automaticamente, caso o paciente cancele no lembrete?</mat-label>
                        <div class="flex sm:flex-row items-start sm:items-center height-60 ml-2">
                            <mat-slide-toggle class="mr-4" disabled></mat-slide-toggle>
                            <span>Inativo</span>
                        </div>
                    </div>

                    <div class="self-end mt-4">
                        <button (click)="save()" class="save-button md:self-end mx-2" color="primary" mat-raised-button>
                            Salvar
                        </button>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="Agendamento">
                <div class="flex flex-col">
                    <div class="flex flex-col">
                        <flow-component #flowComponent *ngIf="flow" [flow]="flow"></flow-component>
                    </div>
                    <div class="self-end mt-4">
                        <button (click)="saveFlow()" class="save-button md:self-end mx-2" color="primary" mat-raised-button>
                            Salvar
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
