import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { DatePipe, JsonPipe, NgForOf, NgIf, NgStyle, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { PrescriptionItemComponent } from '../components/prescription-item/prescription-item.component';
import { MatIconModule } from '@angular/material/icon';
import { PrescriptionCardComponent } from '../components/prescription-card/prescription-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '../../file/service/file.service';
import { PrescriptionReferralsPdfComponent } from '../components/prescription-referrals-pdf/prescription-referrals-pdf.component';
import { PrescriptionMedicinePdfComponent } from '../components/prescription-medicine-pdf/prescription-medicine-pdf.component';
import { PrescriptionPdfComponent } from '../components/prescription-pdf/prescription-pdf.component';
import { MatButtonModule } from '@angular/material/button';
import { Location } from '@angular/common';

@Component({
    selector: 'app-user-patient-list',
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        PrescriptionItemComponent,
        DatePipe,
        MatIconModule,
        PrescriptionCardComponent,
        TitleCasePipe,
        UpperCasePipe,
        NgStyle,
        JsonPipe,
        PrescriptionReferralsPdfComponent,
        PrescriptionMedicinePdfComponent,
        PrescriptionPdfComponent,
        MatButtonModule
    ],
    templateUrl: './user-patient-list.component.html',
    styleUrl: './user-patient-list.component.scss'
})
export class UserPatientListComponent implements OnInit, OnDestroy {
    appointmentPrescriptions: string;
    data: any;
    slug: string;
    files: any[] = [];
    type: string;
    presType: string;
    mediciPdf: any;

    showDelayedContent: boolean = false;
    showDelayedContent2: boolean = false;

    prescriptionType = [
        { 'PC': 'EXAMES' },
        { 'MPR': 'MEDICAMENTOS' },
        { 'CT': 'ATESTADO' },
        { 'OR': 'ORIENTAÇÕES' },
        { 'RP': 'RELATÓRIO' }
    ];

    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        public fileService: FileService,
        private router: Router,
        private location: Location
    ) {
        this.appointmentPrescription();
        const state = this.router.getCurrentNavigation()?.extras.state;
    }

    ngOnInit(): void {
        this.type = localStorage.getItem('type');
        this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
        this.prescriptionDetails();

        setTimeout(() => this.showDelayedContent = true, 1000);

        const obj = this.prescriptionType.find(o => o.hasOwnProperty(this.type));
        this.presType = obj ? obj[this.type] : null;
    }

    ngOnDestroy(): void {
        localStorage.removeItem('type');
    }

    back(): void {
        this.location.back();
    }

    prescriptionDetails() {
        this.userService.appointmentPrescriptionsDetails(this.slug, this.type).subscribe((response) => {
            this.data = response;

            console.log('DATA', this.data, this.type);

            const count = response?.data?.length ? response?.data?.length : response?.files?.length;

            if (count == 0) {
                this.showDelayedContent = false;
                setTimeout(() => this.showDelayedContent2 = true, 8000);
            } else {
                this.showDelayedContent = false;
                this.mediciPdf = this.data.files.filter((item: { base64: any; }) => item.base64).map((item: {
                    base64: any;
                }) => item.base64);
            }

        });
    }

    appointmentPrescription() {
        this.userService.appointmentPrescription().subscribe((response) => {
            this.appointmentPrescriptions = response.data.filter((item: { slug: string; }) => item.slug === this.slug);
        });
    }

    clickButton(serviceName: string) {
            console.log('Arquivos', serviceName);
        this.processData(this.data, serviceName);
        this.files.forEach((file) => {
            this.downloadPDF(file.base64);
        });
    }

    processData(data: any, serviceName: string): void {
        if (data && data.files && Array.isArray(data.files)) {
            data.files.forEach((subgroup: any) => {
                const fileName = subgroup.fileName;
                const filePrefix = fileName.split(' - ')[0];
                if (filePrefix === serviceName) {
                    this.files.push(subgroup);
                }
            });
        }
    }

    downloadPDF(base64: string) {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
            // If the new window/tab was successfully created, focus on it
            newWindow.focus();
        } else {
            // If the new window/tab couldn't be created (pop-up blockers, etc.), force download as fallback
            const a = document.createElement('a');
            a.href = url;
            a.download = 'document.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }

        // Clean up the URL object
        window.URL.revokeObjectURL(url);
    }


}
