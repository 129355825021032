<div [class.disabled]="count === 0" class="px-3 mb-2 pt-0 cursor-pointer" (click)="count !== 0 && navigateWithSlug(slug)">
    <div class="w-full py-3 text-xs font-bold px-3 bg-white rounded-2xl shadow hover:transition-md duration-300 ease-in-out hover:scale-105 md:hover:scale-100 focus:scale-50 active:scale-100 disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
         type="button">
        <div class="flex justify-between items-center">
            <div class="flex flex-col justify-start">
                <div class="text-sm font-medium leading-relaxed text-gray-900">{{ title }}</div>
                <div style="font-size: 9px"
                     class="font-medium leading-relaxed text-gray-500">{{ count }}
                </div>
            </div>
            <div class="flex flex-col justify-end cursor-pointer">
                <mat-icon [ngStyle]="{'color': 'rgb(14, 128, 203)'}" svgIcon="keyboard_arrow_right"></mat-icon>
            </div>
        </div>
    </div>
</div>
