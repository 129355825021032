// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The index of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    serverUrl: 'https://clubmedapi.bclcraft.casacam.net/api',
    //serverUrl: 'https://api-homolog.clubmed.digital/api', // Alternar aqui caso queira acessar a API em produção. Deve acessar o sistema com homolog.localhost:4200
    broadcastingServerUrl: 'https://api-homolog.clubmed.digital',
};

/*

export const environment = {
    production: false,
    //serverUrl: 'http://localhost/api'
    serverUrl: 'http://localhost/api', // Foi trocado aqui pois estava dando erro de CORS ao acessar a API localmente
    broadcastingServerUrl: 'http://127.0.0.1',

};
*/
