<div class="company-table flex justify-center ">
    <router-outlet></router-outlet>
    <div class="flex justify-center  flex-col flex-auto min-w-0">
        <div
                class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
            <div class="flex-1 min-w-0">
                <div>
                    <div class="flex items-center font-medium gap-2">
                        <p class="text-[16px] text-[#1C2D48] font-normal">Cadastro de contas</p>
                    </div>
                </div>
                <div class="flex justify-between">
                    <div class="mt-2">
                        <h2 class="mt-[7px] text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                            Contas Financeiras
                        </h2>
                    </div>
                    <div class="flex justify-end">
                        <div class="flex">
                            <button  (click)="openNoteDialog()" class="create-button ml-4" mat-flat-button>
                                <mat-icon  [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                                <span class="ml-2 mr-1">Cadastrar Banco</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


         <div   class="flex w-screen mt-5">
             <div class="flex m-0" [ngSwitch]="activeComponent">
                 <div class="flex flex-row   justify-center cursor-pointer">
                     <div class="flex flex-col px-2  gap-2">
                         <ng-container>
                             <ng-container>
                                 <fuse-card *ngIf="isComponentEnabledTwo"  (click)="setActiveComponent(2)" [class.active]="activeComponent === 2" class="flex flex-row max-w-full w-full filter-article hover:bg-gray-100 transition duration-300 ease-in-out" >
                                     <div class="flex items-center m-4">
                                         <div  class="text-1xl p-4 font-normal leading-tight text-gray-600">
                                             <mat-icon class="pt-1" svgIcon="mat_solid:account_balance"></mat-icon> - Conta Bancária
                                         </div>
                                     </div>
                                 </fuse-card>

                                 <fuse-card  *ngIf="isComponentEnabledOne"  (click)="setActiveComponent(1)" [class.active]="activeComponent === 1" class="flex flex-row max-w-full w-full filter-article hover:bg-gray-100 transition duration-300 ease-in-out">
                                     <div class="flex items-center m-4 p-4">
                                         <div   class="text-1xl font-normal leading-tight text-gray-600">
                                             <mat-icon class="pt-1" svgIcon="mat_solid:move_to_inbox"></mat-icon> - Caixinha Recepção
                                         </div>
                                     </div>
                                 </fuse-card>
                             </ng-container>
                         </ng-container>
                     </div>

                     <div class="flex flex-col justify-center">
                         <ng-container>
                             <ng-container>
                                 <div *ngIf="isComponentEnabledOne">
                                     <app-internal-account class="w-full" *ngSwitchCase="1"></app-internal-account>
                                 </div>

                                 <fuse-card *ngIf="isComponentEnabledTwo" class="flex flex-row  rounded-lg text-center bg-white p-8 ">
                                     <form [formGroup]="bankForm" *ngSwitchCase="2">
                                         <div class="w-full mt-3">
                                             <div class="text-xl">Cadastro</div>
                                         </div>
                                         <div class="flex flex-wrap -mx-3">
                                             <div class="w-full md:w-full px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Titular</mat-label>
                                                     <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'"
                                                               matPrefix></mat-icon>
                                                     <input [formControlName]="'holder'" matInput>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>{{documentLabel}}</mat-label>
                                                     <mat-icon
                                                             class="icon-size-5"
                                                             [svgIcon]="'heroicons_solid:credit-card'"
                                                               matPrefix></mat-icon>
                                                     <input   [mask]="documentMask"
                                                              (input)="toggleMask()"
                                                              [formControlName]="'document'" matInput>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Banco</mat-label>
                                                     <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'" matPrefix>¿
                                                     </mat-icon>
                                                     <input type="text" matInput [formControl]="myControl" [formControlName]="'bankSlug'"
                                                            [matAutocomplete]="auto">
                                                     <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                         <mat-option *ngFor="let option of filteredOptions | async"
                                                                     [value]="option">{{ option.name }} | {{ option.code }}
                                                         </mat-option>
                                                     </mat-autocomplete>
                                                 </mat-form-field>
                                             </div>
                                         </div>
                                         <div class="flex flex-wrap -mx-3">
                                             <div class="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Agência</mat-label>
                                                     <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"
                                                               matPrefix></mat-icon>
                                                     <input [formControlName]="'agency'" matInput>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-2/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Dígito</mat-label>
                                                     <input [formControlName]="'dvAgency'" matInput>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Número da Conta</mat-label>
                                                     <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"
                                                               matPrefix></mat-icon>
                                                     <input [formControlName]="'accountNumber'" matInput>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-2/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Dígito</mat-label>
                                                     <input [formControlName]="'dvAccounts'" matInput>
                                                 </mat-form-field>
                                             </div>
                                         </div>
                                         <div class="flex flex-wrap -mx-3">
                                             <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Tipo de Conta</mat-label>
                                                     <mat-select [(value)]="selectedAccountType" [formControlName]="'accountType'">
                                                         <mat-option></mat-option>
                                                         <mat-option [value]="account.value" *ngFor="let account of accountType">{{ account.name }}</mat-option>
                                                     </mat-select>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Gateway</mat-label>
                                                     <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"
                                                               matPrefix></mat-icon>
                                                     <input [formControlName]="'gatewayCode'" matInput>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Código recebido</mat-label>
                                                     <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"
                                                               matPrefix></mat-icon>
                                                     <input [formControlName]="'receivingCode'" matInput>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Chave Pix</mat-label>
                                                     <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"
                                                               matPrefix></mat-icon>
                                                     <input [formControlName]="'pixKey'" matInput>
                                                 </mat-form-field>
                                             </div>
                                             <div class="w-full md:w-6/12 px-3 mb-3 md:mb-0">
                                                 <mat-form-field class="w-full">
                                                     <mat-label>Tipo da chave Pix</mat-label>
                                                     <mat-select [(value)]="selectedPixType"  [formControlName]="'pixKeyType'">
                                                         <mat-option></mat-option>
                                                         <mat-option [value]="option.value" *ngFor="let option of pixType">{{ option.value }}</mat-option>
                                                     </mat-select>
                                                 </mat-form-field>
                                             </div>

                                         </div>
                                         <div class="mt-5 mb-5 border-t"></div>
                                         <div class="flex justify-end">
                                             <button (click)="goBack()" class="cancel-button mx-2" mat-raised-button>Cancelar</button>
                                             <button (click)="save()" class="save-button md:self-end mx-2" color="primary"
                                                     mat-raised-button>{{ action }}
                                             </button>
                                         </div>
                                     </form>
                                 </fuse-card>
                             </ng-container>
                         </ng-container>
                     </div>
                 </div>
             </div>
         </div>






<!--        <div [ngSwitch]="activeComponent" class="flex flex-row justify-center ">-->
<!--            <fuse-card class="flex flex-row rounded-lg text-start bg-white p-8 w-screen h-screen mt-10">-->
<!--                <div class="flex-auto p-6 sm:p-10">-->
<!--                    <div class="prose prose-sm max-w-full">-->
<!--                        <div class="flex flex-wrap -mx-4">-->
<!--                            <ng-container>-->
<!--                                <ng-container>-->
<!--                                    <fuse-card class="flex flex-row max-w-full w-full filter-article hover:bg-gray-100 transition duration-300 ease-in-out">-->
<!--                                        <div class="flex items-center m-4">-->
<!--                                            <div (click)="setActiveComponent(1)" class="text-1xl font-semibold leading-tight text-gray-600">-->
<!--                                                <mat-icon svgIcon="mat_solid:account_balance"></mat-icon> - Conta Bancária-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </fuse-card>-->

<!--                                    <fuse-card class="flex flex-row max-w-full w-full filter-article hover:bg-gray-200 transition duration-300 ease-in-out">-->
<!--                                        <div class="flex items-center m-4">-->
<!--                                            <div (click)="setActiveComponent(2)" class="text-1xl font-semibold leading-tight text-gray-600">-->
<!--                                                <mat-icon svgIcon="mat_solid:move_to_inbox"></mat-icon> - Caixinha Recepção-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </fuse-card>-->
<!--                                </ng-container>-->
<!--                            </ng-container>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <app-internal-account class="w-full" *ngSwitchCase="1"></app-internal-account>-->

<!--            <div  class=" flex  flex-row  w-full justify-center mt-10">-->
<!--                <fuse-card *ngSwitchCase="2" class="flex flex-row  rounded-lg text-center bg-white p-8 ">-->
<!--                    <form [formGroup]="bankForm">-->
<!--                        <div class="w-full mt-6">-->
<!--                            <div class="text-xl">Cadastro</div>-->
<!--                        </div>-->
<!--                        <div class="flex flex-wrap -mx-3">-->
<!--                            <div class="w-full md:w-full px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Titular</mat-label>-->
<!--                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'"-->
<!--                                              matPrefix></mat-icon>-->
<!--                                    <input [formControlName]="'holder'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>CPF/CNPJ</mat-label>-->
<!--                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"-->
<!--                                              matPrefix></mat-icon>-->
<!--                                    <input [formControlName]="'document'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Banco</mat-label>-->
<!--                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'" matPrefix>¿-->
<!--                                    </mat-icon>-->
<!--                                    <input type="text" matInput [formControl]="myControl" [formControlName]="'bankSlug'"-->
<!--                                           [matAutocomplete]="auto">-->
<!--                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">-->
<!--                                        <mat-option *ngFor="let option of filteredOptions | async"-->
<!--                                                    [value]="option">{{ option.name }} | {{ option.code }}-->
<!--                                        </mat-option>-->
<!--                                    </mat-autocomplete>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="flex flex-wrap -mx-3">-->
<!--                            <div class="w-full md:w-4/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Agência</mat-label>-->
<!--                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"-->
<!--                                              matPrefix></mat-icon>-->
<!--                                    <input [formControlName]="'agency'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-2/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Dígito</mat-label>-->
<!--                                    <input [formControlName]="'dvAgency'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-4/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Número da Conta</mat-label>-->
<!--                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"-->
<!--                                              matPrefix></mat-icon>-->
<!--                                    <input [formControlName]="'accountNumber'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-2/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Dígito</mat-label>-->
<!--                                    <input [formControlName]="'dvAccounts'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="flex flex-wrap -mx-3">-->
<!--                            <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Tipo de Conta</mat-label>-->
<!--                                    <mat-select [(value)]="selectedAccountType" [formControlName]="'accountType'">-->
<!--                                        <mat-option></mat-option>-->
<!--                                        <mat-option [value]="account.value" *ngFor="let account of accountType">{{ account.name }}</mat-option>-->
<!--                                    </mat-select>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Gateway</mat-label>-->
<!--                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"-->
<!--                                              matPrefix></mat-icon>-->
<!--                                    <input [formControlName]="'gatewayCode'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Código recebido</mat-label>-->
<!--                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"-->
<!--                                              matPrefix></mat-icon>-->
<!--                                    <input [formControlName]="'receivingCode'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Chave Pix</mat-label>-->
<!--                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'"-->
<!--                                              matPrefix></mat-icon>-->
<!--                                    <input [formControlName]="'pixKey'" matInput>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->
<!--                            <div class="w-full md:w-6/12 px-3 mb-6 md:mb-0">-->
<!--                                <mat-form-field class="w-full">-->
<!--                                    <mat-label>Tipo da chave Pix</mat-label>-->
<!--                                    <mat-select [(value)]="selectedPixType"  [formControlName]="'pixKeyType'">-->
<!--                                        <mat-option></mat-option>-->
<!--                                        <mat-option [value]="option.value" *ngFor="let option of pixType">{{ option.value }}</mat-option>-->
<!--                                    </mat-select>-->
<!--                                </mat-form-field>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                        <div class="mt-11 mb-10 border-t"></div>-->
<!--                        <div class="flex justify-end">-->
<!--                            <button (click)="goBack()" class="cancel-button mx-2" mat-raised-button>Cancelar</button>-->
<!--                            <button (click)="save()" class="save-button md:self-end mx-2" color="primary"-->
<!--                                    mat-raised-button>{{ action }}-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </fuse-card>-->
<!--            </div>-->
<!--            </fuse-card>-->
<!--        </div>-->
    </div>
</div>























