<div *ngIf="!isFullScreen" class="flex flex-col bg-gray-200 rounded-md cursor-move absolute z-999"
     [class]="this.minimize ?? this.selectedSize" cdkDrag>
    <div class="px-4 flex justify-between items-center">
        <div class="flex items-center">
            <p class="font-bold text-neutral-700 text-lg">Video Chamada</p>
            <p class="font-bold text-green-600 text-md ml-4">Online</p>
        </div>
        <mat-form-field class="pt-6 pr-5 sm:w-1/4">
            <mat-select [value]="this.selectedSize" (selectionChange)="setSize($event.value)">
                <mat-option [value]="'w-[1920px] h-[1080px]'">1920 x 1080</mat-option>
                <mat-option selected [value]="'w-[1280px] h-[720px]'">1280 x 720</mat-option>
                <mat-option [value]="'w-[854px] h-[480px]'">854 x 480</mat-option>
                <mat-option [value]="'w-[640px] h-[360px]'">640 x 360</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex">
            <p (click)="minimizeVideoCall()" class="hover:bg-gray-300 rounded-md p-2 m-2">Minimizar -</p>
            <p (click)="maximizeVideoCall()" class="hover:bg-gray-300 rounded-md p-2 m-2">Maximizar +</p>
        </div>
    </div>
    <div [hidden]="this.showVideoCall" id="jitsi-frame" class="w-full">

    </div>
    <div class="items-center">
        <i (click)="executeCommand('toggleAudio')"></i>
        <i (click)="executeCommand('hangup')"></i>
        <i (click)="executeCommand('toggleVideo')"></i>
        <i (click)="executeCommand('toggleShareScreen')"></i>
    </div>
</div>
<div *ngIf="isFullScreen">
    <div [hidden]="this.showVideoCall" id="jitsi-frame" class="w-full">

    </div>
</div>
