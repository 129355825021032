import {
    faBank, faBasketShopping,
    faBell, faBoxArchive, faBoxesPacking, faBoxOpen,
    faBuilding,
    faBuildingCircleArrowRight, faBuildingColumns, faBuildingUser,
    faCalendar, faCartShopping, faChartArea,
    faChartLine,
    faClipboardUser, faDisease, faFileCircleExclamation, faFileContract, faFileInvoiceDollar,
    faFileLines,
    faFileMedical, faFileMedicalAlt,
    faFileSignature,
    faFileText, faFileWaveform, faFire,
    faGears, faGrinAlt,
    faHandHoldingMedical,
    faHandshake,
    faHeadset, faHistory, faHospital,
    faHospitalUser,
    faHouseMedicalFlag, faIdCardClip, faLandmarkFlag,
    faLaptopMedical,
    faMagnifyingGlassPlus,
    faMoneyBill, faMoneyBills, faMoneyBillWave, faMoneyCheck, faMoneyCheckDollar,
    faPaste,
    faPeopleGroup, faPills, faPlane, faPlaneArrival, faSave, faShareNodes, faShop, faShoppingBag, faShoppingBasket,
    faSquareH,
    faStethoscope,
    faSuitcaseMedical, faSyringe,
    faUser,
    faUserClock,
    faUserDoctor, faUserNurse, faUsersBetweenLines, faUsersGear, faVialVirus
} from '@fortawesome/free-solid-svg-icons';
import { PlatformType } from 'app/modules/commons/enums/platform-type.enum';
import medicineRoutes from '../../../modules/medicine/routes/medicine.routes';
import { config } from 'rxjs';
import marketplacePlansRoutes from '../../../modules/marketplace/marketplace-plans/routes/marketplace-plans.routes';

export class MenuHelper {
    static managerMenu(tenantType=null) {
        return [
            {
                id: "calendar",
                title: "Agenda",
                type: "basic",
                link: "/calendar",
                icon: faCalendar
            },
            {
                id: "myAccount",
                title: "Minha Conta",
                type: "basic",
                link: "/my-account",
                icon: faUser,
            },
            {
                id: "myPatients",
                title: "Pacientes",
                type: "basic",
                link: "/resources/users/patients",
                icon: faPeopleGroup,
            },
            {
                id: "myServices",
                title: "Meus Atendimentos",
                type: "basic",
                link: "/resources/appointments",
                icon: faHospitalUser,
            },
            {
                id: "settings",
                title: "Minha Equipe",
                type: "collapsable",
                icon: faPeopleGroup,
                children: [
                    {
                        id: "users.manager",
                        title: "Gestores",
                        type: "basic",
                        link: "/resources/users/managers",
                        resource: "Manager",
                    },
                    {
                        id: "users.professional",
                        title: "Profissionais",
                        type: "basic",
                        link: "/resources/users/professionals",
                        resource: "Professional",
                        // icon: faUserDoctor
                    },
                ],
            },
            (tenantType==PlatformType.CLUBMED) ? {
                id: "financial",
                title: "Financeiro",
                type: "collapsable",
                link: "/dashboardsdf",
                icon: faMoneyBill,
                children: [
                    {
                        id: "financial.list",
                        title: "Conta Interna Clubmed",
                        type: "basic",
                        icon: faBoxesPacking,
                        link: "/financial/clubmed-internal-account",
                    },
                    {
                        id: "financial.list",
                        title: "Histórico",
                        type: "basic",
                        icon: faHistory,
                        link: "/financial/financial-history",
                    },
                    {
                        id: "banks",
                        title: "Contas Financeiras",
                        type: "basic",
                        icon: faBank,
                        link: "/resources/bank",
                    },

                ],
            } : {},
            (tenantType==PlatformType.CLUBMED) ? {
                id: "marketplace",
                title: "Marketplace",
                type: "collapsable",
                icon: faShop,
                children: [
                    {
                        id: "marketplace.list",
                        title: "Serviços",
                        type: "basic",
                        link: "/resources/marketplace/products",
                    },
                    {
                        id: "marketplace.contracts",
                        title: "Contratos",
                        type: "basic",
                        link: "/resources/marketplace/contracts",
                    },
                ],
            } : {},
            (tenantType==PlatformType.CLUBMED) ? {
                id: "sst",
                title: "Gestão SST",
                type: "collapsable",
                icon: faHospital,
                children: [
                    {
                        id: "sst.list",
                        title: "Empresas",
                        type: "basic",
                        link: "/resources/marketplace/contracts/clients",
                    }
                ],
            } : {},
        ];
    }

    static covenantManagerMenu() {
        return [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "basic",
                link: "/dashboard",
                icon: faChartLine,
            },
            {
                id: "calendar",
                title: "Agenda",
                type: "basic",
                link: "/calendar",
                icon: faCalendar
            },
            {
                id: "myAccount",
                title: "Minha Conta",
                type: "basic",
                link: "/my-account",
                icon: faUser,
            },
            {
                id: "myPatients",
                title: "Pacientes",
                type: "basic",
                link: "/resources/users/patients",
                icon: faPeopleGroup,
            },
            {
                id: "settings",
                title: "Minha Equipe",
                type: "collapsable",
                icon: faPeopleGroup,
                children: [
                    {
                        id: "users.manager",
                        title: "Gestores",
                        type: "basic",
                        link: "/resources/users/managers",
                        resource: "Manager",
                    },
                    {
                        id: "users.professional",
                        title: "Profissionais",
                        type: "basic",
                        link: "/resources/users/professionals",
                        resource: "Professional",
                        // icon: faUserDoctor
                    },
                ],
            },
            {
                id: "audit",
                title: "Auditoria",
                type: "collapsable",
                active: true,
                icon: faPaste,
                children: [
                    {
                        id: "audit.painel",
                        title: "Painel",
                        type: "basic",
                        link: "/resources/painel",
                        // link: '/resources/records/audit',
                        // link: "/error/not-implemented",
                        icon: faChartLine,
                    },
                    {
                        id: "audit.queue",
                        title: "Fila de Espera",
                        type: "basic",
                        link: "/resources/audit/queue",
                        icon: faUserClock,
                    },
                    {
                        id: "audit.contracts",
                        title: "Contratos",
                        type: "basic",
                        link: "/resources/contracts",
                        resource: 'Contract',
                        icon: faFileSignature,
                    },
                    {
                        id: "audit.closure",
                        title: "Fechamento",
                        type: "basic",
                        link: '/resources/audit/closure',
                        icon: faHandHoldingMedical,
                    },
                ],
            },
            {
                id: "financial",
                title: "Financeiro",
                type: "basic",
                link: "/dashboardsdf",
                icon: faMoneyBill,
            }
        ];
    }

    static professionalMenu(tenantType=null) {
        return [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "basic",
                link: "/dashboard",
                icon: faChartLine,
            },
            {
                id: "myAccount",
                title: "Minha Conta",
                type: "basic",
                link: "/my-account",
                icon: faUser,
            },
            {
                id: "myPatients",
                title: "Meus Pacientes",
                type: "basic",
                link: "/resources/users/patients",
                icon: faPeopleGroup,
            },
            {
                id: "myServices",
                title: "Meus Atendimentos",
                type: "basic",
                link: "/resources/appointments",
                icon: faHospitalUser,
            },
            {
                id: "calendar",
                title: "Calendário",
                type: "basic",
                link: "/calendar",
                icon: faCalendar,
            },
            (tenantType==PlatformType.CLUBMED) ? {
                id: "financial",
                title: "Financeiro",
                type: "collapsable",
                link: "/dashboardsdf",
                icon: faMoneyBill,
                children: [
                    {
                        id: "financial.list",
                        title: "Conta Interna Clubmed",
                        type: "basic",
                        icon: faBoxesPacking,
                        link: "/financial/clubmed-internal-account",
                    },
                    {
                        id: "financial.list",
                        title: "Histórico",
                        type: "basic",
                        icon: faHistory,
                        link: "/financial/financial-history",
                    },
                    {
                        id: "banks",
                        title: "Contas Financeiras",
                        type: "basic",
                        icon: faBank,
                        link: "/resources/bank",
                    },
                ]
            } : {},
            {
                id: "patientQuestions",
                title: "Perguntas de Pacientes",
                type: "basic",
                link: "/patient-question",
                icon: faFileSignature
            },
            {
                id: "virtualOffice",
                title: "Consultório Virtual",
                type: "collapsable",
                icon: faHospitalUser,
                children: [
                    {
                        id: "prompt.service",
                        title: "Pronto Atendimento",
                        type: "basic",
                        link: "/resources/prompt-service",
                        icon: faHouseMedicalFlag,
                    },
                    {
                        id: "specialized.services",
                        title: "Atendimento Especializado",
                        type: "basic",
                        link: "/resources/specialized-appointments",
                        icon: faLaptopMedical,
                    },
                ]
            },
            {
                id: "settings",
                title: "Configurações",
                type: "collapsable",
                link: "/dashboardsdf",
                icon: faGears,
                children: [
                    {
                        id: "prescription",
                        title: "Medicamentos",
                        type: "basic",
                        link: "/resources/records/medicines",
                        icon: faPills,
                    }
                ]
            },
        ];
    }

    static patientMenu(tenantType=null) {
        return [
            /*{
                id: "dashboard",
                title: "Dashboard",
                type: "basic",
                link: "/dashboard",
                icon: faChartLine,
            },
            {
                id: "my-account",
                title: "Minha conta",
                type: "basic",
                link: "/resources/my-account",
                icon: faUser,
            },*/
            {
                id: "service-big-plant",
                title: "Pronto Atendimento",
                type: "basic",
                link: "/resources/prompt-service/waiting-room",
                icon: faHospitalUser,
            },
            {
                id: "service-big-plant",
                title: "Prescrições",
                type: "basic",
                link: "/resources/patient-prescription",
                resource: "Patient",
                icon: faFileMedical,
                // children: [
                //     {
                //         id: "patient",
                //         title: "Exames",
                //         type: "basic",
                //         link: "/resources/users/patients",
                //         resource: "Patient",
                //         icon: faFileWaveform,
                //     },
                //     {
                //         id: "users.patient",
                //         title: "Atestados",
                //         type: "basic",
                //         link: "/resources/records/tags",
                //         // resource: "Patient",
                //         icon: faFileSignature,
                //     },
                //     {
                //         id: "users.patient",
                //         title: "Orientações",
                //         type: "basic",
                //         link: "/resources/records/tags",
                //         // resource: "Patient",
                //         icon: faFileCircleExclamation,
                //     },
                // ],
            },
        ];
    }

    static adminMenu(tenantType=null) {
        return [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "basic",
                link: "/dashboard",
                icon: faChartLine,
            },
            {
                id: "patients",
                title: "Meus Pacientes",
                type: "collapsable",
                resource: "Patient",
                icon: faPeopleGroup,
                children: [
                    {
                        id: "users.patient",
                        title: "Pacientes",
                        type: "basic",
                        link: "/resources/users/patients",
                        resource: "Patient",
                        icon: faUser,
                    },
                    {
                        id: "users.patient.group",
                        title: "Grupos",
                        type: "basic",
                        link: "/resources/records/tags",
                        // resource: "Patient",
                        icon: faPeopleGroup,
                    },
                ],
            },
            {
                id: "users.health",
                title: "Prestadores de Serviço",
                type: "collapsable",
                active: true,
                icon: faHandshake,
                children: [
                    {
                        id: "records.covenant",
                        title: "Consórcios",
                        type: "basic",
                        link: "/resources/records/covenants",
                        resource: "Company",
                        icon: faBuilding,
                    },
                    {
                        id: "users.professional",
                        title: "Profissionais",
                        type: "basic",
                        link: "/resources/users/professionals",
                        resource: "Professional",
                        icon: faUserDoctor,
                    },
                    {
                        id: "users.health",
                        title: "Unidades de Saúde",
                        type: "basic",
                        link: "/resources/records/service-units",
                        resource: "ServiceUnit",
                        icon: faHouseMedicalFlag,
                    },
                    {
                        id: "users.health-type",
                        title: "Tipos de Unidade de Saúde",
                        type: "basic",
                        link: "/resources/records/service-unit-types",
                        resource: "ServiceUnit",
                        icon: faSquareH,
                    },
                ],
            },
            {
                id: "users.health",
                title: "Assistente Virtual",
                type: "collapsable",
                active: true,
                icon: faHeadset,
                children: [
                    {
                        id: "integrations.notification",
                        title: "Notificações",
                        type: "basic",
                        link: "/resources/integrations/notifications",
                        resource: "Notification",
                        icon: faBell,
                    },
                    {
                        id: "records.monitoring",
                        title: "Monitoramentos",
                        type: "basic",
                        link: "/resources/monitoring",
                        icon: faMagnifyingGlassPlus,
                    },
                    {
                        id: "records.setting",
                        title: "Configurações",
                        type: "basic",
                        link: "/resources/users/assistants",
                        icon: faGears,
                    },
                ],
            },
            {
                id: "medical-record",
                title: "Registros Médicos",
                type: "collapsable",
                icon: faClipboardUser,
                children: [
                    {
                        id: "medical-record.referral",
                        title: "Encaminhamentos",
                        type: "basic",
                        link: "/resources/records/referrals",
                        resource: "Referral",
                        icon: faBuildingCircleArrowRight,
                    },
                    {
                        id: "medical-record.guide",
                        title: "Guias",
                        type: "basic",
                        link: "/resources/records/guides",
                        resource: "Guide",
                        // link: "/error/not-implemented",
                        icon: faFileLines,
                    },
                ],
            },
            (tenantType==PlatformType.GOVERNMENT) ? {
                id: "emergency",
                title: "Pronto Atendimento",
                type: "collapsable",
                icon: faHospitalUser,
                children: [
                    {
                        id: "emergency.digital-emergency-care-unit",
                        title: "Fila de espera",
                        type: "basic",
                        link: "/resources/prompt-service",
                        icon: faLaptopMedical,
                        /*children: [
                            {
                                id: "digital-emergency-care-unit.waiting",
                                title: "Fila de Espera",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.scheduled",
                                title: "Agendados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.completed",
                                title: "Realizados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.uncompleted",
                                title: "Não Realizados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.service-units",
                                title: "Unidades de Saúde",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.professional",
                                title: "Profissionais",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                        ]*/
                    },
                    {
                        id: "emergency.physical-emergency-care-unit",
                        title: "Plantonistas",
                        type: "basic",
                        link: "/resources/prompt-service/professionals",
                        icon: faHouseMedicalFlag,
                        /*children: [
                            {
                                id: "physical-emergency-care-unit.waiting",
                                title: "Fila de Espera",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.scheduled",
                                title: "Agendados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.completed",
                                title: "Realizados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.uncompleted",
                                title: "Não Realizados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.service-units",
                                title: "Unidades de Saúde",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.professional",
                                title: "Profissionais",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                        ]*/
                    },
                    // {
                    //     id: "emergency.duty-scale",
                    //     title: "Escala Plantões",
                    //     type: "basic",
                    //     link: "/resources/duty-scale",
                    //     // link: "/error/not-implemented",
                    //     icon: faPeopleArrows
                    // },
                ],
            } : {
                id: "emergency",
                title: "Pronto Atendimento",
                type: "collapsable",
                icon: faHospitalUser,
                children: [
                    {
                        id: "emergency.digital-emergency-care-unit",
                        title: "Fila de espera",
                        type: "basic",
                        link: "/resources/prompt-service",
                        icon: faLaptopMedical,
                        /*children: [
                            {
                                id: "digital-emergency-care-unit.waiting",
                                title: "Fila de Espera",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.scheduled",
                                title: "Agendados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.completed",
                                title: "Realizados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.uncompleted",
                                title: "Não Realizados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.service-units",
                                title: "Unidades de Saúde",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "digital-emergency-care-unit.professional",
                                title: "Profissionais",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                        ]*/
                    },
                    {
                        id: "emergency.physical-emergency-care-unit",
                        title: "Plantonistas",
                        type: "basic",
                        link: "/resources/prompt-service/professionals",
                        icon: faHouseMedicalFlag,
                        /*children: [
                            {
                                id: "physical-emergency-care-unit.waiting",
                                title: "Fila de Espera",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.scheduled",
                                title: "Agendados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.completed",
                                title: "Realizados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.uncompleted",
                                title: "Não Realizados",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.service-units",
                                title: "Unidades de Saúde",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                            {
                                id: "physical-emergency-care-unit.professional",
                                title: "Profissionais",
                                type: "basic",
                                // link: "/error/not-implemented",
                            },
                        ]*/
                    },
                    // {
                    //     id: "emergency.duty-scale",
                    //     title: "Escala Plantões",
                    //     type: "basic",
                    //     link: "/resources/duty-scale",
                    //     // link: "/error/not-implemented",
                    //     icon: faPeopleArrows
                    // },
                ],
            },
            (tenantType==PlatformType.GOVERNMENT) ? {
                id: "emergency",
                title: "Atendimento Especializado",
                type: "collapsable",
                icon: faSuitcaseMedical,
                children: [
                    /**/
                    {
                        id: "emergency.digital-basic-health-units",
                        title: "Unidades Digitais de Saúde",
                        type: "basic",
                        link: "/resources/specialized-appointments",
                        // link: "/error/not-implemented",
                        icon: faLaptopMedical,

                        // link: "/error/not-implemented",
                    },
                    {
                        id: "emergency.physical-basic-health-units",
                        title: "Unidades Físicas de Saúde",
                        type: "basic",
                        link: "/resources/appointments",
                        // link: "/error/not-implemented",
                        // resource: "PhysicalBasicHealthUnit",
                        icon: faHouseMedicalFlag,
                    },
                    // {
                    //     id: "emergency.specialized-protocols",
                    //     title: "Protocolos Especializados",
                    //     type: "basic",
                    //     link: "/resources/specialized-protocols",
                    //     // link: "/error/not-implemented",
                    //     icon: faFolderClosed
                    // },
                ],
            } : {
                id: "emergency",
                title: "Atendimento Especializado",
                type: "collapsable",
                icon: faSuitcaseMedical,
                children: [
                    {
                        id: "emergency.physical-basic-health-units",
                        title: "Agendamentos",
                        type: "basic",
                        link: "/resources/specialized-appointments",
                        // link: "/error/not-implemented",
                        // resource: "PhysicalBasicHealthUnit",
                        icon: faHouseMedicalFlag,
                    },
                    /*{
                        id: "emergency.digital-basic-health-units",
                        title: "Realizados",
                        type: "basic",
                        link: "/resources/specialized-appointments",
                        // link: "/error/not-implemented",
                        icon: faLaptopMedical,

                        // link: "/error/not-implemented",
                    },*/
                    // {
                    //     id: "emergency.specialized-protocols",
                    //     title: "Protocolos Especializados",
                    //     type: "basic",
                    //     link: "/resources/specialized-protocols",
                    //     // link: "/error/not-implemented",
                    //     icon: faFolderClosed
                    // },
                ],
            },

            {
                id: "protocol",
                title: "Protocolos",
                type: "collapsable",
                active: true,
                icon: faFileLines,
                children: [
                    {
                        id: "protocol.screening",
                        title: "Triagem",
                        type: "basic",
                        link: "/resources/upa/screening",
                        // link: "/error/not-implemented",
                        icon: faStethoscope,
                    },
                    {
                        id: "protocol.monitoring",
                        title: "Monitoramentos",
                        type: "basic",
                        // link: "/resources/monitoring",
                        // link: '/resources/records/monitoring',
                        link: "/resources/monitoring",
                        // resource: "Monitoring",
                        icon: faMagnifyingGlassPlus,
                    },
                    {
                        id: "protocol.specialized-service",
                        title: "Atendimento Especializado",
                        type: "basic",
                        // link: "/resources/attendances",
                        // link: '/resources/records/attendances',
                        link: "/resources/specialized-attendance",
                        icon: faHandHoldingMedical,
                    },
                ],
            },
            {
                id: "audit",
                title: "Auditoria",
                type: "collapsable",
                active: true,
                icon: faPaste,
                children: [
                    {
                        id: "audit.painel",
                        title: "Painel",
                        type: "basic",
                        link: "/resources/painel",
                        icon: faChartLine,
                    },
                    {
                        id: "audit.queue",
                        title: "Fila de Espera",
                        type: "basic",
                        link: "/resources/closure",
                        icon: faUserClock,
                    },
                    {
                        id: "audit.contracts",
                        title: "Contratos",
                        type: "basic",
                        link: "/resources/contracts",
                        resource: 'Contract',
                        icon: faFileSignature,
                    },
                    {
                        id: "audit.closure",
                        title: "Fechamento",
                        type: "basic",
                        link: "/resources/audit/closure",
                        icon: faHandHoldingMedical,
                    },
                ],
            },
            (tenantType==PlatformType.CLUBMED) ? {
                id: "company",
                title: "Empresas",
                type: "collapsable",
                resource: "Company",
                icon: faBuilding,
                children: [
                    {
                        id: "company.list",
                        title: "Empresas",
                        type: "basic",
                        link: "/resources/companies/records",
                        icon: faBuildingColumns,
                    },
                    {
                        id: "company.finances",
                        title: "Faturas",
                        type: "basic",
                        link: "/resources/companies/finances",
                        icon: faFileText,
                    },
                    {
                        id: "company.finances",
                        title: "Desligados",
                        type: "basic",
                        link: "/resources/companies/disabled",
                        icon: faFire,
                    },
                ],
            } : {},
            (tenantType==PlatformType.CLUBMED) ? {
                id: "finance",
                title: "Financeiro",
                type: "collapsable",
                icon: faMoneyBillWave,
                children: [
                    {
                        id: "users.manager",
                        title: "Solicitação Resgate",
                        type: "basic",
                        icon: faMoneyCheck,
                        link: "/financial/financial-history/approve",
                        resource: "Manager",
                    },
                    // {
                    //     id: "setting.profile",
                    //     title: "Perfis e Permissões",
                    //     type: "basic",
                    //     link: "/resources/settings/profiles",
                    //     resource: "Profile",
                    // },
                ],
            } : {},
            {
                id: "settings",
                title: "Configurações",
                type: "collapsable",
                icon: faGears,
                children: [
                    {
                        id: "settings",
                        title: "Minha Equipe",
                        type: "collapsable",
                        icon: faPeopleGroup,
                        children: [
                            {
                                id: "users.manager",
                                title: "Gestores",
                                type: "basic",
                                link: "/resources/users/managers",
                                resource: "Manager",
                            },
                            {
                                id: "setting.profile",
                                title: "Perfis e Permissões",
                                type: "basic",
                                link: "/resources/settings/profiles",
                                resource: "Profile",
                            },
                        ],
                    },
                ],
            },
            {
                id: "records",
                title: "Cadastros",
                type: "collapsable",
                active: true,
                icon: faFileSignature,
                children: [
                    {
                        id: "records.specialty",
                        title: "Especialidades",
                        type: "basic",
                        link: "/resources/records/specialties",
                        resource: "Specialty",
                        icon: faFileWaveform,
                    },
                    {
                        id: "records.specialty-item",
                        title: "Itens Especialidade",
                        type: "basic",
                        link: "/resources/records/specialty-items",
                        resource: "SpecialtyItem",
                        icon: faFileWaveform,
                    },
                    {
                        id: "records.procedure.menu",
                        title: "Procedimentos",
                        type: "collapsable",
                        resource: "Procedure",
                        icon: faSyringe,
                        children: [
                            {
                                id: "records.procedure.menu.procedure",
                                title: "Procedimentos",
                                type: "basic",
                                link: "/resources/records/procedures",
                                resource: "Procedure",
                            },
                            {
                                id: "records.procedure.menu.group",
                                title: "Grupos",
                                type: "basic",
                                link: "/resources/records/procedure-groups",
                                resource: "Procedure",
                            },
                            {
                                id: "records.procedure.menu.subGroup",
                                title: "Sub-grupos",
                                type: "basic",
                                link: "/resources/records/procedure-sub-groups",
                                resource: "Procedure",
                            },
                            {
                                id: "records.procedure.menu.classification",
                                title: "Formas de Organização",
                                type: "basic",
                                link: "/resources/records/procedure-classifications",
                                resource: "Procedure",
                            },
                            /*{
                                id: 'records.procedure.menu.price',
                                title: 'Preços',
                                type: 'basic',
                                link: '/resources/records/procedures/price',
                                resource: 'Procedure'
                            },*/
                        ],
                    },
                    {
                        id: "records.medicine",
                        title: "Medicamentos",
                        type: "basic",
                        link: "/resources/records/medicines",
                        resource: "Medicine",
                        icon: faPills,
                    },
                    // {
                    //     id: "records.tags",
                    //     title: "Tags",
                    //     type: "basic",
                    //     link: "/resources/records/tags",
                    //     resource: "Tag",
                    //     icon: faTags,
                    // },
                    {
                        id: "records.activePrinciple",
                        title: "Princípios Ativos",
                        type: "basic",
                        link: "/resources/records/active-principles",
                        resource: "ActivePrinciples",
                        icon: faVialVirus,
                    },
                    {
                        id: "records.professionalTitle",
                        title: "Título Profissional",
                        type: "basic",
                        link: "/resources/records/professional-titles",
                        resource: "ProfessionalTitle",
                        icon: faUserNurse,
                    },
                    {
                        id: "records.cids",
                        title: "CIDs",
                        type: "basic",
                        link: "/resources/records/cids",
                        resource: "Cid",
                        icon: faDisease,
                    },
                ],
            },
            (tenantType==PlatformType.GOVERNMENT) ?{
                id: "integrations",
                title: "Integrações",
                type: "collapsable",
                icon: faShareNodes,
                children: [
                    {
                        id: "integrations.saude-api",
                        title: "Prefeitura",
                        type: "basic",
                        link: "/resources/integrations/saude-api",
                        resource: "Notification",
                        icon: faLandmarkFlag,
                    },
                    {
                        id: "integrations.health-insurances",
                        title: "Consórcios",
                        type: "basic",
                        link: "/resources/health-insurances",
                        icon: faBuilding,
                    },
                ],
            } : {},
        ];
    }

    static serviceUnitManagerMenu(tenantType=null) {
        return [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "basic",
                link: "/dashboard",
                icon: faChartLine,
            },
            {
                id: "calendar",
                title: "Agenda",
                type: "basic",
                link: "/calendar",
                icon: faCalendar
            },
            {
                id: "myAccount",
                title: "Minha Conta",
                type: "basic",
                link: "/my-account",
                icon: faUser,
            },
            {
                id: "myPatients",
                title: "Pacientes",
                type: "basic",
                link: "/resources/users/patients",
                icon: faPeopleGroup,
            },
            {
                id: "myServices",
                title: "Meus Atendimentos",
                type: "basic",
                link: "/resources/appointments",
                icon: faHospitalUser,
            },
            {
                id: "records.procedure.menu.procedure",
                title: "Procedimentos",
                type: "basic",
                link: "/resources/records/procedures",
                resource: "Procedure",
                icon: faSyringe,
            },
            {
                id: "settings",
                title: "Minha Equipe",
                type: "collapsable",
                icon: faPeopleGroup,
                children: [
                    {
                        id: "users.manager",
                        title: "Gestores",
                        type: "basic",
                        link: "/resources/users/managers",
                        resource: "Manager",
                    },
                    {
                        id: "users.professional",
                        title: "Profissionais",
                        type: "basic",
                        link: "/resources/users/professionals",
                        resource: "Professional",
                    },
                ],
            },
        ];
    }

    static companyManagerMenu() {
        return [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "basic",
                link: "/resources/companies/records/dashboard",
                icon: faChartLine,
            },
            {
                id: "patients",
                title: "Empresa",
                type: "collapsable",
                resource: "Patient",
                icon: faBuilding,
                children: [
                    {
                        id: "company-account",
                        title: "Minha conta",
                        type: "basic",
                        link: "/resources/companies/records",
                        icon: faBuildingUser,
                    },
                    {
                        id: "patients-collaborators",
                        title: "Colaboradores",
                        type: "basic",
                        link: "/resources/companies/collaborators",
                        resource: "Patient",
                        icon: faPeopleGroup,
                    },

                ],
            },
            {
                id: "sst-company",
                title: "Gestão SST",
                type: "collapsable",
                resource: "Patient",
                icon: faFileMedical,
                children: [
                    {
                        id: "company",
                        title: "Exames Ocupacionais",
                        type: "basic",
                        link: "/resources/companies/collaborators-exams",
                        icon: faBuildingUser,
                    },
                    /*{
                        id: "users.patient",
                        title: "Medicina do Trabalho",
                        type: "basic",
                        link: "/gestao/medicina_do_trabalho",
                        icon: faPeopleGroup,
                    },
                    {
                        id: "company",
                        title: "Segurança do Trabalho",
                        type: "basic",
                        link: "/gestao/seguranca_do_trabalho",
                        icon: faBuildingUser,
                    },
                    {
                        id: "company",
                        title: "Treinamentos",
                        type: "basic",
                        link: "/gestao/treinamentos",
                        icon: faBuildingUser,
                    },*/
                ],
            },
            {
                id: "financial",
                title: "Financeiro",
                type: "collapsable",
                link: "/dashboardsdf",
                icon: faMoneyBill,
                children: [
                    {
                        id: "financial.company",
                        title: "Fatura",
                        type: "basic",
                        link: "/resources/companies/finances",
                        icon: faMoneyBills,
                    },
                    {
                        id: "financial.expenses",
                        title: "Relatório de desconto em folha",
                        type: "basic",
                        link: "/resources/companies/collaborators/company-expense",
                        icon: faMoneyCheck,
                    },
                    {
                        id: "financial.list",
                        title: "Histórico de movimentações",
                        type: "basic",
                        link: "/financial/financial-history",
                        icon: faChartArea,
                    },
                ],
            },
            {
                id: "marketplace",
                title: "Marketplace",
                type: "collapsable",
                // link: "/dashboardsdf",
                icon: faShop,
                children: [
                    {
                        id: "company.marketplace",
                        title: "Loja Virtual",
                        type: "basic",
                        link: "/resources/marketplace/products",
                        icon: faCartShopping,
                    },
                    {
                        id: "marketplace.contracts",
                        title: "Contratos",
                        type: "basic",
                        link: "/resources/marketplace/contracts",
                        icon: faFileContract,
                    },
                    {
                        id: "company.plans",
                        title: "Comprar Planos",
                        type: "basic",
                        link: "/resources/marketplace/plans",
                        icon: faShoppingBasket,
                    },
                ],
            },
            {
                id: "settings",
                title: "Configurações",
                type: "collapsable",
                icon: faGears,
                children: [
                    {
                        id: "settings",
                        title: "Minha Equipe",
                        type: "collapsable",
                        icon: faUsersGear,
                        children: [
                            {
                                id: "users.manager",
                                title: "Gestores",
                                type: "basic",
                                link: "/resources/users/managers",
                                resource: "Manager",
                            },
                        ],
                    },
                    {
                        id: "job",
                        title: "Cadastros",
                        type: "collapsable",
                        icon: faFileText,
                        children: [
                            {
                                id: "companies.division",
                                title: "Setores",
                                type: "basic",
                                link: "/resources/companies/company-divisions",
                                icon: faUsersBetweenLines,
                            },
                            {
                                id: "companies.jobTitle",
                                title: "Cargos",
                                type: "basic",
                                link: "/resources/companies/job-titles",
                                icon: faIdCardClip,
                            },
                        ],
                    },
                ],
            },
        ];
    }
}
