import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    private _dataUpdated = new Subject<void>();
    private _videoCall = new Subject<void>();
    
    dataUpdated$ = this._dataUpdated.asObservable();
    videoCall$: Observable<any> = this._videoCall.asObservable();

    triggerDataUpdate(): void {
        this._dataUpdated.next();
    }

    videoCall(data: any) {
        console.info("VIDEO-CALL-DATA-INFO");
        console.info(data);
        this._videoCall.next(data);
    }

    clearVideoCall() {
        this._videoCall.next(null);
    }
}
