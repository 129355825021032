import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { CurrencyPipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { FuseCardComponent } from "../../../../../../@fuse/components/card";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Patient } from "../../model/patient";
import { PatientService } from "../../service/patient.service";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { DateAdapter } from "@angular/material/core";
import { MatTabsModule } from "@angular/material/tabs";
import { ModalComponent } from "../../../../commons/components/modal/modal.component";
import Swal from "sweetalert2";
import { ProcedureContract } from '../../../../contract/procedure-contract/model/procedure-contract';
import { Router } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
    selector: "app-collaborator-link",
    templateUrl: "./collaborator-link.component.html",
    styleUrls: ["./collaborator-link.component.scss"],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass,
        MatButtonModule,
        NgIf,
        MatIconModule,
        NgForOf,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        FuseCardComponent,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatTabsModule,
        ModalComponent,
        CurrencyPipe,
        NgxMaskDirective
    ],
})
export class CollaboratorLinkComponent implements OnInit {
    patient: Patient;
    corporateCredit: number;
    formGroup: FormGroup;
    result: string;
    email: string;

    constructor(
        public service: PatientService,
        private router: Router,
        private _formBuilder: FormBuilder,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dateAdapter: DateAdapter<Date>
    ) {
        this.dateAdapter.setLocale("pt-BR");
    }

    ngOnInit(): void {
        this.initializeForms();

        this.formGroup.get('documentNumber').valueChanges.subscribe(value => {

            if (value.length > 10) {
                this.service.searchByCpf(value).subscribe(data => {
                    this.email = data.email;
                    this.formGroup.get('email').setValue(data.email);

                    this.link(data.status, data.msg, data.email, data.create);

                    if (data && data.email) {
                    }
                });
            }
        });
    }

    changeEvent(event: any) {
        this.service.searchByCpf(event).subscribe((data) => {
            this.result = data;
        });
    }

    initializeForms() {
        this.formGroup = this._formBuilder.group({
            email: ["", Validators.required],
            documentNumber: ["", Validators.required],
        });
    }

    link(status: boolean, message: string, email: string, create: boolean) {
        if (status) {
            Swal.fire({
                title: `<div class="text-lg"><span style="color: gray;">${ message }</span></div>`,
                showCancelButton: true,
                confirmButtonText: "Vincular",
                cancelButtonText: "Cancelar",
                customClass: {
                    confirmButton: 'inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in create-button border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85',
                    cancelButton: 'inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem bg-slate-400 shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85',

                },
                icon: 'question'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.linkCollaborator();
                }
            });
        } else {
            if(create){
                Swal.fire({
                    title: `<div class="text-lg"><span style="color: gray;">${ message }</span></div>`,
                    showCancelButton: true,
                    confirmButtonText: "Cadastrar",
                    cancelButtonText: "Cancelar",
                    customClass: {
                        confirmButton: 'inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in create-button border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85',
                        cancelButton: 'inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem bg-slate-400 shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85',

                    },
                    icon: 'question'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.router.navigate(['/resources/companies/collaborators/create']);
                        this.closeDialog();
                    }
                });
            }else{
                Swal.fire({
                    title: `<div class="text-lg"><span style="color: gray;">${ message }</span></div>`,
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: "Ok",
                    customClass: {
                        cancelButton: 'inline-block px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem bg-slate-400 shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85',

                    },
                    icon: 'error'
                })
                this.email = '';
                this.formGroup.reset();
            }
        }
    }


    linkCollaborator() {
        if (this.formGroup.valid) {
            const entity = this.formGroup.getRawValue();
            this.service.linkCollaborator(entity).subscribe((data) => this.handleSuccess(),
                (error) => this.handleError(error)
            );
            this.formGroup.reset();
        } else {
            Swal.fire("Erro", "O campo de e-mail e CPF não devem estar vazios.", "error");
        }
    }

    private handleSuccess() {
        Swal.fire("Sucesso", "Colaborador vinculado com sucesso.", "success");
        this.formGroup.reset();
        this.closeDialog();
    }

    private handleError(response) {
        if (response.error && typeof response.error.errors === "object") {
            const messages = [];
            const errorObject = response.error.errors;
            for (const errorKey of Object.keys(errorObject)) {
                messages.push(errorObject[errorKey]);
            }

            Swal.fire("Erro", messages.join("<br>"), "error");
        } else {
            Swal.fire("Erro", "Ocorreu um erro ao vincular o colaborador.", "error");
        }
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.slug || index;
    }
}
