import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { DefaultLayoutComponent } from "./layout/layouts/default/default.component";
import { ScreeningComponent } from "./modules/construction/screening/screening.component";
import { ChatComponent } from "./modules/construction/chat/chat.component";
import { SpecializedProtocolsComponent } from "./modules/construction/specializedProtocols/specialized-protocols.component";
import { QueueComponent } from "./modules/construction/queue/queue.component";
import { AttendancesComponent } from "./modules/construction/attendances/attendances.component";
import { HealthInsurancesComponent } from "./modules/construction/health-insurances/health-insurances.component";
import { DutyScaleComponent } from "./modules/construction/dutyScale/duty-scale.component";
import { UBSComponent } from "./modules/construction/ubs/ubs.component";
import { MonitoringComponent } from "./modules/construction/monitoring/monitoring.component";
import { AssistentComponent } from "./modules/user/assistent/components/view/assistent.component";
import { ClosureComponent } from "./modules/construction/closure/closure.component";
import {
    SpecializedAttendanceComponent
} from "./modules/construction/specialized-attendance/specialized-attendance.component";
import { PromptServiceComponent } from './modules/prompt-service/components/index/prompt-service.component';
import { InternalAccountComponent } from './modules/financial/financial-histories/components/clubmed-internal-account/internal-account.component';
import { BankPersistComponent } from './modules/bank/components/persist/bank-persist.component';
import { DisabledListComponent } from './modules/company/components/disabled-list/disabled-list.component';
import { UserPatientComponent } from './modules/user/user-patient/user-patient.component';
import { UserPatientListComponent } from './modules/user/user-patient-list/user-patient-list.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/specialty'
    { path: "", pathMatch: "full", redirectTo: "dashboard" },

    // Auth routes for guests
    {
        path: "",
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty",
        },
        children: [
            {
                path: "confirmation-required",
                loadChildren: () => import("app/modules/auth/confirmation-required/confirmation-required.routes"),
            },
            {
                path: "forgot-password",
                loadChildren: () => import("app/modules/auth/forgot-password/forgot-password.routes"),
            },
            {
                path: "reset-password",
                loadChildren: () => import("app/modules/auth/reset-password/reset-password.routes"),
            },
            { path: "sign-in", loadChildren: () => import("app/modules/auth/sign-in/sign-in.routes") },
            {
                path: "pre-cadastro-unidade-de-servico",
                children: [
                    {
                        path: "",
                        loadChildren: () => import("app/modules/service-unit/components/self-registration/routes/unitRegistration.routes"),
                    },
                ],
            },
            {
                path: "sign-up",
                children: [
                    {
                        path: "service-unit",
                        loadChildren: () => import("app/modules/service-unit/components/self-registration/routes/unitRegistration.routes"),
                    },
                    {
                        path: 'patient-registration',
                        loadChildren: () => import('app/modules/patient/components/self-registration/routes/patientRegistration.routes'),
                    },
                    {
                        path: "company",
                        loadChildren: () => import("app/modules/company/components/self-registration/routes/company-registration.routes"),
                    },
                ],
            },
            { path: "anamnese",loadChildren: () => import("app/modules/anamnese/anamnese.routes")},
            {
                path: "teleconsultation",
                loadChildren: () => import("app/modules/prompt-service/routes/prompt-service.routes")
            },

        ],
    },

    // Auth routes for authenticated users
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty",
        },
        children: [
            { path: "sign-out", loadChildren: () => import("app/modules/auth/sign-out/sign-out.routes") },
            {
                path: "unlock-session",
                loadChildren: () => import("app/modules/auth/unlock-session/unlock-session.routes"),
            },
        ],
    },

    {
        path: "error",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty",
        },
        children: [
            { path: "forbidden", loadChildren: () => import("app/layout/common/errors/403/error-403.routes") },
            { path: "not-implemented", loadChildren: () => import("app/layout/common/errors/501/error-501.routes") },
        ],
    },
    {
        path: "",
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: "dashboard",
                loadChildren: () => import("app/modules/home/routes/home.routes"),
            },
            {
                path: "my-account",
                loadChildren: () => import("app/modules/my-account/routes/my-account.routes"),
            },
            {
                path: "calendar",
                loadChildren: () => import("app/modules/calendar/routes/calendar.routes"),
            },
            {
                path: "patient-question",
                loadChildren: () => import("app/modules/questions/routes/question.routes"),
            },
            {
                path: "teleconsultation",
                loadChildren: () => import("app/modules/prompt-service/routes/prompt-service.routes"),
            }
        ],
    },

    // Landing routes
    {
        path: "",
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
              path: "gestao",
              children: [
                { path: "medicina_do_trabalho", loadChildren: ()=> import("app/modules/management/medicine-at-work/routes/active-principle.routes")  },
              ]
            },
            {
                path: "financial",
                children: [
                    { path: "financial-history", loadChildren: ()=> import("app/modules/financial/financial-histories/routes/financial-history.routes")  },
                    { path: "clubmed-internal-account", component: InternalAccountComponent },
                    { path: "account-create", component: BankPersistComponent },
                ]
            },
            {
                path: "resources",
                children: [
                    { path: "chat", component: ChatComponent },
                    { path: "duty-scale", component: DutyScaleComponent },
                    { path: "specialized-protocols", component: SpecializedProtocolsComponent },
                    { path: "specialized-attendance", component: SpecializedAttendanceComponent },
                    { path: "patient-prescription", component: UserPatientComponent },
                    { path: "patient-list/:slug", component: UserPatientListComponent },
                    { path: "prompt-service", loadChildren: () => import("app/modules/prompt-service/routes/prompt-service.routes") },
                    { path: "audit",
                        children: [
                            {
                                path: "queue",
                                component: QueueComponent,
                            },
                            {
                                path: "closure",
                                loadChildren: () => import("app/modules/appointment/routes/appointment.routes"),
                            },
                        ],
                    },
                    { path: "contracts", loadChildren: () => import("app/modules/contract/routes/contract.routes") },
                    { path: "painel", loadChildren: () => import("app/modules/painel/routes/painel.routes") },
                    { path: "attendances", component: AttendancesComponent },
                    {
                        path: "appointments",
                        loadChildren: () => import("app/modules/appointment/routes/appointment.routes"),
                    },
                    {
                        path: "specialized-appointments",
                        loadChildren: () =>
                            import("app/modules/specialized-appointment/routes/specialized-appointment.routes"),
                    },
                    { path: "health-insurances", component: HealthInsurancesComponent },
                    { path: "monitoring", component: MonitoringComponent },
                    { path: "closure", component: ClosureComponent },

                    {
                        path: "upa",
                        children: [{ path: "screening", component: ScreeningComponent }],
                    },

                    {
                        path: "ubs",
                        children: [
                            {
                                path: "digital",
                                component: UBSComponent,
                            },
                            { path: "physical", component: UBSComponent },
                        ],
                    },
                    {
                        path: "records",
                        children: [
                            {
                                path: "covenants",
                                loadChildren: () => import("app/modules/covenant/routes/covenant.routes"),
                            },
                            {
                                path: "specialties",
                                loadChildren: () => import("app/modules/specialty/routes/specialty.routes"),
                            },
                            {
                                path: "specialty-items",
                                loadChildren: () => import("app/modules/specialty-item/routes/specialty-item.routes"),
                            },
                            {
                                path: "procedures",
                                loadChildren: () => import("app/modules/procedure/routes/procedure.routes"),
                            },
                            {
                                path: "procedure-groups",
                                loadChildren: () =>
                                    import("app/modules/procedure/procedure-group/routes/procedure-group.routes"),
                            },
                            {
                                path: "procedure-sub-groups",
                                loadChildren: () =>
                                    import(
                                        "app/modules/procedure/procedure-sub-group/routes/procedure-sub-group.routes"
                                    ),
                            },
                            {
                                path: "procedure-classifications",
                                loadChildren: () =>
                                    import(
                                        "app/modules/procedure/procedure-classification/routes/procedure-classification.routes"
                                    ),
                            },
                            {
                                path: "medicines",
                                loadChildren: () => import("app/modules/medicine/routes/medicine.routes"),
                            },
                            {
                                path: "active-principles",
                                loadChildren: () =>
                                    import("app/modules/active-principle/routes/active-principle.routes"),
                            },
                            {
                                path: "schedules",
                                loadChildren: () => import("app/modules/schedule/routes/schedule.routes"),
                            },
                            {
                                path: "professional-titles",
                                loadChildren: () =>
                                    import("app/modules/professional-title/routes/professional-title.routes"),
                            },
                            {
                                path: "service-units",
                                loadChildren: () => import("app/modules/service-unit/routes/service-unit.routes"),
                            },
                            {
                                path: "service-unit-types",
                                loadChildren: () =>
                                    import("app/modules/service-unit-type/routes/service-unit-type.routes"),
                            },
                            { path: "cids", loadChildren: () => import("app/modules/cid/routes/cid.routes") },
                            { path: "tags", loadChildren: () => import("app/modules/tag/routes/tag.routes") },
                            {
                                path: "referrals",
                                loadChildren: () => import("app/modules/referral/routes/referral.route"),
                            },
                            {
                                path: "guides",
                                loadChildren: () => import("app/modules/saude-api/routes/saude-api.routes"),
                            },
                        ],
                    },
                    {
                        path: "medical-records",
                        children: [
                            {
                                path: "",
                                loadChildren: () => import("app/modules/medical-record/routes/medical-record.routes"),
                            },
                        ],
                    },
                    {
                        path: "marketplace",
                        children: [
                            {
                                path: "products",
                                loadChildren: () => import("app/modules/marketplace/marketplace-product/routes/marketplace-product.routes"),
                            }
                        ]
                    },
                    {
                        path: "marketplace",
                        children: [
                            {
                                path: "plans",
                                loadChildren: () => import("app/modules/marketplace/marketplace-plans/routes/marketplace-plans.routes"),
                            }
                        ]
                    },
                    {
                        path: "marketplace",
                        children: [
                            {
                                path: "contracts",
                                loadChildren: () => import("app/modules/marketplace/marketplace-contract/routes/marketplace-contract.routes"),
                            }
                        ]
                    },
                    {
                        path: "companies",
                        children: [
                            {
                                path: "records",
                                loadChildren: () => import("app/modules/company/routes/company.routes"),
                            },
                            {
                                path: "company-divisions",
                                loadChildren: () =>
                                    import("app/modules/company/company-division/routes/company-division.routes"),
                            },
                            {
                                path: "company-departments",
                                loadChildren: () =>
                                    import("app/modules/company/company-department/routes/company-department.routes"),
                            },
                            {
                                path: "collaborators",
                                loadChildren: () => import("app/modules/company/patient/routes/patient.routes"),
                            },
                            {
                                path: "job-titles",
                                loadChildren: () => import("app/modules/company/job-title/routes/job-title.routes"),
                            },
                            {
                                path: "finances",
                                loadChildren: () => import("app/modules/company/finance/routes/finance-routes"),
                            },
                            {
                                path: "collaborators-exams",
                                loadChildren: () => import("app/modules/company/collaborators-exams/routes/collaborators-exams.routes"),
                            },
                            {
                                path: "disabled", component: DisabledListComponent
                            }
                        ],
                    },
                    {
                        path: "users",
                        children: [
                            {
                                path: "managers",
                                loadChildren: () => import("app/modules/user/manager/routes/manager.routes"),
                            },
                            {
                                path: "secretaries",
                                loadChildren: () => import("app/modules/user/secretary/routes/secretary.routes"),
                            },
                            {
                                path: "professionals",
                                loadChildren: () => import("app/modules/user/professional/routes/professional.routes"),
                            },
                            {
                                path: "patients",
                                loadChildren: () => import("app/modules/user/patient/routes/patient.routes"),
                            },
                            {
                                path: "assistants",
                                component: AssistentComponent,
                            },
                        ],
                    },
                    {
                        path: "bank",
                        children: [
                            {
                                path: "",
                                loadChildren: () => import("app/modules/bank/routes/bank.routes"),
                            },
                        ],
                    },
                    {
                        path: "settings",
                        children: [
                            {
                                path: "profiles",
                                loadChildren: () => import("app/modules/profile/routes/profile.routes"),
                            },
                        ],
                    },
                    {
                        path: "integrations",
                        children: [
                            {
                                path: "notifications",
                                loadChildren: () => import("app/modules/notification/routes/notification.routes"),
                            },
                            {
                                path: "saude-api",
                                loadChildren: () => import("app/modules/saude-api/routes/saude-api.routes"),
                            },
                        ],
                    },
                    {
                        path: "my-account",
                        loadChildren: () => import("app/modules/my-account/routes/my-account.routes"),
                    },
                ],
            },
        ],
    },
];
