<div class="flex flex-col flex-auto min-w-0">
    <!-- Header -->
    <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <!-- Title -->
            <div class="flex justify-between items-center">
                <div class="mt-2 overflow-hidden break-words">
                    <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10">
                        {{ title }}
                    </h2>
                </div>
                <div class="flex">
                    <button class="return-button ml-4" mat-dialog-close mat-flat-button>
                        <mat-icon [svgIcon]="icon" class="close-view"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Main -->
    <ng-content select="[modalBody]"></ng-content>
</div>
