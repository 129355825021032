import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AbstractService } from '../../../core/commons/services/abstract.service';
import { Bank } from '../model/bank';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class BankService extends AbstractService<Bank> {

    _bank: BehaviorSubject<Bank | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'bank-accounts', Bank)
    }

    private _banks: BehaviorSubject<any> = new BehaviorSubject(null);

    // Private

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get banks(): Observable<any> {
        return this._banks.asObservable();
    }

    /**
     * Getter for item
     */
    get data$(): Observable<Bank> {
        return this._bank.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Calendars
     */
    getCalendars(): Observable<any> {
        return this._httpClient.get<Bank[]>('/').pipe(
            tap((response) => {
                this._banks.next(response);
            }),
        );
    }

    bank(): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/bank?limit=500`);
    }

    GetAccount(unitSlug: any): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/bank-accounts/${unitSlug}`);
    }
    editAccount(unitSlug: any, uuid: string): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/bank-accounts/${unitSlug}/${uuid}`);
    }


    storeBank(request: any, unitSlug: string): Observable<any> {
        console.log(request);
        return this._httpClient.post<any>(`${ environment.serverUrl }/bank-accounts/${unitSlug}`, request);
    }

    updateBank(request: any, uuid: string): Observable<any> {
        return this._httpClient.put<any>(`${ environment.serverUrl }/bank-accounts/${uuid}`, request);
    }

    deleteBank(uuid: string): Observable<any> {
        return this._httpClient.delete<any>(`${ environment.serverUrl }/bank-accounts/${uuid}`);
    }

    storeBankingInstitution(request: any): Observable<any> {
        return this._httpClient.post<any>(`${ environment.serverUrl }/bank`, request);
    }
    updateBankingInstitution( uuid: string, request: any): Observable<any> {
        return this._httpClient.put<any>(`${ environment.serverUrl }/bank/${uuid}`, request);
    }

}
