<div class="relative flex  flex-col text-gray-700 bg-gray-200 shadow-md rounded-xl">
    <div class="px-3">
        <div class="flex items-center  mt-3">
            <div class="rounded-full w-[45px] h-[45px] text-base font-medium leading-relaxed text-blue-gray-900 mt-2 mr-3">
                <img *ngIf="prescription?.professionalProfilePicture;" [src]="prescription?.professionalProfilePicture"
                     class="w-[45px] h-[45px] border border-cyan-600 object-cover rounded-full"/>
                <div *ngIf="!prescription?.professionalProfilePicture">
                    <mat-icon [svgIcon]="'heroicons_solid:user-circle'"
                              class="w-full h-full rounded-full default-avatar bg-neutral-200 color-gray"></mat-icon>
                </div>
            </div>

            <div class="flex flex-col">
                <div class="text-md font-bold leading-relaxed text-blue-gray-900">
                    {{ prescription?.clinicName | uppercase }}
                </div>
                <p class="text-sm font-semibold leading-relaxed text-blue-gray-900">
                    {{ prescription?.professionalSpecialty | titlecase }}
                    - {{ prescription?.professionalName | titlecase }}
                    - {{ prescription?.professionalCouncilNumber }}
                </p>
                <p class="text-sm font-semibold leading-relaxed text-blue-gray-900">
                    Paciente - {{ prescription?.patientName | titlecase }}
                </p>
            </div>
        </div>
        <div class="flex ml-14 items-center mb-3">
            <p class="text-sm font-medium leading-relaxed text-gray-500">
                Realizado em: {{ prescription?.date | date: 'dd/MM/yyyy' }} ás {{ prescription?.hour }}
            </p>
        </div>
    </div>
    <div class="w-full border-t border-gray-400 mb-3"></div>
    <app-prescription-card title="EXAMES" [count]="prescription?.numberReferrals" [slug]="prescription.slug"
                           [type]="prescriptionType[ZERO].type"></app-prescription-card>
    <app-prescription-card title="MEDICAMENTOS" [count]="prescription?.numberMedicines" [slug]="prescription.slug"
                           [type]="prescriptionType[ONE].type"></app-prescription-card>
    <app-prescription-card title="ATESTADO" [count]="prescription?.numberMedicalCertificates" [slug]="prescription.slug"
                           [type]="prescriptionType[TWO].type"></app-prescription-card>
    <app-prescription-card title="ORIENTAÇÕES" [count]="prescription?.numberOrientations" [slug]="prescription.slug"
                           [type]="prescriptionType[THREE].type"></app-prescription-card>
    <app-prescription-card title="RELATÓRIO DE EXAMES" [count]="prescription?.numberOrientations"
                           [slug]="prescription.slug" [type]="prescriptionType[FOUR].type"></app-prescription-card>
</div>
