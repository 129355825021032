import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
Validators
} from '@angular/forms';

import Swal from 'sweetalert2';
import { BankService } from '../../service/bank.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AsyncPipe, CommonModule, NgClass, NgFor, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { map, Observable, startWith } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { FuseCardComponent } from '../../../../../@fuse/components/card';
import { Location } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { BankComponent } from '../bank/bank.component';
import { FuseHighlightComponent } from '../../../../../@fuse/components/highlight';
import { MatCardModule } from '@angular/material/card';
import { InternalAccountComponent } from '../internal-account/internal-account.component';
import { NgxMaskDirective } from 'ngx-mask';

interface PixType {
    value: string;
}

interface AccountType {
    value: number;
    name: string;
}

@Component({
    selector: 'app-persist',
    templateUrl: './bank-persist.component.html',
    standalone: true,
    imports: [
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        NgClass,
        MatRadioModule,
        NgForOf,
        MatDialogModule,
        MatAutocompleteModule,
        AsyncPipe,
        RouterLink,
        RouterOutlet,
        FuseCardComponent,
        MatSelectModule,
        MatChipsModule,
        FuseHighlightComponent,
        MatCardModule,
        InternalAccountComponent,
        NgSwitch,
        NgSwitchCase,
        NgxMaskDirective

    ],
    styleUrls: ['./bank-persist.component.scss']
})
export class BankPersistComponent implements OnInit {
    myControl = new FormControl<string | any>('');
    data: any;
    filteredOptions: Observable<any>;
    bankForm: FormGroup;
    bankSlug: any;
    unit: string;
    action = 'Salvar';
    bankAccount: any;
    activeComponent = 1;
    box:any;
    isComponentEnabledOne: boolean;
    isComponentEnabledTwo: boolean;
    documentMask: string;
    documentLabel = 'CPF';

    pixType: PixType[] = [
        { value: 'CPF' },
        { value: 'E-mail' },
        { value: 'Telefone' },
        { value: 'Chave aleatória' }
    ];
    accountType: AccountType[] = [
        {value: 1, name: 'Conta corrente'},
        {value: 2, name: 'Conta poupança'},
        {value: 3, name: 'Conta salário'},
        {value: 4, name: 'Conta digital'},
        {value: 5, name: 'Conta pagamento'},
    ];
    selectedPixType = this.pixType[1].value;
    selectedAccountType = this.accountType[1].value;

    constructor(private bankService: BankService,
                private location: Location,
                private _matDialog: MatDialog,
                private route: ActivatedRoute,
    ) {
        this.bankForm = new FormGroup({
            holder: new FormControl('', [Validators.required]),
            document: new FormControl('', [Validators.required]),
            bankSlug: new FormControl(''),
            agency: new FormControl('', [Validators.required]),
            dvAgency: new FormControl(''),
            accountNumber: new FormControl('', [Validators.required]),
            dvAccounts: new FormControl(''),
            accountType: new FormControl('', [Validators.required]),
            gatewayCode: new FormControl(''),
            receivingCode: new FormControl(''),
            pixKey: new FormControl(''),
            pixKeyType: new FormControl(''),

        });
        this.documentMask = '000.000.000-00';
        this.setActiveComponent(2);
    }
    setActiveComponent(component: number) {
        this.activeComponent = component;
    }

    ngOnInit() {

        this.isComponentEnabledTwo = true;
        this.isComponentEnabledOne = true;
        this.unit = localStorage.getItem('userLocation');
        this.route.params.subscribe(params => {
            this.bankAccount = params['id'];
        });
        this.list();
        this.route.params.subscribe((params) => {
            if (params.id) {
                this.action = 'Editar';

                this.bankService.editAccount(this.unit, params.id).subscribe((response) => {

                    this.box = response.bankName;
                    if (response.bankName === 'Caixinha') {
                        this.activeComponent = 1;
                        this.isComponentEnabledOne = true;
                        this.isComponentEnabledTwo = false;
                    } else {
                        this.activeComponent = 2;
                        this.isComponentEnabledTwo = true;
                        this.isComponentEnabledOne = false;
                    }


                    this.bankForm.patchValue({
                        holder: response.holder,
                        document: response.document,
                        slug: response.bankSlug,
                        agency: response.agency,
                        dvAgency: response.dv_agency,
                        accountNumber: response.account_number,
                        dvAccounts: response.dv_accounts,
                        accountType: response.account_type,
                        gatewayCode: response.gateway_code,
                        receivingCode: response.receiving_code,
                        pixKey: response.pix_key,
                        pixKeyType: response.pix_key_type
                    });
                    this.myControl.setValue(response.bankSlug)
                });
            }
        });
    }

    toggleMask() {
        const documentValue = this.bankForm.get('document').value;
        if (documentValue.length < 11) {
            this.documentLabel = 'CPF';
            this.documentMask = '000.000.000-00'; // CPF mask
        } else {
            this.documentLabel = 'CNPJ';
            this.documentMask = '00.000.000/0000-00'; // CNPJ mask
        }
    }

    list(){
        this.bankService.bank().subscribe((response) => {
            this.data = response.data;
            //select
            this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map(value => {
                    this.bankSlug = value.slug;
                    console.log(  this.bankSlug);
                    const name = typeof value === 'string' ? value : value?.name;
                    return name ? this._filter(name as string) : this.data.slice();
                }),
            );
        });

    }


    goBack() {
        this.location.back();
    }


    displayFn(bank: any): string {
        return bank && bank.name ? bank.name : '';
    }

    private _filter(name: string): any {
        const filterValue = name.toLowerCase();

        return this.data.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    save() {
        if (this.action === 'Salvar') {
            this.bankForm.value.bankSlug = this.bankSlug;
            if (this.bankForm.valid) {

                this.bankService.storeBank(this.bankForm.value, this.unit).subscribe((response) => {
                    this.list();
                    Swal.fire('Cadastrar', 'Cadastro realizado com sucesso.', 'success');
                });
            } else {
                Swal.fire('Erro', 'Preencha os campos obrigatórios.', 'error');
            }
            this.location.back();
        } else {

            if (this.bankForm.valid) {
                this.bankService.updateBank(this.bankForm.value, this.bankAccount).subscribe((response) => {
                    Swal.fire('Editar', 'Cadastro editado com sucesso.', 'success');
                    console.log(response);
                });
            } else {
                Swal.fire('Erro', 'Preencha os campos obrigatórios.', 'error');
            }
        }

    }

    openNoteDialog(): void {
        this._matDialog.open(BankComponent, {
            autoFocus: false,
            width: '40%',

            data: {
                //note: obj,
            },
        }).beforeClosed()
            .subscribe(() => {
                this.list();
                //this.cdRef.detectChanges();
            });
    }

}
