<div class="flex-auto pt-0 text-center">
    <div class="mb-3 text-center text-slate-500">
        <div class="flex justify-center text-center">
            <small class="p-3 text-lg">Vincular / Cadastrar</small>
        </div>
    </div>
    <form class="flex flex-col  w-full" [formGroup]="formGroup">
        <div class="mb-4">
            <input type="text"
                   class="text-sm focus:shadow-primary-outline dark:bg-slate-850 placeholder:text-gray-500 dark:placeholder:text-white/80 dark:text-white/80 leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                   placeholder="CPF"
                   [formControlName]="'documentNumber'"
                   (input)="changeEvent($event.target)"
                   [mask]="'000.000.000-00'"
                   aria-label="CPF"
                   aria-describedby="cpf-addon">
        </div>
        <div *ngIf="email" class="mb-4">
            <input type="email"
                   class="text-sm focus:shadow-primary-outline dark:bg-slate-850 placeholder:text-gray-500 dark:placeholder:text-white/80 dark:text-white/80 leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                   placeholder="Email"
                   [formControlName]="'email'"
                   aria-label="Email"
                   aria-describedby="email-addon">
        </div>

        <div class="flex flex-wrap items-center justify-between   shrink-0 border-slate-100 rounded-b-xl">
            <button type="button" (click)="closeDialog()" data-toggle="modal" data-target="#import"
                    class="px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem bg-slate-400 shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85">
                Cancelar
            </button>
            <button type="button" (click)="linkCollaborator()" data-toggle="modal" data-target="#import"
                    class="px-8 py-2 m-1 mb-4 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in create-button border-0 rounded-lg cursor-pointer leading-pro tracking-tight-rem shadow-md bg-150 bg-x-25 hover:scale-102 active:opacity-85">
                Salvar
            </button>
        </div>
    </form>
</div>

