import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { TenantService } from '../../../app/core/tenant/service/tenant-service';
import { Store } from '@ngrx/store';

@Injectable({providedIn: 'root'})
export class FuseSplashScreenService {

    private currentTenant;

    /**
     * Constructor
     */
    constructor(@Inject(DOCUMENT) private _document: any,
                private _router: Router,
                private store: Store,
                private tenantService: TenantService) {
        // Hide it on the first NavigationEnd event
        // this.tenantService.get().subscribe((response) => {
        //     console.log(response);
        /*this.store.select((state: any) => state.tenant.currentTenant).subscribe((tenant) => {
            this.currentTenant = tenant;
        });*/
            this._router.events.pipe(filter(event => event instanceof NavigationEnd),
                take(1)).subscribe(() => {
                    this.hide()
            });
        // });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(): void {
        this._document.body.classList.remove('fuse-splash-screen-hidden');
    }

    /**
     * Hide the splash screen
     */
    hide(): void {
        setTimeout(() => {
            this._document.body.classList.add('fuse-splash-screen-hidden');
            document.querySelector(".loading").classList.add("open");
        }, 3000);
    }
}
