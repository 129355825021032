import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/core/user/model/user.types';
import { map, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        this._user.next(value);
    }

    get user$(): Observable<User> {

        this.subscribe()
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> { // Método para atualizar o usuário
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response); // Atualiza o valor do usuário no ReplaySubject
            }),
        );
    }

    subscribe() {

        document.querySelectorAll(`app-img-plans`).forEach(item => {
        let storagePlan = localStorage.getItem('planType');
        if (storagePlan != null) {
            let imageUrl = '';

            if (storagePlan === 'ClubMed Prime Gold' || storagePlan === 'gold') {
                imageUrl = 'assets/images/plans/prime gold.png';
            } else if (storagePlan === 'ClubMed Prime Black' || storagePlan === 'black') {
                imageUrl = 'assets/images/plans/prime black.png';
            } else if (storagePlan === 'ClubMed Prime Silver' || storagePlan === 'silver') {
                imageUrl = 'assets/images/plans/prime silver.png';
            }
            item.innerHTML = `<img class="mr-3" src="${ imageUrl }" alt="Diamond" style="width:230px; height: auto;">`;


            // // Clona o botão de tela cheia existente
            // let fullscreenButton = item.querySelector('button').cloneNode(true);
            //
            // // Cria um novo div para conter a imagem e o botão de tela cheia
            // let div = document.createElement('div');
            // div.appendChild();
            // div.appendChild(fullscreenButton);
            //
            // // Adiciona o novo div ao elemento fuse-fullscreen
            // item.appendChild(div);
        }

        });

    }
}
