import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JsonPipe, NgForOf, NgIf, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-prescription-referrals-pdf',
  standalone: true,
    imports: [
        NgStyle,
        MatIconModule,
        NgForOf,
        NgIf,
        JsonPipe
    ],
  templateUrl: './prescription-referrals-pdf.component.html',
  styleUrl: './prescription-referrals-pdf.component.scss'
})
export class PrescriptionReferralsPdfComponent {
    @Input() type: string;
    @Input() data: any;
    @Output() downloadReferralsPdfEvent = new EventEmitter<string>();

    clickButton(subgroupName: string): void {
        console.log('Subgroup to download:', subgroupName);
        this.downloadReferralsPdfEvent.emit(subgroupName);
    }
}
