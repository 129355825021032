import { Translate } from '../medicine/translate/translate.enum';
import { UserRole } from "../commons/enums/user-role.enum";
import {Countries} from "../../layout/common/fields/global-phone-input/enum/countries.enum";

export class EnumUtils {
    static getKeyByValue(value: string, enumerator: object) {
        for (const key in enumerator) {
            if (enumerator[key] === value) {
                return key;
            }
        }
        return null;
    }

    static getEnumKeysByValues(values: [], enumerator: object) {
        const keys = [];
        for (const value of values) {
            const currentKey = this.getKeyByValue(value, enumerator);
            if (currentKey) {
                keys.push(currentKey);
            }
        }

        return keys;
    }

    static filterEnum(enumObject: any, searchString: string): string[] {
        const filteredValues = [];

        for (const key in enumObject) {
            if (enumObject.hasOwnProperty(key)) {
                const translatedEnumValue = Translate[key];
                if (typeof translatedEnumValue === 'string' &&
                    translatedEnumValue.toLowerCase().includes(searchString?.toLowerCase())) {
                    filteredValues[key] = enumObject[key];
                }
            }
        }

        return filteredValues;
    }

    static filterEnumRoleByUser(enumObject: any, user: any): string[] {
        const filteredValues = [];
        if (user.role == UserRole.ROOT) {
            return enumObject;
        }

        if (user.role == UserRole.SECRETARY) {
            return enumObject;
        }

        for (const key in enumObject) {
            if (enumObject.hasOwnProperty(key)) {
                const enumValue = enumObject[key];
                if (user.role == enumValue) {
                    filteredValues[key] = enumObject[key];
                }
            }
        }

        return filteredValues;
    }

    static applyMaskNumber(number: string, countryCode: string): string {
        const country = Countries[countryCode];

        if (!country) {
            return number;
        }

        const cleanedNumber = number.replace(country.code, '');

        let maskedNumber = '';
        let index = 0;

        for (let i = 0; i < country.mask.length; i++) {
            const maskChar = country.mask[i];

            if (maskChar === '0' && index < cleanedNumber.length) {
                maskedNumber += cleanedNumber[index];
                index++;
            } else if (maskChar !== '0') {
                maskedNumber += maskChar;
            }
        }

        return maskedNumber;
    }
}
