import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Flow } from "../flow";
import { AbstractService } from "../../../../../../core/commons/services/abstract.service";

@Injectable({ providedIn: 'root' })
export class FlowService extends AbstractService<Flow> {
    _flow: BehaviorSubject<Flow | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'flows', Flow)
    }

    // Private
    private _flows: BehaviorSubject<any> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get flows(): Observable<any> {
        return this._flows.asObservable();
    }

    /**
     * Getter for item
     */
    get flow$(): Observable<Flow> {
        return this._flow.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Flows
     */
    getFlows(): Observable<any> {
        return this._httpClient.get<Flow[]>('/').pipe(
            tap((response) => {
                this._flows.next(response);
            }),
        );
    }
}
