import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe, LowerCasePipe, NgForOf, NgIf, NgStyle, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { UserService } from '../service/user.service';
import { TranslocoPipe } from '@ngneat/transloco';
import { PrescriptionItemComponent } from '../components/prescription-item/prescription-item.component';
import { Location } from '@angular/common';

@Component({
    selector: 'app-user-patient',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        NgIf,
        RouterLink,
        NgStyle,
        NgForOf,
        DatePipe,
        UpperCasePipe,
        LowerCasePipe,
        TranslocoPipe,
        TitleCasePipe,
        PrescriptionItemComponent
    ],
    templateUrl: './user-patient.component.html',
    styleUrl: './user-patient.component.scss'
})

export class UserPatientComponent implements OnInit {
    appointmentPrescriptions: any;
    count: any;
    showDelayedContent: boolean = false;
    showDelayedContent2: boolean = false;

    constructor(private userService: UserService, private location: Location) {
    }

    ngOnInit(): void {
        this.appointmentPrescription();
        setTimeout(() => this.showDelayedContent = true, 1000);
    }

    appointmentPrescription() {
        this.userService.appointmentPrescription().subscribe((response) => {
            this.count = response.data.length;
            if (this.count == 0) {
                this.showDelayedContent = false;
                setTimeout(() => this.showDelayedContent2 = true, 8000);
            } else {
                this.showDelayedContent = false;
                this.appointmentPrescriptions = response;
            }

        });
    }
}
