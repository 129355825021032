import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { DefaultLayoutComponent } from "app/layout/layouts/default/default.component";
import { FuseVerticalNavigationComponent } from "@fuse/components/navigation";

import { RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-units-health",
    templateUrl: "./ubs.component.html",
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./ubs.component.scss"],
    imports: [DefaultLayoutComponent, FuseVerticalNavigationComponent, RouterOutlet, CommonModule],
})
export class UBSComponent {
    tabs = "queue";
    /**
     * Constructor
     */
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}

    handleChangeTabs = (tab: string) => {
        this.tabs = tab;
        return;
    };
}
