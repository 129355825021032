<!-- Button -->
<button [matMenuTriggerFor]="userActions" mat-icon-button>
    <span class="relative">
        <img *ngIf="showAvatar && user.photo" [src]="user.photo" class="w-7 h-7 rounded-full" />
        <mat-icon
            class="user-data-dropdown"
            *ngIf="!showAvatar || !user.photo"
            [svgIcon]="'heroicons_outline:user-circle'"
        ></mat-icon>
        <!--<span
                [ngClass]="{'mr-px mb-px': !showAvatar || !user?.photo,
                          'bg-green-500': user?.status === 'online',
                          'bg-amber-500': user?.status === 'away',
                          'bg-red-500': user?.status === 'busy',
                          'bg-gray-400': user?.status === 'not-visible'}"
                class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
        ></span>-->
    </span>
</button>

<mat-menu #userActions="matMenu" [xPosition]="'before'">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span *transloco="let t">{{ t("Hello") }},</span>
            <span class="mt-1.5 text-md font-medium">{{ user?.email }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="handleNavigateToMyAccount()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{ "Profile" | transloco }}</span>
    </button>
    <!--<button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Settings</span>
    </button>-->
    <!--<button
            [matMenuTriggerFor]="userStatus"
            mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
        <span>Status</span>
    </button>-->
    <button *ngIf="this.user.role == UserRole.SERVICE_UNIT_MANAGER" [matMenuTriggerFor]="serviceUnits" mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
        <span>Unidades de Saúde</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button (click)="signOut()" mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>{{ "Sign out" | transloco }}</span>
    </button>
</mat-menu>

<mat-menu #serviceUnits="matMenu" class="user-status-menu">
    <ng-container *ngIf="userLocales">
        <ng-container *ngFor="let locale of userLocales">
            <button (click)="updateUserLocation(locale)" mat-menu-item>
                <span class="w-4 h-4 mr-3 rounded-full " [ngClass]="showClassServiceUnit(locale.slug)"></span>
                <span>{{ locale.name }}</span>
            </button>
        </ng-container>
    </ng-container>
    <mat-divider class="my-2"></mat-divider>
    <button (click)="newServiceUnit()" mat-menu-item>
        <mat-icon svgIcon="heroicons_outline:plus-small"></mat-icon>
        <span>Nova Unidade</span>
    </button>
</mat-menu>
<mat-menu #userStatus="matMenu" class="user-status-menu">
    <button (click)="updateUserStatus('online')" mat-menu-item>
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button (click)="updateUserStatus('away')" mat-menu-item>
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button (click)="updateUserStatus('busy')" mat-menu-item>
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button (click)="updateUserStatus('not-visible')" mat-menu-item>
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>