export const Countries = {
    BR: {name: "Brasil +55", code: "+55", mask: "(00) 0 0000-0000"},
    US: {name: "Estados Unidos +1", code: "+1", mask: "(000) 000-0000"},
    CA: {name: "Canadá +1", code: "+1", mask: "(000) 000-0000"},
    MX: {name: "México +52", code: "+52", mask: "000 000 0000"},
    AR: {name: "Argentina +54", code: "+54", mask: "000 00-0000-0000"},
    PT: {name: "Portugal +351", code: "+351", mask: "000 000 000"},
    FR: {name: "França +33", code: "+33", mask: "0 00 00 00 00"},
    DE: {name: "Alemanha +49", code: "+49", mask: "00000 000000"},
    UK: {name: "Reino Unido +44", code: "+44", mask: "00 0000 0000"},
    AU: {name: "Austrália +61", code: "+61", mask: "0 0000 0000"},
    JP: {name: "Japão +81", code: "+81", mask: "0 0000 0000"},
    IN: {name: "Índia +91", code: "+91", mask: "00000 00000"},
    CN: {name: "China +86", code: "+86", mask: "000 0000 0000"},
    RU: {name: "Rússia +7", code: "+7", mask: "8 (000) 000-00-00"},
    ZA: {name: "África do Sul +27", code: "+27", mask: "0 00 000 0000"}
}
