<div class="flex flex-col gap-5 my-5 mx-7">
    <h2 class="font-semibold text-xl">{{ title }}</h2>

    <div class="w-full h-[400px] flex items-center justify-center">
        <div class="flex flex-col items-center justify-center gap-1">
            <img class="w-[100px]" src="/assets/icons/warning.svg" />
            <p class="text-lg font-bold">
                Este serviço está inativo, entre em contato com seu representante para ativação.
            </p>
        </div>
    </div>
</div>
