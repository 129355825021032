import {HttpClientModule, provideHttpClient} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { translocoConf, TranslocoHttpLoader } from "./core/transloco/transloco-root-module";
import { provideTransloco } from "@ngneat/transloco";
import { provideEnvironmentNgxMask } from "ngx-mask";
import {CurrencyPipe, registerLocaleData} from "@angular/common";
import { StoreModule } from '@ngrx/store';
import { tenantReducer } from './core/tenant/state/tenant.state';
import {BrowserModule} from "@angular/platform-browser";
import localePt from '@angular/common/locales/pt';

function HttpModule() {

}

registerLocaleData(localePt);

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            StoreModule.forRoot({
                tenant: tenantReducer
            })
        ),
        BrowserModule, BrowserAnimationsModule, HttpClientModule,
        provideAnimations(),
        provideHttpClient(),
        CurrencyPipe,
        provideEnvironmentNgxMask(),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
        ),
        { provide: LOCALE_ID, useValue: 'pt-BR' },

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco({
            config: translocoConf,
            loader: TranslocoHttpLoader,
        }),

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
    ],
};
