import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { DefaultLayoutComponent } from "app/layout/layouts/default/default.component";
import { FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, } from '@angular/forms';
import { RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Status } from "app/modules/commons/enums/status.enum";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from "@angular/material/tabs";
import { MessagesComponent } from "../messages/messages.component";
import { Flow } from "./flow";
import { FlowService } from "./service/flow.service";
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: "flow-component",
    templateUrl: "./flow.component.html",
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./flow.component.scss"],
    imports: [MatSlideToggleModule, DefaultLayoutComponent, FuseVerticalNavigationComponent, RouterOutlet,
        CommonModule, DefaultLayoutComponent, FuseVerticalNavigationComponent,
        FormsModule, ReactiveFormsModule, MatIconModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule,
        MatOptionModule, MatDividerModule, MatCheckboxModule, MatRadioModule, MatButtonModule, MatAutocompleteModule, MatTooltipModule, MatTabsModule, MessagesComponent, NgxMaskPipe, NgxMaskDirective],
})
export class FlowComponent {
    @ViewChild('form') form: NgForm;
    @Input() flow: Flow;
    formGroup: FormGroup = new FormGroup<any>({
        slug: new FormControl(),
        name: new FormControl(),
        description: new FormControl(),
        type: new FormControl(),
        retryTimes: new FormControl(),
        retryInterval: new FormControl(),
        template: new FormControl()
    });
    data: Flow;
    tabs = "stickyNotes";
    protected readonly Status = Status;
    private reminder: any;
    @ViewChild(MessagesComponent, { static: false }) messageComponent: MessagesComponent;
    protected _unsubscribeAll: Subject<any> = new Subject<any>();


    /**
     * Constructor
     */
    constructor(private _formBuilder: FormBuilder,
                protected _changeDetectorRef: ChangeDetectorRef,
                private flowService: FlowService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getMessages();
    }

    private getMessages() {
        this.flowService.get(this.flow.slug)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data: Flow) => {
                this.data = data;
                this._changeDetectorRef.markForCheck();

                this.formGroup = this._formBuilder.group({
                    slug: [data.slug],
                    name: [data.name],
                    description: [data.description],
                    type: [data.type],
                    retryTimes: [data.retryTimes],
                    retryInterval: [data.retryInterval],
                    template: [data?.template]
                });
        })
    }

    getFormGroup() {
        if (this.messageComponent) {
            const formGroup = this.messageComponent.formGroup.getRawValue();
            this.formGroup.get('template').setValue(formGroup)
        }
    }

    setValue(event: any) {
        this.formGroup.get('template').setValue(event)
    }
}
