import {File} from "../../file/model/file";

export class User {
    slug: string;
    personSlug?: string;
    photo: File;
    name: string;
    photoId: string;
    email: string;
    password: string;
    role: string;
    status: string;
    permissions: any;

    constructor(object?: any) {
        if (object) {
            for (const prop of Object.keys(object)) {
                this[prop] = object[prop];
            }
        }
    }
}
