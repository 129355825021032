<div class="flex flex-col gap-5 my-5 mx-7">
    <div class="flex items-center">
        <button class="font-normal py-3 px-6" (click)="handleChangeTabs('queue')" [ngClass]="tabs === 'queue' ? 'active' : 'inactive'">
            Fila de Espera
        </button>
        <button class="py-3 px-6" (click)="handleChangeTabs('scheduled')" [ngClass]="tabs === 'scheduled' ? 'active' : 'inactive'">
            Agendados
        </button>

        <button class="font-normal py-3 px-6" (click)="handleChangeTabs('done')" [ngClass]="tabs === 'done' ? 'active' : 'inactive'">
            Realizados
        </button>
        <button class="font-normal py-3 px-6" (click)="handleChangeTabs('canceled')" [ngClass]="tabs === 'canceled' ? 'active' : 'inactive'">
            Não Realizado
        </button>
        <button class="py-3 px-6" (click)="handleChangeTabs('professionals')" [ngClass]="tabs === 'professionals' ? 'active' : 'inactive'">
            Profissionais
        </button>
    </div>

    <div class="flex items-center gap-2">
        <div class="bg-gray-200 rounded-xl flex flex-col items-center justify-center py-2 px-4">
            <p>Fila de Espera</p>
            <p class="font-semibold">0</p>
        </div>
        <div class="bg-gray-200 rounded-xl flex flex-col items-center justify-center py-2 px-4">
            <p>Agendados</p>
            <p class="font-semibold">0</p>
        </div>
        <div class="bg-gray-200 rounded-xl flex flex-col items-center justify-center py-2 px-4">
            <p>Realizados</p>
            <p class="font-semibold">0</p>
        </div>
        <div class="bg-gray-200 rounded-xl flex flex-col items-center justify-center py-2 px-4">
            <p>Profissionais</p>
            <p class="font-semibold">0</p>
        </div>
    </div>

    <div *ngIf="tabs == 'professionals'" class="w-full flex items-center justify-end gap-2">
        <button class="text-white bg-black rounded-xl py-3 px-6">Integrar Atendimento Especializado da ClubMed</button>
        <button class="text-white bg-black rounded-xl py-3 px-6">
            Adicionar Profissional Especialista para Teleconsultas
        </button>
    </div>

    <div class="w-full h-[200px] flex items-center justify-center">
        <div class="flex flex-col items-center justify-center gap-1">
            <img class="w-[100px]" src="/assets/icons/warning.svg" />
            <p class="text-lg font-bold">
                Este serviço está inativo, entre em contato com seu representante para ativação.
            </p>
        </div>
    </div>
</div>
