import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AbstractService } from '../../../core/commons/services/abstract.service';
import { VideoCall } from '../model/video-call';
import { environment } from 'app/environments/environment';

@Injectable({ providedIn: 'root' })
export class VideoCallService extends AbstractService<VideoCall> {

    _videoCall: BehaviorSubject<VideoCall | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'video-call', VideoCall)
    }

    private _videoCalls: BehaviorSubject<any> = new BehaviorSubject(null);

    // Private

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get videoCalls(): Observable<any> {
        return this._videoCalls.asObservable();
    }

    /**
     * Getter for item
     */
    get data$(): Observable<VideoCall> {
        return this._videoCall.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get VideoCalls
     */
    getVideoCalls(): Observable<any> {
        return this._httpClient.get<VideoCall[]>('/').pipe(
            tap((response) => {
                this._videoCalls.next(response);
            }),
        );
    }

    getAppointmentVideoCall(uuid: string): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/app/video-call-appointment/${uuid}`);
    }

    authorizeVideoCall(roomData: any, uuid: string): Observable<any> {
        return this._httpClient.post<any>(`${ environment.serverUrl }/app/authorize-video-call/${roomData}`, { roomName: roomData, patientUuid: uuid });
    }
}
