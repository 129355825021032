<div  class="flex flex-col w-full mx-auto px-2 py-3 flex-auto">
    <div *ngFor="let dt of data" class="w-full pt-0">
        <div class="w-full flex justify-between py-3 text-md font-semibold px-3 bg-gray-300 rounded-t shadow items-center" type="button">
            {{ dt.subgroup_name }}
            <mat-icon
                    (click)="clickButton(dt.subgroup_name)"
                    class="cursor-pointer hover:transition-md duration-300 ease-in-out hover:scale-105 md:hover:scale-100 focus:scale-50 active:scale-100 disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
                    [ngStyle]="{'color': '#00000', 'width': '10px', 'height': '10px'}"
                    svgIcon="mat_outline:picture_as_pdf">
            </mat-icon>
        </div>

        <div *ngFor="let item of dt.referrals" class="flex mb-2 items-center w-full py-3 text-xs font-bold px-3 bg-white border border-sky-500 shadow" type="button">
            <div class="flex-grow flex items-center justify-between px-2 mb-2 pt-0">
                <div class="flex items-center">
                    <svg id="Layer_1" data-name="Layer 1" width="30" height="30" opacity="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 112.33">
                        <defs>
                            <style>
                                .cls-1, .cls-2 { fill-rule: evenodd; }
                                .cls-2 { fill: #0976e3; }
                            </style>
                        </defs>
                        <title>medical</title>
                        <path class="cls-1" d="M15.09,8.48a7.6,7.6,0,0,1-1-3.08c-.07-1.7.8-3.11,2.71-4.31A7.9,7.9,0,0,1,18.3.37,5.67,5.67,0,0,1,25.53,8a6,6,0,0,1-5.38,3.64,6.75,6.75,0,0,1-5.06-3.11Z"/>
                        <path class="cls-1" d="M43.57,8.48a7.45,7.45,0,0,0,1-3.08c.07-1.7-.79-3.11-2.71-4.31A7.9,7.9,0,0,0,40.36.37,5.67,5.67,0,0,0,33.13,8a6,6,0,0,0,5.38,3.64,6.75,6.75,0,0,0,5.06-3.11Z"/>
                        <path class="cls-1" d="M102.74,4.26a20.14,20.14,0,0,1,4.92,39.67c0,1.37.09,1.24.15,3.46.11,3.85.23,7.94.23,11.31h0c0,25.13-4.4,53.63-40.84,53.63-13.52,0-23.42-6.48-30.35-15.52-8.17-10.66-12-25-12.73-35.91a35.48,35.48,0,0,1-6.4-6A84.69,84.69,0,0,1,9,42.4a4.62,4.62,0,0,1-.24-4.15l-.43-.83C2.47,26-1.36,13.43.46,8c.85-2.57,2.89-4.26,5.91-5.2A28.32,28.32,0,0,1,16,2a3.2,3.2,0,1,1-.33,6.4,22.39,22.39,0,0,0-7.38.53c-1,.31-1.59.67-1.73,1.09C5.25,13.81,8.83,24.34,14,34.5c.21.42.42.83.64,1.24a4.61,4.61,0,0,1,2.36,2,74.84,74.84,0,0,0,7.7,11.11,29.83,29.83,0,0,0,3.93,3.9l.47,0A25.36,25.36,0,0,0,33,49.24a70.59,70.59,0,0,0,8.13-11.36,4.62,4.62,0,0,1,2.34-2c5.42-10,9.34-20.78,8.54-25-.18-1-1-1.59-2.28-2a24.72,24.72,0,0,0-7.43-.81,3.2,3.2,0,0,1,0-6.4,30.17,30.17,0,0,1,9.47,1.14c3.41,1.15,5.85,3.25,6.54,6.9,1,5.5-3,17.68-8.91,28.7a4.65,4.65,0,0,1-.27,4.19,81.46,81.46,0,0,1-9.26,12.88A32.1,32.1,0,0,1,33.43,61c.69,9.32,4,21.28,10.79,30.15C49.51,98.08,57,103,67.19,103c28.18,0,31.57-23.56,31.57-44.33h0c0-4.35-.1-7.81-.19-11.06-.07-2.2-.13-2.84-.17-3.58a20.14,20.14,0,0,1,4.36-39.8Zm0,7.24a12.9,12.9,0,1,1-12.9,12.9,12.89,12.89,0,0,1,12.9-12.9Z"/>
                        <path class="cls-2" d="M62.52,50.17h8.14a2.77,2.77,0,0,1,2.76,2.76v9.4h9.4a2.77,2.77,0,0,1,2.76,2.76v8.14A2.77,2.77,0,0,1,82.82,76h-9.4v9.39a2.77,2.77,0,0,1-2.76,2.77H62.52a2.78,2.78,0,0,1-2.77-2.77V76H50.36a2.78,2.78,0,0,1-2.77-2.77V65.09a2.78,2.78,0,0,1,2.77-2.77h9.4V52.93a2.77,2.77,0,0,1,2.76-2.76Z"/>
                    </svg>
                    <div class="flex flex-col items-start">
                        <div class="text-sm px-4 font-normal leading-relaxed text-gray-500">
                            {{ item?.service_name }} -
                            <span *ngIf="item?.status === 'Waiting'" style="color: #e3bf09">Aguardando</span>
                            <span *ngIf="item?.status === 'Canceled'" style="color: #cf1313">Cancelado</span>
                            <span *ngIf="item?.status === 'Rejected'" style="color:#7a0f0f">Rejeitado</span>
                            <span *ngIf="item?.status === 'Completed'" style="color: #1b4d05">Completo</span>
                            <span *ngIf="item?.status === 'In Progress'" style="color: #44e347">Em Progresso</span>
                            <span *ngIf="item?.status === 'Scheduled'" style="color: #09bbe3">Agendado</span>
                        </div>
                        <div class="px-4">
                            <p class="text-gray-500 font-normal font-s">{{ item?.tuss }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex mr-0 mb-4">
                    <mat-icon class="ml-2" *ngIf="item?.status === 'Waiting'" [ngStyle]="{'color': '#e3bf09', 'width': '10px', 'height': '10px'}" svgIcon="heroicons_solid:exclamation-circle"></mat-icon>
                    <mat-icon class="ml-2" *ngIf="item?.status === 'Canceled'" [ngStyle]="{'color': '#cf1313', 'width': '10px', 'height': '10px'}" svgIcon="heroicons_solid:x-circle"></mat-icon>
                    <mat-icon class="ml-2" *ngIf="item?.status === 'Rejected'" [ngStyle]="{'color': '#7a0f0f', 'width': '10px', 'height': '10px'}" svgIcon="heroicons_solid:face-frown"></mat-icon>
                    <mat-icon class="ml-2" *ngIf="item?.status === 'Completed'" [ngStyle]="{'color': '#1b4d05', 'width': '10px', 'height': '10px'}" svgIcon="heroicons_solid:check-circle"></mat-icon>
                    <mat-icon class="ml-2" *ngIf="item?.status === 'In Progress'" [ngStyle]="{'color': '#44e347', 'width': '10px', 'height': '10px'}" svgIcon="heroicons_solid:ellipsis-horizontal-circle"></mat-icon>
                    <mat-icon class="ml-2" *ngIf="item?.status === 'Scheduled'" [ngStyle]="{'color': '#09bbe3', 'width': '10px', 'height': '10px'}" svgIcon="heroicons_solid:clock"></mat-icon>
                </div>
            </div>
            <div class="flex force-text-left"></div>
        </div>
    </div>
</div>
