import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, OnDestroy, OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { AsyncPipe, CurrencyPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskPipe } from 'ngx-mask';
import { PaginatorModule } from 'primeng/paginator';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { PatientService } from '../../patient/service/patient.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { Store } from '@ngrx/store';
import { UserService } from '../../../../core/user/service/user.service';
import { User } from '../../../../core/user/model/user.types';
import Swal from 'sweetalert2';
import { PatientViewComponent } from '../../patient/components/view/patient-view.component';
import { PatientCreditComponent } from '../../patient/components/patient-credit/patient-credit.component';
import { CollaboratorLinkComponent } from '../../patient/components/collaborator-link/collaborator-link.component';
import { DefaultLayoutComponent } from '../../../../layout/layouts/default/default.component';
import { FuseVerticalNavigationComponent } from '../../../../../@fuse/components/navigation';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { translate } from '@ngneat/transloco';

@Component({
    selector: 'app-disabled-list',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DefaultLayoutComponent, FuseVerticalNavigationComponent, MatTableModule, DatePipe, CurrencyPipe, NgClass,
        MatButtonModule, MatSortModule, MatPaginatorModule, NgIf, MatIconModule, NgForOf, AsyncPipe, MatInputModule,
        MatSelectModule, FormsModule, RouterLink, RouterOutlet, TranslocoPipe, MatDialogModule, MatTooltipModule, MatMenuModule, NgxMaskPipe,
        MatDatepickerModule, MatRadioModule, ReactiveFormsModule],
    templateUrl: './disabled-list.component.html',
    styleUrl: './disabled-list.component.scss'
})
export class DisabledListComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(MatSort) protected _sort: MatSort;
    columns: string[] = ['status', 'company', 'name', 'jobTitle', 'settings'];
    protected listSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    _unsubscribeAll: Subject<any> = new Subject<any>();
    form: FormGroup;
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    protected currentPage: number = 1;
    protected limit: number = 10;

    data: any;
    pagination: any;
    isLoading: boolean = true;
    status = "";
    user: any;
    patientCompanyStatus = {
        all: '',
        active: 'A',
        disabled: 'F',
    };
    constructor(
        private formBuilder: FormBuilder,
        public service: PatientService,
        private _router: Router,
        public dialog: MatDialog,
        private dateAdapter: DateAdapter<Date>,
        public store: Store,
        public userService: UserService,
        private cdRef: ChangeDetectorRef) {
        this.dateAdapter.setLocale('pt-BR');

        this.form = this.formBuilder.group({
            status: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = {
                    ...user,
                    permissions: JSON.parse(atob(user.permissions))
                };
            });

        this.patientList();
    }


    ngAfterViewInit(): void {
        this.dataSource.sort = this._sort;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    setPage(page) {
        this.currentPage = page.pageIndex + 1;
        this.limit = page.pageSize;
        this.patientList(this.patientCompanyStatus.disabled)
    }

    trackByFn(index: number, item: any): any {
        return item.slug || index;
    }



    patientList(company_status: string = this.patientCompanyStatus.disabled) {
        this.service.getPatientByStatus(this.currentPage, this.limit, company_status).subscribe((response: any) => {
            this.data = response.data;
            console.log(response.data);
            this.dataSource.data = response.data;

            this.pagination = {
                length: response.meta.total,
                pageIndex: response.meta.current_page - 1,
                pageSize: response.meta.per_page
            };
            this.isLoading = false;
            this.listSubject.next(this.data);
            this.cdRef.markForCheck();

        });
    }


    openDetails(enterAnimationDuration: string, exitAnimationDuration: string, data): void {
        this.dialog.open(PatientViewComponent, {
            width: '1000px',
            maxHeight: '700px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: data,
        });
    }

    getCorporateUsed(corporateLimit): number {
        if (corporateLimit != null) {
            return corporateLimit.totalCreditUsedValue;
        }
        return 0;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    dropdownOpen = false;

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    getProgressBar(total, used) {
        let perc = 0;
        if (total > 0) {
            perc = ((total - used) / total) * 100;
        }
        return `width: ${ perc }%`;
    }

    change(status: string) {
        switch (status) {
            case 'all':
                this.status = status;
                this.patientList();
                break;
            case 'active':
                this.status = status;
                this.patientList(this.patientCompanyStatus.active);
                break;
            case 'disabled':
                this.status = status;
                this.patientList(this.patientCompanyStatus.disabled);
                break;
        }
    }

    openLinkCollaborator() {
        CollaboratorLinkComponent
        this.dialog.open(CollaboratorLinkComponent, {
            width: '800px',
        }).afterClosed().subscribe(() => {
            this.patientList(this.patientCompanyStatus.all);
        });
    }

}
