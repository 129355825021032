import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { VideoCallService } from './service/video-call.service';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

declare var JitsiMeetExternalAPI: any;

@Component({
    selector: 'app-jitsi',
    templateUrl: './jitsi.component.html',
    styleUrls: ['./jitsi.component.scss'],
    imports: [
        CdkDrag,
        NgIf,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule
    ],
    standalone: true
})
export class JitsiComponent implements OnInit, AfterViewInit {

    @Input() jwt: any;
    @Input() roomName: any;
    @Input() videoCallData: any;
    @Input() isFullScreen: boolean = false;
    api: any;
    domain: string = 'meet.clubmed.digital';
    options: any;

    isAudioMuted = false;
    isVideoMuted = false;
    minimize: any;
    selectedSize: string = 'w-[1280px] h-[720px]';
    showVideoCall: boolean;

    constructor(private service: VideoCallService) {

    }

    ngOnInit(): void {
        if (this.isFullScreen) {
            this.setSize('w-full h-full');
        }
    }

    ngAfterViewInit() {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
            this.options = {
                roomName: this.roomName,
                interfaceConfigOverwrite: {
                    SHOW_DOMINANT_SPEAKER_DISPLAY_NAME: true,
                    SHOW_JITSI_WATERMARK: false,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    TOOLBAR_BUTTONS: [
                        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                        'fodeviceselection', 'hangup',
                        'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
                        'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
                        'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone',
                        'e2ee', 'localrecording'
                    ],
                    MOBILE_APP_PROMO: false,
                    APP_NAME: "ClubMed Digital",
                    HIDE_DEEP_LINKING_LOGO: false,
                    SHOW_BRAND_WATERMARK: false,
                    SHOW_POWERED_BY: false,

                },
                jwt: this.jwt,
                parentNode: document.querySelector('#jitsi-frame'),
                userInfo: {
                    email: this.videoCallData.loggedUserEmail,
                    displayName: this.videoCallData.loggedUserName,
                },
                configOverwrite: {
                    enableNoisyMicDetection: false,
                    prejoinPageEnabled: false,
                    defaultLanguage: 'pt-BR',
                    disableDeepLinking : true,
                },
                domain: this.domain,
            }

            this.api = new JitsiMeetExternalAPI(this.domain, this.options)

            this.api.addEventListeners({
                readyToClose: this.handleClose,
                participantLeft: this.handleParticipantLeft,
                participantJoined: this.handleParticipantJoined,
                videoConferenceJoined: this.handleVideoConferenceJoined,
                videoConferenceLeft: this.handleVideoConferenceLeft,
                audioMuteStatusChanged: this.handleMuteStatus,
                videoMuteStatusChanged: this.handleVideoStatus,
            });
        }).catch(err => {
            console.error('Erro ao solicitar permissões: ', err);
            //alert('Erro ao solicitar permissões: ' + err.message);
            
        });
    }

    handleClose = ()=>{

    }

    handleParticipantLeft = async (participant: any)=>{
        const data = await this.getParticipants()
    }

    handleParticipantJoined = async (participant: any)=>{
        const data = await this.getParticipants()
    }

    handleVideoConferenceJoined = async (participant: any)=>{
        const data = await this.getParticipants()
    }

    handleVideoConferenceLeft = async (participant: any)=>{
        this.service.subRoute(`/finish`, 'put', { roomName: this.roomName }).subscribe((result) => {
        });
    }

    handleMuteStatus = ()=>{

    }

    handleVideoStatus = ()=>{

    }

    getParticipants() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.api.getParticipantsInfo())
            }, 500)
        })
    }

    executeCommand(command: string) {
        this.api.executeCommand(command);
        if (command == 'hangup') {
        }

        if (command == 'toggleAudio') {
            this.isAudioMuted = !this.isAudioMuted;
        }

        if (command == 'toggleVideo') {
            this.isVideoMuted = !this.isVideoMuted;
        }
    }

    minimizeVideoCall() {
        const match = this.selectedSize.match(/w-\[[^\]]*\]/);

        let widthValue = match && match.length > 0 ? match[0] : '';

        this.minimize = widthValue;
        this.showVideoCall = true;
    }

    maximizeVideoCall() {
        this.minimize = this.selectedSize;
        this.showVideoCall = false;
    }

    setSize(value: any) {
        this.selectedSize = value;
        this.minimize = this.selectedSize;
    }
}
