import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { DefaultLayoutComponent } from "app/layout/layouts/default/default.component";
import { FuseVerticalNavigationComponent } from "@fuse/components/navigation";

import { RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-duty-scale",
    templateUrl: "./duty-scale.component.html",
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./duty-scale.component.scss"],
    imports: [DefaultLayoutComponent, FuseVerticalNavigationComponent, RouterOutlet, CommonModule],
})
export class DutyScaleComponent {
    title = "Escala Plantões";
    /**
     * Constructor
     */
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}
}
