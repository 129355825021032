
export class Bank {
    slug: string;
    bankCode: string;
    agency: string;
    dvAgency: number;
    accountNumber: string;
    dvAccounts: number;
    holder: string;
    document: string;
    accountType: number;
    gatewayCode: string;
    receivingCode: string;
    pixKey: string;
    pixKeyType: string;

    constructor(object?: any) {
        if (object) {
            for (const prop of Object.keys(object)) {
                this[prop] = object[prop];
            }
        }
    }
}
