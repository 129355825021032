export class Tenant {
    slug: string;
    name: string;
    domain: string;
    maintenanceMode: string;
    status: string;
    syncEmergencyDoctors: string;
    syncElectiveDoctors: string;
    allowElectives: string;
    enableEmergency: string;
    electiveMinimumAntecedence: string;
    logo: string;
    logoUrl: string;
    logoUrlWhite: string;
    banner: string;
    bannerUrl: string;
    supportEmail: string;
    customFavicon: string;
    smtpData: string;
    dbHost: string;
    dbName: string;
    dbUser: string;
    dbPassword: string;
    supportChatEnabled: string;
    supportChatSnippet: string;
    whatsappEnabled: string;
    whatsappApiEndpoint: string;
    whatsappApiToken: string;
    jitsiRecordingEnabled: string;
    jitsiUrl: string;
    pusherHost: string;
    pusherPort: string;
    pusherAppId: string;
    pusherAppKey: string;
    pusherAppSecret: string;
    primaryColor: string;
    textColor: string;
    useTermLink: string;
    privacyPolicyLink: string;
    additionalLinks: string;
    companyId: string;
    instanceId: string;
    whiteLogo: string;
    customFaviconImage: string;
    type: string;

    constructor(object?: any) {
        if (object) {
            for (const prop of Object.keys(object)) {
                this[prop] = object[prop];
            }
        }
    }
}
