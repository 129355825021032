<modal [icon]="'heroicons_outline:x-mark'" [title]="patient?.name">

    <!-- Main -->
    <mat-tab-group modalBody>
        <mat-tab label="Perfil">
            <div class="flex flex-col w-full">
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Nome</mat-label>
                            <input [value]="patient?.name" id="name" matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Nome social</mat-label>
                            <input [value]="patient?.socialName" id="socialName" matInput
                                   readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Email</mat-label>
                            <input [value]="patient?.user?.email" id="email" matInput readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Número do documento</mat-label>
                            <input [value]="patient?.documentNumber"
                                   matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Contato</mat-label>
                            <input [value]="patient?.phone"
                                   matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Data de nascimento</mat-label>
                            <input type="date" [value]="patient?.birthdate" id="birthdate" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Gênero</mat-label>
                            <input [value]="EnumUtils.getKeyByValue(patient?.gender, Gender) | transloco" id="gender"
                                   matInput>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Tipo</mat-label>
                            <input [value]="EnumUtils.getKeyByValue(patient?.type, PatientType) | transloco"
                                   id="type"
                                   matInput>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div *ngIf="patient.holder" class="flex flex-col w-full">
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Email</mat-label>
                            <input [value]="patient?.holder?.user?.email" id="holderEmail" matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Nome</mat-label>
                            <input [value]="patient?.holder?.name" id="holderName" matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Nome social</mat-label>
                            <input [value]="patient?.holder?.socialName" id="holderSocialName" matInput
                                   readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Número do documento</mat-label>
                            <input [value]="patient?.holder?.documentNumber"
                                   matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Contato</mat-label>
                            <input [value]="patient?.holder?.phone | mask: '(00) 0 0000-0000'"
                                   matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Data de nascimento</mat-label>
                            <input type="date" [value]="patient?.holder?.birthdate"
                                   id="holderBirthdate"
                                   matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Gênero</mat-label>
                            <input [value]="EnumUtils.getKeyByValue(patient?.holder?.gender, Gender) | transloco"
                                   id="holderGender"
                                   matInput>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
                        <mat-form-field class="mt-4 w-full">
                            <mat-label class="font-bold w-40">Tipo</mat-label>
                            <input [value]="EnumUtils.getKeyByValue(patient?.holder?.type, PatientType) | transloco"
                                   id="holderType"
                                   matInput>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-tab>
        <!--<mat-tab label="Empresa">
            <div class="flex w-full sm:flex-row flex-col justify-between">
                <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
                    <mat-form-field class="flex mt-4 w-full">
                        <mat-label class="font-bold w-40">Empresa</mat-label>
                        <input type="text" readonly [value]="patient?.company?.name" matInput>
                    </mat-form-field>
                </div>
                <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
                    <mat-form-field class="flex mt-4 w-full">
                        <mat-label class="font-bold w-40">Setor</mat-label>
                        <input type="text" readonly [value]="patient?.companyDivision?.name" matInput>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex w-full sm:flex-row flex-col justify-between">
                <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
                    <mat-form-field class="flex mt-4 w-full">
                        <mat-label class="font-bold w-40">Departmento</mat-label>
                        <input type="text" readonly [value]="patient?.companyDepartment?.name" matInput>
                    </mat-form-field>
                </div>
                <div class="flex sm:flex-row items-start sm:items-center w-full sm:w-1/2 mr-2">
                    <mat-form-field class="flex mt-4 w-full">
                        <mat-label class="font-bold w-40">Cargo</mat-label>
                        <input type="text" readonly [value]="patient?.jobTitle?.name" matInput>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>-->
        <mat-tab label="Endereço">
            <div class="flex flex-col w-full">
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">CEP</mat-label>
                            <input [value]="patient?.address?.postcode" id="postcode" matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-2/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Rua</mat-label>
                            <input [value]="patient?.address?.street" id="street" matInput readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Estado</mat-label>
                            <input [value]="EnumUtils.getKeyByValue(patient?.address?.state, State) | transloco"
                                   id="state" matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Cidade</mat-label>
                            <input [value]="patient?.address?.city" id="city" matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-1/3 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Bairro</mat-label>
                            <input [value]="patient?.address?.district" id="district" matInput readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex w-full sm:flex-row flex-col justify-between">
                    <div class="flex sm:flex-row items-start sm:items-center w-1/2 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Número</mat-label>
                            <input [value]="patient?.address?.number" id="number" matInput readonly>
                        </mat-form-field>
                    </div>
                    <div class="flex sm:flex-row items-start sm:items-center w-1/2 mr-2">
                        <mat-form-field class="flex mt-4 w-full">
                            <mat-label class="font-bold w-40">Complemento</mat-label>
                            <input [value]="patient?.address?.details" id="details" matInput readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</modal>
