import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseAlertComponent } from '../@fuse/components/alert';
import { Store } from '@ngrx/store';
import { setTenant } from './core/tenant/actions/tenant.action';
import { TenantService } from './core/tenant/service/tenant-service';
import { Tenant } from './core/tenant/model/tenant';
import { first, Observable, Subscription } from 'rxjs';
import { selectCurrentTenant } from './core/tenant/selectors/tenant.selector';
import { Title } from '@angular/platform-browser';
import { Utils } from './modules/utils/utils';
import { JitsiComponent } from './modules/jisti/jitsi.component';
import { NgIf } from '@angular/common';
import { SharedService } from '../@fuse/services/shared/shared.service';
import { SoketiService } from './core/soketi/soketi.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, FuseAlertComponent, JitsiComponent, NgIf],
})
export class AppComponent implements OnInit {
    tenant$: Observable<Tenant | null>;
    favIcon: HTMLLinkElement = document.querySelector('#app-icon');
    videoCall: Subscription;
    jwt: any;
    roomName: any;
    videoCallData: any;
    confirmVideoCall: any;

    /**
     * Constructor
     */
    constructor(private store: Store,
                private tenantService: TenantService,
                private sharedService: SharedService,
                private soketiService: SoketiService,
                private titleService: Title) {
        this.tenant$ = this.store.select(selectCurrentTenant);
        this.videoCall = this.sharedService.videoCall$.subscribe(result => {
            // console.info("VIDEO-CALL-RESULT");
            // console.info(result);
            this.jwt = result.jwt;
            this.roomName = result.roomName;
            this.videoCallData = result.videoCallData;
            this.confirmVideoCall = result.confirmVideoCall;
        });
    }

    ngOnInit(): void {
        this.tenant$.pipe(first()).subscribe((currentTenant) => {
            if (!currentTenant) {
                this.tenantService.getTenant().subscribe((response) => {
                    this.titleService.setTitle(response.name);
                    this.setTenant(response);
                    this.setVariables(response);
                });
            }

            window.addEventListener("load", function () {
                setTimeout(() => {
                    document.querySelector(".loading").classList.add("open");
                }, 2000);
            });
        });
    }

    setTenant(tenant: Tenant) {
        this.store.dispatch(setTenant({ tenant }));
    }

    private setVariables(tenant: Tenant) {
        const intensity = 50 / 1000;
        const customPropertyName = `--primary-color-50`;
        const rgbaColor = Utils.hexToRgba(tenant.primaryColor, intensity);
        const hexColor = Utils.rgbaToHex(rgbaColor);
        this.favIcon.href = tenant.customFaviconImage ? `data:image/png;base64,${tenant.customFaviconImage}` : "favicon.png";

        document.documentElement.style.setProperty(customPropertyName, rgbaColor);
        document.documentElement.style.setProperty(`--fuse-primary-50-rgb`, rgbaColor);
        document.documentElement.style.setProperty(`--fuse-primary-50`, hexColor);
        document.documentElement.style.setProperty(`--fuse-bg-hover`, 'rgba(0, 0, 0, .1)');

        for (let i = 100; i <= 900; i += 100) {
            const intensity = i / 1000;
            const customPropertyName = `--primary-color-${i}`;
            const rgbaColor = Utils.hexToRgba(tenant.primaryColor, intensity);
            const hexColor = Utils.rgbaToHex(rgbaColor);
            document.documentElement.style.setProperty(customPropertyName, rgbaColor);
            document.documentElement.style.setProperty(`--fuse-primary-${i}-rgb`, rgbaColor);
            document.documentElement.style.setProperty(`--fuse-primary-${i}`, hexColor);
        }

        document.documentElement.style.setProperty('--primary-color', tenant.primaryColor);
        document.documentElement.style.setProperty('--text-color', tenant.textColor);
        document.documentElement.style.setProperty('--fuse-icon', tenant.primaryColor);
        document.documentElement.style.setProperty('--fuse-bg-card-rgb', '#FFFFFF');
    }
}
