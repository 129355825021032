<div  class="flex flex-col w-full mx-auto px-2 py-3 flex-auto">
    <div class="w-full pt-0">
        <div class="w-full flex justify-between py-3 text-md font-semibold px-3 bg-gray-300 rounded-t shadow items-center" type="button">
            Baixar PDF
            <mat-icon
                    (click)="clickButtonChild(mediciPdf)"
                    class="cursor-pointer hover:transition-md duration-300 ease-in-out hover:scale-105 md:hover:scale-100 focus:scale-50 active:scale-100 disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
                    [ngStyle]="{'color': '#00000', 'width': '10px', 'height': '10px'}"
                    svgIcon="mat_outline:picture_as_pdf">
            </mat-icon>
        </div>

        <div *ngFor="let item of data" class="flex mb-2 items-center w-full py-3 text-xs font-bold px-3 bg-white border border-sky-500 shadow" type="button">
            <div class="flex-grow flex items-center justify-between px-2 mb-2 pt-0">
                <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="24" height="24">
                        <path fill="#0E80CB" d="M614.3 247c16.3-25 25.7-54.9 25.7-87C640 71.6 568.4 0 480 0c-32.1 0-61.9 9.4-87 25.7c-7.9 5.2-8.5 16.2-1.8 22.9L591.4 248.8c6.7 6.7 17.8 6.2 22.9-1.8zM567 294.3c7.9-5.2 8.5-16.2 1.8-22.9L368.6 71.2c-6.7-6.7-17.8-6.2-22.9 1.8c-16.3 25-25.7 54.9-25.7 87c0 88.4 71.6 160 160 160c32.1 0 61.9-9.4 87-25.7zM301.5 368H18.5c-9.5 0-16.9 8.2-15 17.5C18.9 457.8 83.1 512 160 512s141.1-54.2 156.5-126.5c2-9.3-5.5-17.5-15-17.5zm0-32c9.5 0 16.9-8.2 15-17.5C301.1 246.2 236.9 192 160 192S18.9 246.2 3.5 318.5c-2 9.3 5.5 17.5 15 17.5H301.5z"/>
                    </svg>
                    <div class="flex flex-col items-start">
                        <div class="text-sm px-4 font-normal leading-relaxed text-gray-500">{{ item?.medication }}</div>
                        <div class="px-4">
                            <p class="text-gray-500 font-normal font-s">{{ item?.tuss }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
