import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, map, Observable, of, Subject, switchMap, throwError } from "rxjs";
import { Utils } from "../../../modules/utils/utils";

export abstract class AbstractService<E> {
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    public baseUrl: string;
    private additionalSearch: string;

    protected constructor(protected http: HttpClient, private path: string, private object) {
        this.baseUrl = `${environment.serverUrl}/${path}`;
    }

    setAdditionalSearch(additionalSearch) {
        this.additionalSearch = additionalSearch;
    }

    list(page: number = 1, limit: number = 10, searchTerm = null, uuid = null, urlPrefix = null): Observable<any> {
        let url: string = "";
        if (uuid && urlPrefix) {
            url = `${urlPrefix}/${uuid}/${this.path}`;
        }

        let baseUrl = `${url != "" ? environment.serverUrl : this.baseUrl}/` + url + `?page=${page}&limit=${limit}`;
        if (searchTerm) {
            baseUrl += `&searchTerm=${searchTerm.toLowerCase()}`;
        }
        if (this.additionalSearch) {
            baseUrl += this.additionalSearch;
            this.setAdditionalSearch('');
        }

        let userLocation = localStorage.getItem('userLocation')
        if (userLocation) {
            baseUrl += `&serviceUnitUserLocation=${userLocation}&`;
        }

        return this.http.get<E[]>(baseUrl, { headers: this.httpOptions.headers }).pipe(
            map((response: any): {} => {
                const responseObject = { data: [], pagination: {} };
                if (response.data && Array.isArray(response.data)) {
                    response.data.forEach((entity: any) => responseObject.data.push(new this.object(entity)));
                }

                if (response.meta) {
                    responseObject.pagination = {
                        length: response.meta.total,
                        pageIndex: response.meta.current_page - 1,
                        currentPage: response.meta.current_page,
                        pageSize: response.meta.per_page,
                        lastPage: response.meta.last_page,
                    };
                }

                return responseObject;
            })
        );
    }

    get(id?: any, queryParam = null): Observable<any> {
        let search = '?';
        let userLocation = localStorage.getItem('userLocation');
        if (userLocation) {
            search += `serviceUnitUserLocation=${userLocation}`;
        }
        if (queryParam != null) {
            search += queryParam;
        }
        const baseUrl = id ? `${this.baseUrl}/${id}` : `${this.baseUrl}`;
        return this.http.get(baseUrl + search, { headers: this.httpOptions.headers }).pipe(
            map((response: any) => {
                return new this.object(response.data || response);
            }),
            switchMap((data) => {
                return of(data);
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    save(object: E | any, path: string = ""): Observable<E> {
        const url = Utils.isEmpty(path) ? this.baseUrl : `${this.baseUrl}/${path}`;
        if (object.hasOwnProperty("slug")) {
            return this.http.put<E>(`${url}/${object.slug}`, object, this.httpOptions);
        }

        return this.http.post<E>(url, object, this.httpOptions);
    }

    delete(id: string): Observable<E> {
        return this.http.delete<E>(`${this.baseUrl}/${id}`, this.httpOptions);
    }

    // save(object: E | any, path: string = ''): Observable<E> {
    //     const url = Utils.isEmpty(path) ? this.baseUrl : `${ this.baseUrl }${ path }`;
    //     if (object.hasOwnProperty('id')) {
    //         return this.http.put<E>(`${ url }${ object.id }`, object, this.httpOptions);
    //     }
    //
    //     return this.http.post<E>(url, object, this.httpOptions);
    // }

    getAll(path = null) {
        const newUrl = path ? `${this.baseUrl}/${path}` : `${this.baseUrl}`;
        return this.http.get(newUrl, { headers: this.httpOptions.headers }).pipe(
            map((response: any) => {
                return new this.object(response);
            })
        );
    }

    subRoute(
        path: string,
        method: string = "get",
        object?: any,
        id?: any,
        newBaseUrl: string = null,
        searchTerm = null
    ): Observable<any | any[]> {
        const baseUrl = Utils.isEmpty(newBaseUrl) ? this.baseUrl : `${environment.serverUrl}/${newBaseUrl}`;
        let url = !id ? `${baseUrl}${path}` : `${baseUrl}/${id}/${path}`;

        if (method === "put" || method === "post") {
            return this.http[method](url, object, { headers: this.httpOptions.headers });
        }

        if (searchTerm) {
            url += `&searchTerm=${searchTerm.toLowerCase()}`;
        }

        if (this.additionalSearch) {
            url += this.additionalSearch;
            this.setAdditionalSearch('');
        }

        return this.http[method](url, { headers: this.httpOptions.headers }).pipe(
            map((response: any): {} => {
                let responseObject = { data: [], pagination: {} };
                if (response.pagination) {
                    response.data.forEach((entity: any) => responseObject.data.push(new this.object(entity)));
                    responseObject.pagination = {
                        length: response.meta.total,
                        pageIndex: response.meta.current_page - 1,
                        currentPage: response.meta.current_page,
                        pageSize: response.meta.per_page,
                        lastPage: response.meta.last_page,
                    };
                } else {
                    responseObject = response;
                }

                return responseObject;
            })
        );
    }

    // save(object: E, path: string = ''): Observable<E> {
    //     const url = Utils.isEmpty(path) ? this.baseUrl : `${ this.baseUrl }${ path }/`;
    //     if (object['id'] || object.hasOwnProperty('id')) {
    //         return this.http.put<E>(`${ url }${ object['id'] }`, object, this.httpOptions);
    //     }
    //
    //     return this.http.post<E>(url, object, this.httpOptions);
    // }
    //
    // delete(object?: E, id?): Observable<boolean> {
    //     return this.http.delete(`${ this.baseUrl }${ object ? object['id'] : id }`, { headers: this.httpOptions.headers })
    //         .map((response: Response) => response.ok)
    //         .catch(() => {
    //             return Observable.of(false);
    //         });
    // }

    // getAllTree(userTree = false): Observable<any> {
    //     const url = !userTree ? `${ this.baseUrl }tree` : `${ this.baseUrl }tree?userTree=true`;
    //     return this.http.get(url).map((response: any) => response);
    // }
    //
    // getTree(id: number): Observable<any> {
    //     return this.http.get(`${ this.baseUrl }${ id }/tree`).map((response: any) => response.data);
    // }

    find(param: string, page: number = 1, path = null, status = null, operator = null): Observable<any> {
        status = status ? `&status=${status}` : "";
        let baseUrl = `${this.baseUrl}?searchTerm=${param.toLowerCase()}&page=${page}${status}&operator=${operator}`;
        if (this.additionalSearch) {
            baseUrl += this.additionalSearch;
            this.setAdditionalSearch('');
        }

        return this.http
            .get(baseUrl, {
                headers: this.httpOptions.headers,
            })
            .pipe(
                map((response: any): {} => {
                    const responseObject = { data: [], pagination: {} };
                    response.data.forEach((entity: any) => responseObject.data.push(new this.object(entity)));
                    responseObject.pagination = {
                        length: response.meta.total,
                        pageIndex: response.meta.current_page - 1,
                        currentPage: response.meta.current_page,
                        pageSize: response.meta.per_page,
                        lastPage: response.meta.last_page,
                    };
                    return responseObject;
                })
            );
    }

    findList(param: string, page: number = 1) {
        const subject = new Subject<E[]>();
        this.http
            .get(`${this.baseUrl}search-index?findby=${param.toLowerCase()}&page=${page}`, {
                headers: this.httpOptions.headers,
            })
            .subscribe(
                (json: any) => {
                    const results = json.results || json.data || json;
                    subject.next(
                        results.map((object: any) => {
                            const obj = new this.object(object);
                            if (json.meta) {
                                obj.meta = json.meta;
                            }

                            return obj;
                        })
                    );
                },
                (error) => subject.error(error)
            );

        return subject.asObservable();
    }

    getDecryptedParams = (hash: string): Observable<any> =>
        this.http.get(`${environment.serverUrl}decrypt/${hash}`, { headers: this.httpOptions.headers });
}
