// tenant.selectors.ts

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TenantState } from '../state/tenant.state';

export const selectTenantState = createFeatureSelector<TenantState>('tenant');

export const selectCurrentTenant = createSelector(
    selectTenantState,
    (state: TenantState) => state.currentTenant
);
