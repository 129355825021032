import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, tap } from 'rxjs';
import { User } from '../model/user';
import { AbstractService } from '../../../core/commons/services/abstract.service';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractService<User> {
    _user: BehaviorSubject<User | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super(_httpClient, 'users', User)
    }

    // Private
    private _users: BehaviorSubject<any> = new BehaviorSubject(null);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get users(): Observable<any> {
        return this._users.asObservable();
    }

    /**
     * Getter for item
     */
    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Procedures
     */
    getUsers(): Observable<any> {
        return this._httpClient.get<User[]>('/').pipe(
            tap((response) => {
                this._users.next(response);
            }),
        );
    }


    /* User Patients */
    appointmentPrescription(): Observable<any> {
        return this._httpClient.get<any[]>(`${ environment.serverUrl }/appointments-prescriptions-app`);
    }

    appointmentPrescriptionsDetails(slug:string ,type:string): Observable<any> {
        return this._httpClient.get<any[]>(`${ environment.serverUrl }/appointments-prescriptions-details/${slug}?processLargeFile=1&type=${type}`);
    }


}
