import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class Utils {

    static camelCase(key) {
        const regex = /(_\w)/g;
        return key.replace(regex, match => match[1].toUpperCase());
    }

    static onlyNumber(value: string): string {
        const numbers = value.match(/\d+/g);
        return numbers ? numbers.join('') : '';
    }

    static formatPhoneNumber(phoneNumber: string): string {
        const number = phoneNumber.replace(/\D/g, '');
        return number.replace(/(\d{2})(\d)(\d{4})(\d{4})/, '($1) $2 $3-$4');
    }

    static getValueInString(data, regex) {
        const replacedData = data.match(regex);
        return replacedData;
    }

    static snakeCase(key) {
        const regex = /[\w]([A-Z])/g;
        return key.replace(regex, match => `${ match[0] }_${ match[1] }`).toLowerCase();
    }

    static isEmpty(value: any) {
        return value == null || value === 'undefined' || !value || value === '' || value.length === 0;
    }

    static toBoolean(value: string): boolean {
        return value === 'true';
    }

    static replaceToEmpty(value: string, match: string) {
        const newValue = value.replace(match, '');
        return newValue;
    }

    static objectToString(messages: any[]) {
        const messageArray = [];
        for (const message of Object.keys(messages)) {
            messageArray.push(messages[message]);
        }

        return messageArray;
    }

    static getBasename(path: string, ext: boolean = true) {
        const basename = path.split('/').reverse()[0];
        return ext ? basename : basename.split('.')[0];
    }

    static getExtension(filename: string) {
        return filename.split('/').reverse()[0].split('.')[1];
    }

    static getType(mimeType: string): string {
        const map = new Map<string, string>();
        map.set('application/pdf', 'pdf');
        map.set('application/xml', 'xml');
        map.set('text/plain', 'txt');
        map.set('application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'docx');
        map.set('application/msword', 'doc');
        map.set('image/png', 'png');
        map.set('image/jpeg', 'jpg');
        map.set('image/gif', 'gif');
        map.set('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx');
        map.set('application/vnd.ms-excel', 'xls');
        map.set('application/vnd.openxmlformats-officedocument.presentationml.presentation', 'pptx');
        map.set('application/vnd.ms-powerpoint', 'ppt');
        map.set('default', 'bpmn');
        return map.get(mimeType.toLowerCase());
    }

    public static sliceTextByLength(text, length, start, end) {
        return text && text.length > length ? `${ text.slice(start, end) }...` : text;
    }

    static toSnakeCase(object) {
        const newObject = {};
        for (const prop of Object.keys(object)) {
            newObject[this.snakeCase(prop)] = object[prop];
        }

        return newObject;
    }

    static prepareConstToSelectElement(object: object) {
        const objects: object[] = [];
        Object.keys(object).forEach((type) => {
            objects.push(Object.assign({}, object[type]));
        });

        return objects;
    }

    static generateFileByUrl(uri: any) {
        let byteString;
        if (uri.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(uri.split(',')[1]);
        } else {
            byteString = unescape(uri.split(',')[1]);
        }

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ia], 'Manager Signature.png', { type: 'image/png' });
    }

    static generateFileByBase64(base64: any) {
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([ab], 'Photo.png', { type: 'image/png' });
    }

    static createFile(data, signatureImageUrl, metadata) {
        return new File(data, signatureImageUrl, metadata);
    }

    static cropSignatureCanvas(canvas) {

        // First duplicate the canvas to not alter the original
        const croppedCanvas = document.createElement('canvas');
        const croppedCtx = croppedCanvas.getContext('2d');

        croppedCanvas.width = canvas.width;
        croppedCanvas.height = canvas.height;
        croppedCtx.drawImage(canvas, 0, 0);

        // Next do the actual cropping
        let w = croppedCanvas.width;
        let h = croppedCanvas.height;
        const pix = { x: [], y: [] };
        const imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height);
        let x;
        let y;
        let index;

        for (y = 0; y < h; y++) {
            for (x = 0; x < w; x++) {
                index = (y * w + x) * 4;
                if (imageData.data[index + 3] > 0) {
                    pix.x.push(x);
                    pix.y.push(y);

                }
            }
        }
        pix.x.sort((a, b) => a - b);
        pix.y.sort((a, b) => a - b);
        const n = pix.x.length - 1;

        w = pix.x[n] - pix.x[0];
        h = pix.y[n] - pix.y[0];
        const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

        croppedCanvas.width = w;
        croppedCanvas.height = h;
        croppedCtx.putImageData(cut, 0, 0);

        return croppedCanvas.toDataURL();
    }

    static shortName(fullName: any) {
        if (fullName && fullName.length > 0) {
            const arrayName = fullName.trim().split(' ');
            if (arrayName.length > 1) {
                const secondName = arrayName[1];
                const firstLetter = `${ secondName[0] }.`;
                return arrayName[0].concat(` ${ firstLetter }`);
            }
            return fullName;
        }
    }

    static trim(value) {
        return value.replace(/\s/g, '');
    }

    static isMobile() {
        const ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            return true;
        }
        return false;
    }

    static getModalWidth() {
        return window.innerWidth > 1000 ? '900px' : '95%';
    }

    static ucFirstLetter(value: string): string {
        const first = value.substr(0, 1).toUpperCase();
        return first + value.substr(1);
    }

    sliceTextByLength(text, length, start, end) {
        return text && text.length > length ? `${ text.slice(start, end) }...` : text;
    }

    static hexToRgbNew(hexadecimal: string) {
        const arrBuff = new ArrayBuffer(4);
        const vw = new DataView(arrBuff);
        vw.setUint32(0, parseInt(hexadecimal, 16), false);
        const arrByte = new Uint8Array(arrBuff);

        return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];
    }

    static hexToRgba(hex = null, alpha = 0) {
        hex = hex.replace(/^#/, '');

        const r = parseInt(hex.slice(0, 2), 16);
        const g = parseInt(hex.slice(2, 4), 16);
        const b = parseInt(hex.slice(4, 6), 16);

        // Garante que o valor de alfa esteja no intervalo de 0 a 1
        alpha = alpha || 1;
        alpha = Math.min(1, Math.max(0, alpha));

        // Retorna a cor no formato RGBA
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    }

    static rgbaToHex(rgbaColor: string) {
        rgbaColor = rgbaColor.replace(/^#/, '');
        if (/^([0-9A-Fa-f]{8})$/.test(rgbaColor)) {
            const r = rgbaColor.slice(0, 2);
            const g = rgbaColor.slice(2, 4);
            const b = rgbaColor.slice(4, 6);

            return `#${ r }${ g }${ b }`;
        }

        return '#000000';
    }

    static truncateFileString(str: string): string {
        if (str.length > 24) {
            const firstPart = str.substring(0, 15);
            const lastPart = str.substring(str.length - 6);
            return `${firstPart}...${lastPart}`;
        }
        return str;
    }

    static templateToString(template: string): string {
        if (template.startsWith('[')) {
            template = template.slice(1, -1);
        }

        if (template.startsWith('{')) {
            const parsedData = JSON.parse(template);
            const components = parsedData.data[0].components;

            let bodyText = '';
            let buttonsText = '';

            components.forEach((component: any) => {
                if (component.type === 'BODY') {
                    bodyText = component.text + '\n\n';
                } else if (component.type === 'BUTTONS') {
                    component.buttons.forEach((button: any) => {
                        buttonsText += button.text + '\n';
                    });
                }
            });

            return `${bodyText} ${buttonsText.trim()}`;
        }

        return template
    }

    static filterArray(array: any[], searchString: any) {
        const filteredValues = [];

        for (let item in array) {
            if (typeof array[item] === 'string' && array[item].includes(searchString)) {
                filteredValues[item] = array[item];
            }
        }

        return filteredValues;
    }

    static dateEnToDateBr(date: string): string {
        const datePipe = new DatePipe('en-US');
        const formattedDate = datePipe.transform(date, 'dd/MM/yyyy');
        return formattedDate || '';
    }

    static transformEnDateToBrDate(data: string) {
        if (!data) return data;
        if (/^\d{4}-\d{2}-\d{2}$/.test(data)) {
            const parts = data.split("-");
            return `${ parts[2] }/${ parts[1] }/${ parts[0] }`;
        } else {
            return data;
        }
    }

    static currencyFormat(price: any) {
        const value = (price == null) ? 0 : price;
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    static formatCpf(cpf: string): string {
        if (!cpf || cpf.length !== 11) {
            return cpf;
        }

        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
}
