

<fuse-card class="flex flex-col justify-center  rounded-lg text-center bg-white p-8">
    <form [formGroup]="bankForm">
        <div class="w-full mt-3">
            <div class="text-xl">Cadastro</div>
        </div>
        <div class="flex flex-row flex-wrap">
            <div class="w-full px-3 mb-6 md:mb-0">
                <mat-form-field class="w-full">
                    <mat-label>Titular</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'" matPrefix></mat-icon>
                    <input [formControlName]="'holder'" matInput>
                </mat-form-field>
            </div>
            <div class="w-full  px-3 mb-6 md:mb-0" *ngIf="action !== 'Editar'">
                <mat-form-field class="w-full">
                    <mat-label>Conta</mat-label>
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:credit-card'" matPrefix>¿
                    </mat-icon>
                    <input type="text" matInput [formControl]="myControl" [formControlName]="'bankSlug'"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredOptions | async"
                                    [value]="option">{{ option.name }} | {{ option.code }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="w-full  px-3 mb-6 md:mb-0">
                <mat-form-field class="w-full">
                    <mat-label>Textarea</mat-label>
                    <textarea [formControlName]="'observation'" matInput></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="mt-11 mb-10 border-t"></div>
        <div class="flex justify-end">
            <button (click)="goBack()" class="cancel-button mx-2" mat-raised-button>Cancelar</button>
            <button (click)="save()" class="save-button md:self-end mx-2" color="primary" mat-raised-button>{{ action }}</button>
        </div>
    </form>
</fuse-card>





















