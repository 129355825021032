import { Injectable } from '@angular/core';
import { User } from "../user/model/user";
import { FuseNavigationItem } from "../../../@fuse/components/navigation";

@Injectable()
export class Role {

    static hasPermission(permission: string, user: User) {
        return user.permissions.includes(permission);
    }

    static showCollapsable(children: FuseNavigationItem[], user: User) {
        for (let child of children) {
            if (user.permissions.includes('View ' + child.resource) || !child.resource) {
                return true
            }
        }

        return false;
    }
}
