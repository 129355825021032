import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgForOf, NgStyle } from '@angular/common';

@Component({
  selector: 'app-prescription-medicine-pdf',
  standalone: true,
    imports: [
        MatIconModule,
        NgStyle,
        NgForOf
    ],
  templateUrl: './prescription-medicine-pdf.component.html',
  styleUrl: './prescription-medicine-pdf.component.scss'
})
export class PrescriptionMedicinePdfComponent {

    @Input() type: string;
    @Input() data: any;
    @Input() mediciPdf: string;
    @Output() downloadMedicinePdfEvent = new EventEmitter<string>();

    clickButtonChild(pdf: string): void {
        this.downloadMedicinePdfEvent.emit(pdf);
    }

}
