<div class="container p-8">
    <div class="parent gap-2">
        <div class="div1">
            <div class="bg-white rounded-lg shadow-md h-full ">
                <div class="bg-card-header rounded-tl-lg rounded-tr-lg shadow-md text-white py-2 w-full ab">
                    <div class="flex justify-center items-center">
                        <p>Solicitação de Resgate</p>
                    </div>
                </div>
                <div class="relative p-4 flex flex-col min-w-0  break-words shadow-soft-xl rounded-2xl bg-clip-border">
                    <div class="flex-auto">
                        <div class="flex flex-wrap -mx-3">
                            <!-- Card form -->
                            <form
                                    class="flex flex-col flex-0 items-start w-full   sm:p-8 space-y-6 overflow-y-auto"
                                    [formGroup]="cardForm">

                                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                    <mat-label>Banco</mat-label>
                                    <mat-select [formControlName]="'bankAccountSlug'">
                                        <mat-option *ngFor="let bank of banks" [value]="bank.slug">
                                            {{ bank.bankName }} | Agência: {{ bank.agency }} | Conta: {{ bank.account_number }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field
                                        class="w-full"
                                        [subscriptSizing]="'dynamic'">
                                    <mat-label>Valor</mat-label>
                                    <input matInput [formControlName]="'value'" (input)="replaceComma($event)">
                                    <span class="mr-1" matPrefix>R$ </span>
                                </mat-form-field>
                            </form>

                        </div>

                    </div>
                    <div class="flex justify-end pr-6">
                        <button class="save-button" color="primary" (click)="save()" mat-raised-button>{{ action }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="div2">
            <div class="bg-white rounded-lg shadow-md h-28 ">
                <div class="bg-card-header rounded-tl-lg rounded-tr-lg shadow-md text-white py-2 w-full ab">
                    <div class="flex justify-center items-center">
                        <p>Saldo Disponível</p>
                    </div>
                </div>
                <div class="flex justify-between items-center">

                    <div class="p-3">
                        <p class="mb-0 font-sans font-semibold text-gray-600 leading-normal text-lg">Valor</p>
                        <h5 class="mb-0 font-semibold text-green-600">
                            {{ balanceValue | currency: 'BRL':'symbol':'1.2-2' }}
                            <!--                                        <span class="leading-normal text-sm font-weight-bolder text-lime-500">+55%</span>-->
                        </h5>

                    </div>
                    <div class="flex flex-col justify-center items-center">
                        <p class="mb-0 font-sans font-semibold text-gray-600 leading-normal text-lg">Soma de Solicitações Pendentes</p>
                        <h5 class="mb-0 font-bold text-orange-600">
                            {{ pendingValue | currency: 'BRL':'symbol':'1.2-2' }}
                        </h5>
                    </div>

                    <div>
                        <div class="inline-block w-12 h-12 text-center rounded-lg bg-gray-200 shadow-soft-2xl mr-3">
                            <div class="  flex items-center justify-center    w-12 h-12 text-center rounded-lg  shadow-lg">
                                <mat-icon class="text-green-600" svgIcon="mat_solid:monetization_on"></mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="div3">
            <div class="bg-white rounded-lg shadow-md h-28 ">
                <div class="bg-card-header rounded-tl-lg rounded-tr-lg shadow-md text-white py-2 w-full ab">
                    <div class="flex justify-center items-center">
                        <p>Faturamento Total</p>
                    </div>
                </div>
                <div class="relative flex flex-col min-w-0  break-words shadow-soft-xl rounded-2xl bg-clip-border">
                    <div class="flex-auto p-4">
                        <div class="flex flex-wrap -mx-3">

                            <div class="flex-none w-2/3 max-w-full px-3">
                                <div>
                                    <p class="mb-0 font-sans font-semibold text-gray-600 leading-normal text-lg">Valor</p>
                                    <h5 class="mb-0 font-bold text-green-600">
                                        {{ totalValue | currency: 'BRL':'symbol':'1.2-2' }}
                                        <!--                                        <span class="leading-normal text-sm font-weight-bolder text-lime-500">+55%</span>-->
                                    </h5>
                                </div>
                            </div>

                            <div class="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
                                <div class="inline-block w-12 h-12 text-center rounded-lg bg-gray-200 shadow-soft-2xl">
                                    <div class="  flex items-center justify-center    w-12 h-12 text-center rounded-lg  shadow-lg">
                                        <mat-icon class="text-green-600" svgIcon="mat_solid:monetization_on"></mat-icon>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="div4">
            <div class="bg-white rounded-lg shadow-md h-30 ">
                <div class="bg-card-header rounded-tl-lg rounded-tr-lg shadow-md text-white py-2 w-full ab">
                    <div class="flex justify-center items-center">
                        <p>Lançamentos Futuros</p>
                    </div>
                </div>
                <div class="relative flex flex-col min-w-0  break-words shadow-soft-xl rounded-2xl mb-2 bg-clip-border">
                    <div class="flex-auto p-4">
                        <div class="flex flex-wrap -mx-3">
                            <div class="flex-none w-2/3 max-w-full px-3">
                                <div>
                                    <p class="mb-0 font-sans font-semibold text-gray-600 leading-normal text-lg">Valor</p>
                                    <h5 class="mb-0 font-bold text-green-600">
                                        {{ futureValue | currency: 'BRL':'symbol':'1.2-2' }}
                                        <!--                                        <span class="leading-normal text-sm font-weight-bolder text-lime-500">+55%</span>-->
                                    </h5>
                                </div>
                            </div>
                            <div class="w-4/12 max-w-full px-3 ml-auto text-right flex-0">
                                <div class="inline-block w-12 h-12 text-center rounded-lg bg-gray-200 shadow-soft-2xl">
                                    <div class="  flex items-center justify-center    w-12 h-12 text-center rounded-lg  shadow-lg">
                                        <mat-icon class="text-green-600" svgIcon="mat_solid:monetization_on"></mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-white rounded-lg shadow-md mt-4 ">
        <div class="bg-white rounded-tl-lg rounded-tr-lg shadow-md text-gray-600 py-2 w-full ab">
            <div class="flex justify-center items-center">
                <p>Histórico de Resgates</p>
            </div>
        </div>

        <div class="flex flex-auto overflow-hidden">
            <!-- Main -->
            <div class="flex-auto w-full p-4">
                <div class="grid grid-cols-1 gap-8 w-full">
                    <!-- Tabela antiga -->
                    <div class="xl:col-span-2 flex flex-col flex-auto rounded-2xl overflow-hidden">
                        <div class="relative overflow-x-auto">

                            <mat-form-field style="width: 100%; font-size: 14px;">
                                <mat-label [ngClass]="'w-full'">Select / Busca</mat-label>
                                <input matInput (keyup)="applyFilter($event)" [placeholder]="'Busca'" #input>
                                <mat-select matPrefix (selectionChange)="SearchSelect($event.value)">
                                    <mat-option *ngFor="let option of statusOptions" [value]="option.value">
                                        {{ option.viewValue }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="mat-elevation-z8">
                                <table style="width: 100%;" mat-table [dataSource]="dataSource" matSort>
                                    <!-- Progress Column -->
                                    <ng-container matColumnDef="withdrawCreatedAt">
                                        <th mat-header-cell style="width: 130px !important;" *matHeaderCellDef mat-sort-header> Data</th>
                                        <td mat-cell *matCellDef="let row"> {{ row.withdrawCreatedAt | date:'dd/MM/yyyy' }}
                                        </td>
                                    </ng-container>

                                    <!-- Progress Column -->
                                    <ng-container matColumnDef="transaction_id">
                                        <th mat-header-cell style="width: 220px !important;" *matHeaderCellDef mat-sort-header> ID da Transação</th>
                                        <td mat-cell *matCellDef="let row"> {{ row.transaction_id}}</td>
                                    </ng-container>

                                    <!-- Progress Column -->
                                    <ng-container matColumnDef="bankName">
                                        <th mat-header-cell style="width: 270px !important;" *matHeaderCellDef mat-sort-header> Banco</th>
                                        <td mat-cell *matCellDef="let row"> {{ row.bankName}}</td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="pix_key">
                                        <th mat-header-cell style="width: 210px !important;"  *matHeaderCellDef class="wide-column" mat-sort-header>Pix</th>
                                        <td mat-cell *matCellDef="let row"
                                            class="wide-column"> {{ row.pix_key }}
                                        </td>
                                    </ng-container>

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="withdrawValue">
                                        <th mat-header-cell *matHeaderCellDef class="wide-column" mat-sort-header> Valor do
                                            Resgate
                                        </th>
                                        <td mat-cell *matCellDef="let row"
                                            class="wide-column"> {{ row.withdrawValue | currency:'BRL' }}
                                        </td>
                                    </ng-container>
                                    <!-- Name Column -->
                                    <ng-container matColumnDef="withdrawStatus">
                                        <th mat-header-cell *matHeaderCellDef class="wide-column" mat-sort-header> Status
                                        </th>
                                        <td mat-cell *matCellDef="let row" class="wide-column">
                                        <span class="badge"
                                              [ngClass]="getBadgeClass(row.withdrawStatus)">{{ getBadgeText(row.withdrawStatus) }}</span>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    <!-- Row shown when there is no matching data. -->
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="4">Não há resgate solitado até o
                                            momento {{ input.value }}
                                        </td>
                                    </tr>
                                </table>
<!--                                <mat-paginator-->
<!--                                        [pageSize]="dynamicPageSize"-->
<!--                                        (page)="changePageSize($event.pageSize)"-->
<!--                                        [pageSizeOptions]="[5, 10, 20]"-->
<!--                                        showFirstLastButtons>-->
<!--                                </mat-paginator>-->
                                <mat-paginator (page)="setPage($event)"
                                               [hidePageSize]="false"
                                               [length]="pagination?.length"
                                               [pageSizeOptions]="[5, 10, 20]"
                                               [ngClass]="{ 'pointer-events-none': isLoading }"
                                               [pageIndex]="pagination?.pageIndex"
                                               [pageSize]="pagination?.pageSize"
                                               [showFirstLastButtons]="true"
                                               showFirstLastButtons
                                ></mat-paginator>
                            </div>
                        </div>
                    </div>
                    <!-- end Tabela Antiga -->
                </div>
            </div>
        </div>


    </div>










</div>
