import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { AsyncPipe, CurrencyPipe, DatePipe, KeyValuePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { FuseVerticalNavigationComponent } from '../../../../../../@fuse/components/navigation';
import { FuseCardComponent } from '../../../../../../@fuse/components/card';
import { Status } from '../../../../commons/enums/status.enum';
import { TranslocoPipe } from '@ngneat/transloco';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Patient } from '../../model/patient';
import { PatientService } from '../../service/patient.service';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter } from "@angular/material/core";
import { State } from "../../../../commons/enums/state.enum";
import { EnumUtils } from "../../../../utils/enum.utils";
import { Gender } from "../../../../commons/enums/gender.enum";
import { PatientType } from "../../../../commons/enums/patient-types.enum";
import { MatTabsModule } from '@angular/material/tabs';
import { ModalComponent } from '../../../../commons/components/modal/modal.component';

@Component({
    selector: 'patient-index',
    templateUrl: './patient-view.component.html',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./patient-view.component.scss'],
    imports: [FuseVerticalNavigationComponent, MatTableModule, DatePipe, CurrencyPipe, NgClass, MatButtonModule,
        MatSortModule, MatPaginatorModule, NgIf, MatIconModule, NgForOf, AsyncPipe, MatInputModule, MatSelectModule,
        FormsModule, RouterLink, FuseCardComponent, TranslocoPipe, MatDialogModule, MatSlideToggleModule, MatAutocompleteModule, NgxMaskDirective, NgxMaskPipe, MatStepperModule, ReactiveFormsModule, KeyValuePipe, MatDatepickerModule, MatTabsModule, ModalComponent]
})
export class PatientViewComponent implements OnInit, AfterViewInit, OnDestroy {
    patient: Patient;
    protected readonly Status = Status;
    protected readonly State = State;
    protected readonly EnumUtils = EnumUtils;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    protected readonly Gender = Gender;
    protected readonly PatientType = PatientType;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        public service: PatientService,
        @Inject(MAT_DIALOG_DATA) public data: any, private dateAdapter: DateAdapter<Date>
    ) {
        this.dateAdapter.setLocale('pt-BR');
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.patient = this.data;
    }

    /**
     * After index init
     */
    ngAfterViewInit(): void {
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.slug || index;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
