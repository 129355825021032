import { Message } from "../messages/message";

export class Flow {
    slug: string;
    name: string;
    description: string;
    type: string;
    retryTimes: string;
    retryInterval: string;
    template: Message;

    constructor(object?: any) {
        if (object) {
            for (const prop of Object.keys(object)) {
                this[prop] = object[prop];
            }
        }
    }
}
