import { Status } from '../../../commons/enums/status.enum';
import { Flow } from "../components/flow-messages/flow";

export class Reminder {
    slug: string;
    name: string;
    description: string;
    event: string;
    status: Status;

    constructor(object?: any) {
        if (object) {
            for (const prop of Object.keys(object)) {
                this[prop] = object[prop];
            }
        }
    }
}
