import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyPipe, DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccountWithdrawalComponent } from '../account-withdrawal/account-withdrawal.component';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RiskComponent } from '../../../../marketplace/marketplace-contract/components/risk/risk.component';
import { BankService } from '../../../../bank/service/bank.service';
import { FinancialHistoryService } from '../../service/financial-history.service';
import Swal from 'sweetalert2';
import { CardApproveWithdrawalComponent } from '../card-approve-withdrawal/card-approve-withdrawal.component';
import { tap } from 'rxjs';


@Component({
    selector: 'app-clubmed-internal-account',
    templateUrl: './internal-account.component.html',
    standalone: true,
    imports: [
        MatTabsModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        CurrencyPipe,
        MatTableModule,
        NgClass,
        NgIf,
        RouterOutlet,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        TextFieldModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        RouterLink,
        AccountWithdrawalComponent,
        MatOptionModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        NgForOf,
        DatePipe
    ],
    styleUrls: ['./internal-account.component.scss']
})
export class InternalAccountComponent implements OnInit {
    cardForm: FormGroup;
    action = 'Salvar';
    balanceValue: number;
    totalValue: number;
    futureValue: number;
    banks: any;
    loading = true;
    bankAccountSlug: string;
    unit: any;
    pageIndex: number;
    pageSize: number;
    length: number;
    valuesSum: any;
    isLoading: boolean = true;
    pagination: any;

    protected currentPage: number = 1;
    protected limit: number = 10;

    withWithdrawalsStatus = 'TODOS';
    displayedColumns: string[] = ['withdrawCreatedAt','transaction_id','bankName', 'pix_key', 'withdrawValue', 'withdrawStatus'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    protected pendingValue: any;

    constructor(
        private cdRef: ChangeDetectorRef,
        private bankService: BankService,
        private _matDialog: MatDialog,
        private formBuilder: FormBuilder,
        private financialHistoryService: FinancialHistoryService
    ) {
        this.cardForm = this.formBuilder.group({
            bankAccountSlug: ['', Validators.required],
            value: ['', Validators.required],
            userBalanceId: [''],
        });
    }

    ngOnInit(): void {
        this.unit = localStorage.getItem('userLocation');
        this.listTotalAndFutureValue()
        this.listBankAccount();

        this.listServiceUnit();
        this.listProfessional();

        this.list();
    }

    save() {
        if (this.cardForm.valid) {
            this.cardForm.value.userBalanceId = this.bankAccountSlug;
            this.financialHistoryService.withdraw(this.cardForm.value, this.cardForm.value.bankAccountSlug).subscribe((response) => {
                    if (response.message) {
                        Swal.fire('Erro', response.message, 'error');
                    }else{

                        Swal.fire('Solicitação', 'Solicitação de efetuada.', 'success');
                        this.list();
                        this.listTotalAndFutureValue();
                        this.cdRef.detectChanges();

                        this.cardForm.reset();
                    }
                },
                (error: any) => {

                    Swal.fire('Erro', 'Erro ao solicitar resgate. Tente novamente mais tarde.', 'error');
                    this.list();
                    this.cdRef.detectChanges();
                }
            );
        } else {
            Swal.fire('Erro', 'Preencha os campos obrigatórios.', 'error');

        }

    }
    listBankAccount(){
        this.bankService.GetAccount(this.unit).subscribe((response: any) => {
            this.banks = response.data.filter((item: any) => item.bankName !== 'Caixinha')
        });
    }

    listTotalAndFutureValue(){
        this.financialHistoryService.getInitValues(this.unit).subscribe((response: any) => {
            this.totalValue = response.totalValue;
            this.futureValue = response.futureValue;
            this.pendingValue = response.pendingValue;
        });
    }

    listServiceUnit() {
        this.financialHistoryService.getFinancialHistoryServiceUnit( this.unit).subscribe((response) => {
           if(response.data){
               console.log('VERIFICNDO SE É unidade', response);
                this.bankAccountSlug = response.data.slug;
                this.balanceValue = response.data.value;
           }
        });
    }
    listProfessional() {
        this.financialHistoryService.getFinancialHistoryProfessional().subscribe((response) => {
            if(response.data){
                console.log('VERIFICNDO SE É PROFISSIONAL', response);
                this.bankAccountSlug = response.data.slug;
                this.balanceValue = response.data.value;
            }
        });
    }

    replaceComma(event: any) {
        this.cardForm.controls['value'].setValue(event.target.value.replace(',', '.'));
    }

    dynamicPageSize = 10;
    changePageSize(size: number) {
        this.dynamicPageSize = size;
        console.log('Tamanho da página:', size);
        this.list();
    }


    list() {
        this.dataSource = new MatTableDataSource();
        this.financialHistoryService.getWithdrawalInternal(this.unit, this.withWithdrawalsStatus,this.limit).subscribe((response: any) => {
            const data = response.data;
            this.dataSource = new MatTableDataSource(data);

            this.isLoading = false;
            this.pagination = {
                length: response.total,
                pageIndex: response.current_page - 1,
            };
            this.dataSource.sort = this.sort;
        });
    }

    setPage(event: any) {
        this.currentPage = event.pageIndex + 1;
        this.limit = event.pageSize;
        this.dynamicPageSize = event.pageSize;
        this.list();
    }

    applyFilter(event: KeyboardEvent) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    getBadgeClass(status: string): string {
        switch (status) {
            case 'PENDING':
                return 'font-bold text-orange-600';
            case 'APPROVED':
                return 'font-bold text-green-600';
            case 'DENIED':
                return 'font-bold text-red-600';
            default:
                return '';
        }
    }

    getBadgeText(status: string): string {
        switch (status) {
            case 'PENDING':
                return 'PENDENTE';
            case 'APPROVED':
                return 'APROVADO';
            case 'DENIED':
                return 'NEGADO';
            default:
                return '';
        }
    }
    statusOptions = [
        { viewValue: 'Todos', value: 'TODOS' },
        { viewValue: 'Pendente', value: 'PENDING' },
        { viewValue: 'Aprovado', value: 'APPROVED' },
        { viewValue: 'Negado', value: 'DENIED' },
    ];

    SearchSelect(selectedValue: string) {
        console.log('Selected value:', selectedValue);
        this.withWithdrawalsStatus = selectedValue;
        this.list();
    }

}
