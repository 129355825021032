export enum Translate {
    ACTIVE = "ATIVO",
    INACTIVE = "INATIVO",
    DOCTOR = "Médico",
    PSYCHOLOGIST = "Psicólogo",
    PSYCHOANALYST = "Psicanalista",
    NUTRITIONIST = "Nutricionista",
    PHYSIOTHERAPIST = "Fisioterapeuta",
    EXAM = "Exame",
    SURGERY = "Cirurgia",
    APPOINTMENT = "Consulta",
    TELEMEDICINE = "Telemedicina",
    Password = "Senha",
    CAPSULE = "Cápsula",
    EXTENDED_RELEASE_CAPSULE = "Cápsula de Liberação Prolongada",
    TABLET = "Comprimido",
    DISPERSIBLE_TABLET = "Comprimido Dispersível",
    COATED_TABLET = "Comprimido Revestido",
    EXTENDED_RELEASE_COATED_TABLET = "Comprimido Revestido de Liberação Prolongada",
    TOPICAL_CREAM = "Creme Tópico",
    DRAJEA = "Drágea",
    GEL = "Gel",
    GEL_CREAM = "Creme em Gel",
    POWDER = "Pó",
    LYOPHILIZED_POWDER = "Pó Liofilizado",
    POWDER_FOR_ORAL_SOLUTION = "Pó para Solução Oral",
    OINTMENT = "Pomada",
    SOAP = "Sabonete",
    AEROSOL_SOLUTION = "Solução em Aerosol",
    SOLUTION_DROPS = "Gotas de Solução",
    NASAL_SOLUTION = "Solução Nasal",
    SUSPENSION_DROPS = "Gotas de Suspensão",
    INJECTABLE_SUSPENSION = "Suspensão Injetável",
    ORAL_SUSPENSION = "Suspensão Oral",
    SYRUP = "Xarope",
    MANUFACTURED = "Fabricado",
    INDUSTRIALIZED = "Industrializado",
    NON_STEROIDAL_ANTI_INFLAMMATORY_AGENTS = "Agentes Anti-inflamatórios Não Esteroidais",
    ANALGESIC_ANTIPYRETICS = "Analgésicos e Antipiréticos",
    LOCAL_ANESTHETICS = "Anestésicos Locais",
    ANTIEMETICS = "Antiêmicos",
    ANTIPHLOGISTICS = "Antiflogísticos",
    ANTIFUNGALS = "Antifúngicos",
    ANTIBIOTICS = "Antibióticos",
    ANTILIPEMIC_AGENTS = "Agentes Antilipêmicos",
    CORTICOSTEROIDS = "Corticosteroides",
    DIURETICS = "Diuréticos",
    CENTRAL_NERVOUS_SYSTEM_STIMULANTS = "Estimulantes do Sistema Nervoso Central",
    ANTIHYPERTENSIVE_DRUGS = "Medicamentos Anti-hipertensivos",
    ANTIPARKINSONIAN_DRUGS = "Medicamentos Antiparkinsonianos",
    BLOOD_COAGULATION_AND_HEMOSTATICS_DRUGS = "Medicamentos para Coagulação Sanguínea e Hemostáticos",
    COLD_AND_FLU_REMEDIES = "Remédios para Resfriado e Gripe",
    PSYCHOTROPIC_DRUGS = "Medicamentos Psicotrópicos",
    HYPNOANALGESICS = "Hipnoanalgésicos",
    MUSCLE_RELAXANTS = "Relaxantes Musculares",
    OTHER_ANALGESICS = "Outros Analgésicos",
    VITAMINS = "Vitaminas",
    ANTINEOPLASTIC_AGENTS = "Agentes Antineoplásicos",
    ANTIEPILEPTICS = "Antiepilépticos",
    ANTI_VERTIGO_DRUGS = "Medicamentos Anti-vertigem",
    MULTIPLE_SCLEROSIS_DRUGS = "Medicamentos para Esclerose Múltipla",
    SEDATIVES_HYPNOTICS = "Sedativos e Hipnóticos",
    VASOCONSTRICTORS = "Vasoconstritores",
    LOCAL_ANTI_INFLAMMATORY_DRUGS = "Medicamentos Anti-inflamatórios Locais",
    ANTINEURITIC_AGENTS = "Agentes Antineuríticos",
    HOMEOPATHICS = "Homeopáticos",
    VASODILATORS = "Vasodilatadores",
    G = "Grama (g)",
    MCG = "Micrograma (mcg)",
    MCG_DOSE = "Micrograma por Dose (mcg/Dose)",
    MG = "Miligrama (mg)",
    MG_5_ML = "Miligrama por 5 mL (mg/5 mL)",
    MG_ML = "Miligrama por Mililitro (mg/mL)",
    UI = "Unidade Internacional (UI)",
    GENERIC = "Genérico",
    REFERENCE = "Referência",
    SIMILAR = "Similar",
    BIOPHARMACEUTICAL = "Biofarmacêutico",
    INPUT = "Insumo",
    PHYTOTHERAPEUTIC = "Fitoterápico",
    BIOSIMILAR = "Biossimilar",
    DERMOCOSMETIC = "Dermocosmético",
    DZ = "Dúzia (DZ)",
    UN = "Unidade (UN)",
    PC = "Peça (PC)",
    C = "Caixa (C)",
    P = "Pacote (P)",
    MALE = 'Masculino',
    FEMALE = 'Feminino',
    OTHERS = 'Outros',
    UNINFORMED = 'Não informado',
    DEPENDENT = 'Dependente',
    HOLDER = 'Titular',
    ACRE = 'Acre',
    ALAGOAS = 'Alagoas',
    AMAPA = 'Amapa',
    AMAZONAS = 'Amazonas',
    BAHIA = 'Bahia',
    CEARA = 'Ceará',
    DISTRITO_FEDERAL = 'Distrito Federal',
    ESPIRITO_SANTO = 'Espírito Santo',
    GOIAS = 'Goiás',
    MARANHAO = 'Maranhão',
    MATO_GROSSO = 'Mato Grosso',
    MATO_GROSSO_DO_SUL = 'Mato Grosso do Sul',
    MINAS_GERAIS = 'Minas Gerais',
    PARA = 'Pará',
    PARAIBA = 'Paraíba',
    PARANA = 'Paraná',
    PERNAMBUCO = 'Pernambuco',
    PIAUI = 'Piauí',
    RIO_DE_JANEIRO = 'Rio de Janeiro',
    RIO_GRANDE_DO_NORTE = 'Rio Grande do Norte',
    RIO_GRANDE_DO_SUL = 'Rio Grande do Sul',
    RONDONIA = 'Rondônia',
    RORAIMA = 'Roraima',
    SANTA_CATARINA = 'Santa Catarina',
    SAO_PAULO = 'São Paulo',
    SERGIPE = 'Sergipe',
    TOCANTINS = 'Tocantins',
    CRM = 'CRM',
    CRP = 'CRP',
    RNTP = 'RNTP',
    CRN = 'CRN',
    CRF = 'CRF',
    ROOT = "Root",
    MANAGER = "Gestor",
    SERVICE_UNIT_MANAGER = "Gestor Unidade de Saúde",
    COVENANT_MANAGER = "Gestor Convênio",
    LABORATORY_MANAGER = "Gestor Laboratório",
    DIAGNOSTIC_CENTER_MANAGER = "Gestor Centro de Diagnóstico",
    PROFESSIONAL = "Profissional",
    PATIENT = "Paciente",
    RECEPTIONIST = "Recepcionista",
    VERY_LOW_RISK = "Muito Baixo Risco",
    LOW_RISK = "Baixo Risco",
    MEDIUM_RISK = "Risco Médio",
    HIGH_RISK = "Alto Risco",
    Mandarin = "Mandarim",
    Spanish = "Espanhol",
    English = "Inglês",
    Hindi = "Hindi",
    Arabic = "Árabe",
    Bengali = "Bengali",
    Portuguese = "Português",
    Russian = "Russo",
    Japanese = "Japonês",
    Punjabi = "Panjabi",
    French = "Francês",
    PENDING = 'Aguardando',
    SCHEDULED = 'Agendado',
    WAITING = 'Aguardando',
    ACCEPTED = 'Aceito',
    REJECTED = 'Rejeitado',
    COMPLETED = 'Concluído',
    IN_PROGRESS = 'Em Progresso',
    CANCELED = 'Cancelado',
    SECRETARY= 'Secretário',
    ADMINISTRATOR_MANAGER = 'Gestor Administrativo',
    O_POSITIVE = 'O+',
    A_POSITIVE = 'A+',
    B_POSITIVE = 'B+',
    AB_POSITIVE = 'AB+',
    O_NEGATIVE = 'O-',
    A_NEGATIVE = 'A-',
    B_NEGATIVE = 'B-',
    AB_NEGATIVE = 'AB-',
    CHILDREN = 'Crianças',
    TEENAGERS = 'Adolescentes',
    ADULTS = 'Adultos',
    SENIORS = 'Idosos',
    WHITE = 'Branco',
    BLACK = 'Negro',
    BROWN = 'Pardo',
    YELLOW = 'Amarelo',
    INDIGENOUS = 'Indígena',
    COMPANY_MANAGER = "Gestor de Empresa",
}
