import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'app/environments/environment';

@Injectable({ providedIn: 'root' })
export class FinancialHistoryService {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {

    }

    getFinancialHistoryServiceUnit(serviceUnitUuid): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/user-balance/service-unit/${serviceUnitUuid}`);
    }


    getFinancialHistoryProfessional(): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/user-balance/professional`);
    }

    getFinancialHistoryCompany(): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/user-balance/company`);
    }

    getFinancialHistoryPatient(): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/user-balance/auth-user`);
    }
    listWithdrawals(status: string | null, page: number, limit: number): Observable<any> {
        let params = new HttpParams();
        if (status) {
            params = params.append('status', status);
        }
        if (page) {
            params = params.append('page', page.toString());
        }
        if (limit) {
            params = params.append('limit', limit.toString());
        }
        return this._httpClient.get<any>(`${ environment.serverUrl }/list-request-withdrawals`, { params: params });
    }
    withdraw(request: any , bankAccountSlug: string): Observable<any> {
        return this._httpClient.post<any>(`${ environment.serverUrl }/request-withdraw/${bankAccountSlug}`, request);
    }
    updateWithdraw(request:any, WithdrawSlug: string): Observable<any> {
        return this._httpClient.put<any>(`${ environment.serverUrl }/approve-withdraw/${WithdrawSlug}`,request);
    }

    getWithdrawalInternal(unitySlug: string, status: string , page: number): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/withdrawals/${unitySlug}/${status}/${page}`);
    }

    getInitValues(unitySlug: string): Observable<any> {
        return this._httpClient.get<any>(`${ environment.serverUrl }/financial-histories/${unitySlug}/sum`);
    }


}

