<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="menuData" [opened]="!isScreenSmall" [user]="user" class="dark sidebar print:hidden">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <ng-container *ngIf="tenant$ | async as tenant">
                    <img class="w-30" *ngIf="!tenant.whiteLogo" [src]="tenant.logo ? 'data:image/png;base64,' + tenant.logo : 'assets/images/logo/logo.svg'" alt="Logo do Tenant" />
                    <img class="w-30" *ngIf="tenant.whiteLogo" [src]="
                            tenant.whiteLogo
                                ? 'data:image/png;base64,' + tenant.whiteLogo
                                : 'assets/images/logo/logo.svg'
                        " alt="Logo do Tenant" />
                </ng-container>
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!--                <notification></notification>-->
                <user [showAvatar]="false"></user>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24">
                <img *ngIf="!isManager() && user?.photo?.base64" [src]="currentUserPhoto" alt="User avatar" class="w-full h-full rounded-full" />
                <mat-icon *ngIf="!isManager() && !user?.photo" [svgIcon]="'heroicons_solid:user-circle'" class="icon-size-24 default-avatar"></mat-icon>

                <img *ngIf="isServiceUnitManager() && currentImagePhoto" [src]="currentImagePhoto" alt="Location avatar" class="w-full h-full rounded-full" />
                <mat-icon *ngIf="isServiceUnitManager() && !currentImagePhoto" [svgIcon]="'mat_solid:local_hospital'" class="icon-size-24 default-avatar">home_health</mat-icon>
                
                <img *ngIf="isCompanyManager() && currentImagePhoto" [src]="currentImagePhoto" alt="Location avatar" class="w-full h-full rounded-full" />
                <mat-icon *ngIf="isCompanyManager() && !currentImagePhoto" [svgIcon]="'heroicons_solid:building-office-2'" class="icon-size-24 default-avatar"></mat-icon>

            </div>
            <div class="flex flex-col items-center justify-center w-full mb-6 mt-2">
                <div *ngIf="!isManager()" class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium user-data-photo">
                    {{ user?.name }}
                </div>
                <div *ngIf="isManager() && this.userLocation != null" class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium user-data-photo">
                    {{ userLocation?.name }}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary user-data-photo">
                    {{ user?.email }}
                </div>
            </div>
            <!-- <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary user-data-photo" *ngIf="canShowTypeField()">
                <select id="typeSchedule" [(ngModel)]="localeId" class="placeholder-white">
                   <option value="">Escolha uma opção</option>
                   <option class="text-white"
                           *ngFor="let userLocale of user?.userLocales">{{ userLocale?.registeredName || userLocale?.fantasyName }}</option>
               </select>
            </div> -->
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter></ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button (click)="toggleNavigation('mainNavigation')" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!--            <languages></languages>-->
            <app-img-plans></app-img-plans>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full bg-white default-component-content">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout} changes won't be registered and the index won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>
