import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { DefaultLayoutComponent } from "app/layout/layouts/default/default.component";
import { FuseVerticalNavigationComponent } from "@fuse/components/navigation";

import { RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";

@Component({
    selector: "monitoring",
    templateUrl: "./monitoring.component.html",
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./monitoring.component.scss"],
    imports: [DefaultLayoutComponent, FuseVerticalNavigationComponent, RouterOutlet, CommonModule],
})
export class MonitoringComponent {
    title = "Monitoramento";
    /**
     * Constructor
     */
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}
}
