import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/service/user.service';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AbstractService } from '../commons/services/abstract.service';
import { Tenant } from "../tenant/model/tenant";
import { Store } from "@ngrx/store";
import { setUser } from "../user/actions/user.action";
import { User } from "../user/model/user.types";
import { SoketiService } from '../soketi/soketi.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends AbstractService<AuthService> {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
                private _userService: UserService,
                private soketiService: SoketiService,
                private store: Store) {
        super(_httpClient, 'auth', AuthService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    /**
 * Getter & setter for local
 */
    get userLocation(): string {
        return localStorage.getItem('userLocation') ?? '';
    }

    set userLocation(value: string) {
        localStorage.setItem('userLocation', value);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param data
     */
    forgotPassword(data: string): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}/forgot-password`, data);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}/reset-password`, password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { login: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${this.baseUrl}/login`, credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                if (response.userLocales && response.userLocales.length > 0) {
                    this.userLocation = response.userLocales[0].slug;
                }
                this.soketiService.connect().subscribe(result => {


                })

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;
                this.setUser(response.user);
                    localStorage.setItem('r', response?.user?.role);
                if (response.user.company!=null)
                    localStorage.setItem('planType', response.user.company.plan_type);

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    setUser(user: User) {
        this.store.dispatch(setUser({ user }));
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post(`${this.baseUrl}/token`, {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }

                this.soketiService.connect().subscribe(result => {

                })

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;
                this.setUser(response.user);

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userLocation');
        localStorage.removeItem('planType');
        localStorage.removeItem('r');

        // Set the authenticated flag to false
        this._authenticated = false;

        this._httpClient.post(`${this.baseUrl}/logout`, {}).subscribe(() => { });

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}/sign-up`, user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}/unlock-session`, credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
