import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { DefaultLayoutComponent } from "app/layout/layouts/default/default.component";
import { FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    NgForm,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Status } from "app/modules/commons/enums/status.enum";
import { ReminderService } from '../../service/reminder.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Utils } from '../../../../utils/utils';
import { MatTabsModule } from "@angular/material/tabs";
import { Message } from "./message";
import { MatDialog } from '@angular/material/dialog';
import { VariableExampleComponent } from './variable-example/variable-example.component';

@Component({
    selector: "message-component",
    templateUrl: "./messages.component.html",
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./messages.component.scss"],
    imports: [MatSlideToggleModule, DefaultLayoutComponent, FuseVerticalNavigationComponent, RouterOutlet,
        CommonModule, DefaultLayoutComponent, FuseVerticalNavigationComponent,
        FormsModule, ReactiveFormsModule, MatIconModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule,
        MatOptionModule, MatDividerModule, MatCheckboxModule, MatRadioModule, MatButtonModule, MatAutocompleteModule, MatTooltipModule, MatTabsModule],
})
export class MessagesComponent {
    @Input() messages: any;
    @Output() value: EventEmitter<any> = new EventEmitter<any>();
    @Output() formValueChanged: EventEmitter<any> = new EventEmitter<any>();


    @ViewChild('form') form: NgForm;
    formGroup: FormGroup = new FormGroup<any>({
        slug: new FormControl(),
        content: new FormControl(),
        responseButton: new FormControl(),
        templateName: new FormControl(),
        type: new FormControl(),
        messages: new FormControl()
    });
    data: Message;
    tabs = "stickyNotes";
    protected readonly Status = Status;
    private reminder: any;

    /**
     * Constructor
     */
    constructor(private _formBuilder: FormBuilder,
                protected _changeDetectorRef: ChangeDetectorRef,
                public dialog: MatDialog,
                private reminderService: ReminderService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.data = this.messages;
        this.formGroup = this.buildForm(this.data);
        this.formValueChanged.emit(this.formGroup.value);

        this.formGroup.valueChanges.subscribe(() => {
            this.emitFormValueChange();
        });
    }

    buildForm(data: any): FormGroup {
        return this._formBuilder.group({
            slug: [data.slug],
            content: [data.type == 'T' ? Utils.templateToString(data.content) : data.content],
            responseButton: [data.responseButton],
            templateName: [data.templateName],
            type: [data.type],
            messages: this.buildFormArray(data.messages)
        });
    }

    buildFormArray(messages: any[]): FormArray {
        return this._formBuilder.array(
            messages.map(message => this.buildForm(message))
        );
    }

    protected readonly Utils = Utils;

    emitFormValueChange() {
        this.formValueChanged.emit(this.formGroup.value);
    }

    get messagesFormArray(): FormArray {
        return this.formGroup.get("messages") as FormArray;
    }

    setValue(data: any, i: any) {
        const messageControl = this.messagesFormArray.at(i) as FormGroup;
        messageControl.setValue(data);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(VariableExampleComponent);
    }
}
